.login {
  font-family: Poppins, sans-serif !important;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
}

.myinput {
  display: none;
}

.myinput + .mydisplay {
  width: 20px;
  height: 20px;
  border: 3px solid darkgray;
  border-radius: 6px;
  box-sizing: border-box;
}

.myinput:hover + .mydisplay {
  border-color: #00b3ee;
}

.myinput:checked + .mydisplay {
  background-size: cover;
}

.mainImage {
  /* max-width: 870px;
  width: 100%;
  max-height: 897px;
  height: 100%; */
  width: 700px;
  height: 700px !important;
  align-self: center;
  margin-left: -32px;
}

.spliterSize {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 40px;
  /* margin-left: 5px; */
}

.loginFields {
  /* width: 70%; */
  height: 100%;
  display: flex;
  flex-direction: column;
  /* margin-left: 15%; */
  margin-top: 40px;
  font-family: Poppins, sans-serif;
}

.loginLogo {
  height: 150px;
  width: 150px;
  position: absolute;
  z-index: 1;
  top: 10px;
  left: 200px;
}

.welcomeText {
  font-size: 36px;
  font-family: Poppins, sans-serif;
  font-weight: 600;
  text-align: left;
  letter-spacing: 0.2px;
  color: #020723;
  opacity: 1;
}

.subHeaderText {
  text-align: left;
  font-size: 22px;
  font-family: Poppins, sans-serif;
  font-weight: normal;
  letter-spacing: 0px;
  color: #020723;
  opacity: 0.87;
  margin-top: 15px;
}

.smallHeaderText {
  text-align: left;
  font-size: 12px;
  font-family: Poppins, sans-serif;
  font-weight: normal;
  letter-spacing: 0.4px;
  color: #020723;
  opacity: 0.5;
}

.signInType {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 15px;
}

.signInHeaderText {
  letter-spacing: var(--unnamed-character-spacing-0);
  text-align: left;

  font-size: 18px;
  font-weight: 600;
  font-family: Poppins, sans-serif;
  letter-spacing: 0px;
  color: #020723;
  opacity: 0.87;
}
.marginLeft {
  margin-left: 10px;
}

.normalFontType {
  font-family: sans-serif;
  font-size: 16px;
}

.userNameHeaderText {
  text-align: left;

  font-size: 16px;
  font-family: Poppins, sans-serif;
  font-weight: 600;
  letter-spacing: 0.44px;
  color: #020723;
  opacity: 1;
  margin-top: 20px;
}

.rememberText {
  text-align: left;
  font-size: 14px;
  font-weight: 500;
  color: #020723;
  font-family: sans-serif;
}

.remember_me_check {
  margin-right: 4px;
  height: 20px;
  width: 20px;

  border-radius: 10px;
}

.userNameField {
  text-align: left;
  font-size: 16px;
  font-family: Poppins, sans-serif;
  font-weight: normal;
  letter-spacing: 0.44px;
  opacity: 1;
  margin-top: 10px;
  height: 56px;
  margin-left: 0px !important;
  width: 502px;
  border: 0px;
  background-color: #f2f7ff;
  padding-left: 10px;
  color: black;
  border-radius: 10px;
  letter-spacing: 0.44px;
}
.check_icon {
  height: 40px;
  cursor: pointer;
  width: 30px;
  margin-top: 15px;
  padding-top: 12px;
  color: #8b60fd;
  font-size: 20px;
  margin-left: -40px;
  border: none;
  border-radius: 10px;
  z-index: 1;
}

.rememberDiv {
  margin-top: 10px;
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 502px;
}

.forgotPasswordDiv {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.forgotPassBtn {
  letter-spacing: 0.47px;
  font-size: 14px !important;
  font-weight: medium !important;
  font-family: Poppins, sans-serif;
  opacity: 0.87;

  color: #0b92e8 !important;

  text-align: right;
}

.loginBtn {
  background-color: #daccff !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  text-align: center;
  height: 56px;
  margin-top: 15px;
  width: 502px;
  border: 0px;
  border-radius: 10px;
}

.loginBtn.p-button:hover {
  background-color: #8b60fd !important;
  color: white;
}

.errorText {
  text-align: left;
  font-size: 12px;
  font-weight: 500;
  color: red;
  font-family: sans-serif;
  margin-top: 5px;
}

.radio_lable {
  cursor: pointer;
  text-align: left;
  font-size: 18px;
  font-family: Poppins, sans-serif;
  font-weight: normal;
  letter-spacing: 0px;
  color: #020723;
  opacity: 0.87;
}

.rememberMe {
  text-align: left;
  font-size: 14px;
  font-family: Poppins, sans-serif;
  font-weight: normal;
  letter-spacing: 0.47px;
  color: #212121;
  opacity: 0.87;
}

/* radio button styling */

input[type="radio"] {
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  outline: none;
  border: 3px solid #8b60fd;
  margin-bottom: -3px;
  cursor: pointer;
}

input[type="radio"]:before {
  content: "";
  display: block;
  width: 60%;
  height: 60%;
  margin: 20% auto;
  border-radius: 50%;
  margin-bottom: -3px;
  cursor: pointer;
}

input[type="radio"]:checked:before {
  background: #8b60fd;
}

input[type="radio"]:checked {
  border-color: #8b60fd;
}

.p-inputtext:enabled:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #c7d2fe;
  border-color: #6366f1;
}

.userNameField:enabled:focus-visible {
  outline: 0 none !important;
  outline-offset: 0 !important;
  box-shadow: 0 0 0 0.2rem #c7d2fe !important;
  border-color: #6366f1 !important;
}

.loginBtn:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px #b1b3f8, 0 1px 2px 0 rgb(0, 0, 0);
}

input[type="checkbox"]:not([class*="disabled"]):focus {
  outline: none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #c7d2fe;
  border: 2px solid #6366f1;
  background-color: #4338ca;
  opacity: 0.8;
  border-radius: 10px;
}

input[type="checkbox"] {
  accent-color: #4338ca;
  opacity: 0.8;
}

/* responsive code starts here */

@media only screen and (min-device-width: 1025px) {
  .login {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
  .spliterSize {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 40px;
  }
  .loginFields {
    width: 70%;

    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 40px;
  }
  .loginLogo {
    top: 10px;
    display: flex;
    justify-content: center;
  }

  .rememberDiv {
    margin-top: 10px;
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 502px;
  }
}

@media only screen and (min-device-width: 1024px) and (max-device-width: 1350px) {
  .mainImage {
    width: 500px;
    height: 550px !important;
    align-self: center;
    margin-left: -32px;
  }
  .loginLogo {
    top: 10px;
    left: 150px;
  }
  .userNameField {
    margin-top: 10px;
    height: 56px;
    margin-left: 0px !important;
    width: 400px;
  }
  .rememberDiv {
    width: 400px;
  }
  .loginBtn {
    height: 56px;
    width: 400px;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1023px) {
  .login {
    display: block;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  .spliterSize {
    width: 100%;
    height: 100%;
    display: block;
    align-items: center;
  }

  .mainImage {
    display: none;
  }
  .loginFields {
    margin-top: 150px;

    display: flex;
    justify-content: center;
  }
  .parent_login_div {
    display: flex;
    justify-content: center;
  }
  .loginLogo {
    left: 0px;
    top: 10px;
    display: flex;
    justify-content: center;
  }
}

@media only screen and (min-device-width: 200px) and (max-device-width: 767px) {
  .login {
    display: block;
    align-items: center;
    width: 100%;
    height: 100%;
    padding-bottom: 20px;
  }
  .spliterSize {
    width: 100%;
    height: 100%;
    display: block;
    align-items: center;
  }

  .mainImage {
    display: none;
  }
  .loginFields {
    margin-top: 150px !important;
    display: flex;
    justify-content: center;
    margin-left: 0%;
  }
  .loginLogo {
    left: 0px;
    top: 10px;
    display: flex;
    justify-content: center;
  }
  .parent_login_div {
    display: flex;
    justify-content: center;
  }
  .userNameField {
    height: 56px;
    width: 300px;
    border: 0px;
  }
  .loginBtn {
    height: 56px;
    width: 300px;
  }
  .rememberDiv {
    width: 300px;
  }
  
}
