.modal-content {
  border-radius: 80px !important;
}


@media screen and (min-width: 375px) and (max-width: 767px) {
  .successModal  {
    width: 350px;
  }
  .logOutHeader_self {
    font-weight: 500;
    font-size: 25px !important;
}
}



/* @media only screen and (min-device-width: 1024px) and (max-device-width: 1920px){
.close_btn_self {
  margin-top: -202px!important;
  padding-left: 280px;
  justify-content: end;
}
} */

