.top {
  width: 100%;
  min-height: 100vh;
  height: auto;
  background-color: #daccff;
  background-repeat: no-repeat;
  background-size: cover;
}
.upload_name {
  color: black;
  /* margin-top: -20px; */
  /* margin-left: 120px; */
  white-space: nowrap;
  /* width: 100px; */
  
}
.avatarModal{
  width:576px !important
}
.close_avatar {
  margin-left: 93%;
  padding-top: 2%;
}

.close_avatar :hover {
  cursor: pointer;
}

.edit {
  background-color: yellow;
  border: 1px solid white;
  height: 40px;
  width: 40px;
  padding: 10px;
  border-radius: 8px;
  cursor: pointer;
}

.avatar_header {
  display: flex;
  justify-content: space-between;
}

.grid {
  width: 86%;
  margin-left: 7%;
  margin-right: 7%;
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(auto-fit, minmax(180px, 2fr));
  grid-auto-rows: 180px;
}

.avatarBox {
  padding-top: 8%;

  text-align: center;
  width: 182px;
  height: 179px;
  background: #fbfbfb 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 3px solid #c80040;
  border-radius: 30px;
  opacity: 1;
}

.avatar_img {
  width: 128px;
  height: 128px;
  background: transparent;
}

.avatarBox:hover {
  border: 4px solid #8b60fd;
  box-shadow: 0px 3px 6px #00000029;
}
.avatar_click {
  padding-top: 8%;
  text-align: center;
  width: 182px;
  height: 179px;
  background: #fbfbfb 0% 0% no-repeat padding-box;
  border-radius: 30px;
  opacity: 1;
  border: 4px solid #8b60fd;
  box-shadow: 0px 3px 6px #00000029;
}
.avatar_click:hover {
  border: 4px solid #8b60fd;
  box-shadow: 0px 3px 6px #00000029;
}

.uploadInp {
  display: none;
}
.uploadBtn {
  cursor: pointer;
  background-color: #8b60fd;
  color: white;
  padding: 5px;
  height: 35px;
  width: 100px;
  border-radius: 5px;
  margin: 2px;
}

.uploadSectn {
  border: 1px solid orange;
  height: 40px;
  border-radius: 4px;
}

.btn_cntnr .select_bg {
  width: 100%;
  height: 50px;
}

.select_content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  font-size: 18px;
  color: black;
  font-weight: 550;
}

/* responsive code strts here */

@media only screen and (min-device-width: 1023px) {
  .btn_cntnr {
    width: 335px;
    height: 50px;
    position: relative;
    margin-left: 38%;
  }
  .uploadBtn {
    cursor: pointer;
    background-color: #8b60fd;
    color: white;
    padding: 5px;
    height: 35px;
    width: 100px;
    border-radius: 5px;
    margin: 2px;
    min-width: 100px;
  }
  .avatarModal{
    width:576px !important
  }
  .edit_div {
    z-index: 1;
    margin-right: 10%;
    margin-top: 5px;
  }
  .upload_name{
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100px;
  }
  .grid{
    margin-left: 8%;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1023px) {
  .btn_cntnr {
    width: 335px;
    height: 50px;
    position: relative;
    margin-left: 30%;
  }
  .uploadBtn {
    cursor: pointer;
    background-color: #8b60fd;
    color: white;
    padding: 5px;
    height: 35px;
    width: 100px;
    border-radius: 5px;
    margin: 2px;
  }

  .avatarModal{
    width:500px !important
  }
  .edit_div {
    z-index: 1;
    margin-right: 90px;
        margin-top: 5px;

  }
  .grid{
    margin-left: 8%;
  }
  .upload_name{
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100px;
  }
}

@media only screen and (min-device-width: 200px) and (max-device-width: 767px) {
  .select_content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    font-size: 14px;
    color: black;
    font-weight: 550;
    width: 100%;
  }
  .avatarModal{
    width:300px !important
  }
  .uploadBtn{
    /* margin-left: 60px; */
    min-width: 59px;
    font-size: 15px;
  }
  .edit_div {
    z-index: 1;
    margin-right: 2%;
  }
  .upload_name {
    /* margin-top: -20px; */
    /* margin-left: 105px; */
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 70px;
  }
}
@media only screen and (min-device-width: 450px) and (max-device-width: 767px) {
  .close_avatar {
    margin-left: 90%;
  }
  .upload_name{
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100px;
  }
}

@media only screen and (min-device-width: 425px) and (max-device-width: 449px) {
  .btn_cntnr {
    width: 320px;
    height: 50px;
    position: relative;
    margin-left: 10%;
    right: 20px;
  }
  .edit_div {
    z-index: 1;
    margin-right: 3%;
    margin-top: 5px;
  }
}
@media only screen and (min-device-width: 375px) and (max-device-width: 424px) {
  .btn_cntnr {
    width: 320px;
    height: 50px;
    position: relative;
    margin-left: 10%;
    right: 14px;

  }
  .edit_div {
    z-index: 1;
    margin-right: 3%;
    margin-top: 5px;

  }
  .upload_name {
    /* margin-top: -20px; */
    /* margin-left: 105px; */
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 80px;
  }
}
@media only screen and (min-device-width: 320px) and (max-device-width: 374px) {
  .btn_cntnr {
    width: 320px;
    height: 50px;
    position: relative;
    margin-left: 10%;
    right: 14px;
  }
  .grid {
    width: 90%!important;
    margin-left: 7%!important;
    /* margin-right: 7%!important; */
    display: grid!important;
    gap: 20px!important;
    grid-template-columns: repeat(auto-fit, minmax(93px, 18fr))!important;
    grid-auto-rows: 120px!important;
}
  .edit_div {
    z-index: 1;
    margin-right: 2%;
    margin-top: 5px;

  }
  .upload_name {
    /* margin-top: -20px; */
    /* margin-left: 105px; */
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 80px;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 449px) {
  .grid {
    width: 90%;
    margin-left: 3%;
    /* margin-right: 7%; */
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(auto-fit, minmax(80px, 18fr));
    grid-auto-rows: 120px;
  }
  .avatarBox {
    padding-top: 8%;
    width: 115px;
    height: 115px;
    border-radius: 20px;
  }
  .avatar_click {
    padding-top: 8%;
    width: 120px;
    height: 130px;
    border-radius: 20px;
  }
  .avatar_img {
    width: 95px;
    height: 90px;
    background: transparent;
  }
  .close_avatar {
    margin-left: 85%;
  }
  .upload_name {
    /* margin-top: -20px; */
    /* margin-left: 105px; */
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 80px;
  }
}


@media only screen and (min-device-width: 425px) and (max-device-width: 767px) {
  .btn_cntnr {
    width: 320px;
    height: 50px;
    position: relative;
    margin-left: 10%;
    right: 14px;
  }
  .grid {
    width: 90%!important;
    margin-left: 7%!important;
    /* margin-right: 7%!important; */
    display: grid!important;
    gap: 20px!important;
    grid-template-columns: repeat(auto-fit, minmax(93px, 18fr))!important;
    grid-auto-rows: 120px!important;
}
  .edit_div {
    z-index: 1;
    margin-right: 4%;
    margin-top: 5px;

  }
  /* .upload_name {
  
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 80px;
  } */
}

@media only screen and (min-device-width: 320px) and (max-device-width: 767px) {
.ModalBodyAvtar{
  width: 240px!important;
}
  

}

