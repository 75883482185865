body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    "Poppins" sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.poppins_font {
  font-family: Poppins, sans-serif !important;
}
.comic_font {
  font-family: Comic Sans MS !important;
}

.sideDiv {
  margin-left: 100px;
  padding-bottom: 10px;
  display: flex;
  background-color: #fef9ef;
  min-height: 100vh;
  height: 100%;
  /* padding: 10px 20px 10px 10px; */
}
.sideDivCourse{
  padding: 10px 10px 10px 10px !important;
}
.comp_container {
  background: #fef9ef;
min-height: 100vh;
  height: 100%;
}

.no_data_img {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 2rem;
}

/* scrollbar css starts here */

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background-color: #fef9ef;
}

::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.section::-webkit-scrollbar {
  width: 5px;
}

.section::-webkit-scrollbar-track {
  background-color: #fef9ef;
}

.section::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

html {
  scrollbar-color: #6969dd #e0e0e0;
  scrollbar-width: thin;
}
.navbar {
  padding: 0.5rem 0rem !important;
}


@media only screen and (min-device-width: 768px) and (max-device-width: 1023px) {
  .sideDiv,
  .sideDiv_header {
    display: block;
  }
  .navbar-nav-scroll{
    overflow-y: visible !important;
  }
 
}

@media only screen and (min-device-width: 200px) and (max-device-width: 767px) {
  .sideDiv,
  .sideDiv_header {
    display: block;
    margin-left: 0px;
    margin: 15px;
    padding: 0px;
    min-height: 100vh;
    height: 100%;
  }
  .no_data_img img {
    max-width: 300px;
    width: 100%;
  }
  .comp_container {
    background: #fef9ef;
    height: 100% !important;
    /* height: 100vh; */
    min-height: 0vh!important;
    padding-bottom: 5px;
  }
 
}
