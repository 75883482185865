.chaptr_name_head {
  font-size: 22px;
  font-weight: 500;
  text-align: start;
}

.backBtn {
  margin-right: 7rem;
}

.chapter_one {
  font-size: 20px;
  font-weight: 500;
}

.vp-sidedock SideDock_module_root__98c28094 .hidden {
  display: none!important;
}
.video_list {
  width: 30%;
  background-color: white;
  height: 565px;
  overflow-y: scroll;
  padding: 1rem 1rem;
  border: none;
  border-radius: 5px;
}

.chapter_name_div_active {
  padding: 10px;
  height: 89px;
  width: 100%;
  border: none;
  border-radius: 5px;
  background-color: #8b60fd;
  cursor: pointer;
}

.chapter_name_div {
  padding: 10px;
  height: 89px;
  width: 100%;
  border: none;
  border-radius: 5px;
  background-color: white;
  box-shadow: 0px 14px 40px #fef9ef;
  cursor: pointer;
}

.lesson_count {
  width: 42px;
  height: 44px;
}

.lesson_info {
  text-align: start;
  color: white;
  font-size: 12px;
}

.play {
  width: 30px;
  height: 30px;
}

.play_bg {
  width: 24px;
  height: 24px;
  background-color: #fdf980;
  border-radius: 4px;
  border: 2px solid #064974;
}

.test_div {
  display: flex;
}
.glossary_td {
  background-color: #918e96;
  color: #918e96;
}

.table {
  text-align: center;
}

.glossary_data {
  padding: 1rem;
}
.glossary_close {
  margin-top: 2%;
  margin-left: 90%;
  cursor: pointer;
}

.selfTest_btn {
  border: 1px solid #064974;
  background-color: #fdf980;
  color: #064974;
  padding-left: 4px;
  padding-right: 4px;
  border-radius: 11px;
  /* width: 170px; */
  width: 150px;
  height: 55px;
  font-size: 20px;
  font-weight: 500;
  /* padding-top: 5px; */
  cursor: pointer;
  box-shadow: 0px 3px 6px #00000029;
}
.pdfButton{
  border: 1px solid #064974;
  background-color: #064974;
  color: #fff;
  padding-left: 30px;
  padding-right: 30px;
  border-radius: 11px;
  /* width: 120px; */
  /* height: 35px; */
  font-size: 20px;
  font-weight: 500;
  /* padding-top: 5px; */
  cursor: pointer;
  box-shadow: 0px 3px 6px #00000029;
}
#example_video_1_html5_api {
  width: 800px;
  height: 500px;
}

/* Media query for screens with a maximum width of 425px */
@media only screen and (min-width: 1220px) {
  .video_div,
  .video_video_div {
    max-width: 883px;
    width: 65%;
    height: 481px;
  }

  .video_height,
  .video_video_div {
    width: 883px;
    height: 481px;
  }
  .main_div {
    display: flex;
    justify-content: space-between;
  }
  /* .video_list {
    width: 30%;
    height: auto;
    padding: 1rem 1rem;
  } */

  .video_no_data {
    font-size: 40px;
    padding-top: 25%;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1200px) {
  .video_div {
    width: 100%;
    height: auto;
  }
  .video_video_div {
    width: 885px;
    height: 400px;
  }
  .main_div {
    display: block;
    /* justify-content: space-between; */
  }
  .video_list {
    width: 100%;
    height: auto;
    padding: 1rem 1rem;
    margin-top: 20px;
  }
  .video_no_data {
    font-size: 40px;
    padding-top: 19%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .main_div {
    display: block;
  }
  .video_video_div {
    width: 600px;
    height: 300px;
  }
  .test_div{
    margin-top: 100px
    /* padding-top: 90px; */
    /* padding-left: 20px; */
  }
  .video_list {
    width: 100%;
    height: auto;
    padding: 1rem 1rem;
    margin-top: 20px;
  }
  .video_no_data {
    font-size: 30px;
    padding-top: 19%;
  }
  .modal-body {
    /* position: relative !important;
    flex: 1 1 auto !important;
    min-width: 100%;
    width: 695px; */
    /* min-height: 38vh; */
    /* height: 100%; */
}
.backBtn {
  margin-right: 10px;
}
}

@media only screen and (min-width: 284px) and (max-width: 420px) {
  .test_div {
    display: block;
  }
}

@media only screen and (min-width: 284px) and (max-width: 767px) {
  .main_div {
    display: block;
  }
  .video_list {
    width: 100%;
    height: auto;
    padding: 1rem 1rem;
    margin-top: 20px;
  }
  .selfTest_btn {
    margin-top: 10px;
    padding-left: 4px;
    padding-right: 4px;
    border-radius: 11px;
    width: 100px;
    height: 40px;
    font-size: 12px;
    font-weight: 500;
    padding-top: 5px;
    box-shadow: 0px 3px 6px #00000029;
  }
  .glossary_data {
    padding: 0.5rem;
    overflow-y: scroll;
    height: 79vh;
}
.col-md-3 {
  flex: 0 0 auto;
  width: 33%;
}
  /* .modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 95px;
    color: var(--bs-modal-color);
    pointer-events: auto;
    background-color: var(--bs-modal-bg);
    background-clip: padding-box;
    border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
    border-radius: var(--bs-modal-border-radius);
    outline: 0;
    margin-bottom: 425px
}
.modal-body {
  position: relative;
  flex: 1 1 auto;
  min-width: 100%;
  width: 100%;
  max-width: 500px;
  height: 95vh;
  padding-bottom: 20px;
} */
  .detail_icon {
    height: 18px;
  }
  .backBtn {
    margin-right: 1rem;
  }
 
  .glossary_close {
    margin-top: 2%;
    margin-left: 86%;
    cursor: pointer;
  }
  .glossary_head {
    font-size: 14px;
    padding: 0rem;
  }
  .video_no_data {
    font-size: 30px;
    padding-top: 2rem;
  }
}
/* <-----------------------------> */
@media only screen and (min-device-width: 768px) and (max-device-width: 1920px){
  .chapter{
    margin-top: 10px;
  }
  .backBtn {
    margin-right: 10px;
  }
  .test_div{
    gap: 20px;
  }
 
}


@media only screen and (min-device-width:320px) and (max-device-width: 374px){

.col-md-3 {
  flex: 0 0 auto;
  width: 45%!important;
}
}