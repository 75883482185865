/* .container_div{
    background: #fef9ef;
    display:flex;
} */

.scoreWhiteDiv {
  height: 376px;
  max-width: 387px;
  width: 100%;
  background-color: white;
  border-radius: 10px;
}

.drop_option {
  border: none;
  border-radius: 10px;
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.drp_select :hover {
  background-color: gray;
  color: white;
}

.drp_select :selection {
  background-color: gray;
  color: white;
}

.view_inp_home {
  color: black;
  border: none;
  background: #fef9ef;
  width: 90%;
  margin-top: 20px;
  height: 40px;
  text-align: start;
  text-align: left;
  border-radius: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

.dropdown-menu.show {
  margin-top: 0px !important;
  margin-left: -30px !important;
  border: 1px solid #b9ddf4;
  box-shadow: 1px 1px 33px 1px #e7e7e7;
  width: 90%;
}

.Button {
  background-color: #8b60fd;
  color: white;
  border: none;
  border-radius: 8px;
  height: 30px;
  padding: 4px 10px;
  border: 2px solid white;
  box-shadow: 1px 1px 1px 1px #dcd6d6;
}

.Button :hover {
  opacity: 0.5;
}

.notice p {
  width: 50%;
  text-align: start;
}

.notice h1 {
  font-weight: 700;
  margin-top: 20px;
}

.notice_heading {
  padding-top: 20px;
  margin-bottom: 20px;
}

.notice_head {
  font-weight: 600;
  text-align: start;
}

.notice_para {
  width: 80%;
  text-align: start;
  font-size: 20px;
  font-weight: 550;
  margin-top: 20px;
  margin-right: 3rem;
  margin-left: 3rem;
  position: relative;
}

.notice_heading h1 {
  text-align: start;
}

.progressBar {
  color: #2b9fe8;
  font-size: 18px;
  position: relative;
}

.viewAll {
  color: #918e96;
  font-size: 16px;
  cursor: pointer;
}

.progressDiv {
  background-color: white;
  border: none;
  border-radius: 10px;
  max-width: 494px;
  width: 100%;
  height: 376px;
}

.scoreDiv {
  background-color: white;
  border: none;
  border-radius: 10px;
  width: 85%;
  height: 133px;
  position: relative;
  padding-top: 2%;
}

.scoreDiv img {
  border-radius: 10px;
  padding: 5px;
}

.snowDiv {
  background-color: white;
  border: none;
  border-radius: 20px;
  /* max-height: 628px; */
  height: auto;
  width: 25%;
  padding-bottom: 20px;
}

.name {
  color: #8b60fd;
  font-size: 25px;
  font-weight: 400;
}

.imgContainer {
  position: relative;
  width: 100%;
}

.imgContainer .img {
  width: auto;
  height: 190px;
}

.imgContainer .edit {
  position: absolute;
  top: 10%;
  left: 85%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  color: white;
  border: none;
  cursor: pointer;
  text-align: center;
  box-shadow: 3px 3px 3px #00000029;
}

.progress_Whole_Div {
  width: 494px;
}

.score_Whole_Div {
  width: 387px;
}

.Subn_name {
  position: absolute;
  top: 45%;
  left: 5%;
  font-size: 24px;
}

.avgOuter {
  width: 103px;
  height: 103px;
  border-radius: 50%;
  border: 5px solid #e97838;
  position: absolute;
  top: 53%;
  left: 70%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  background-color: white;
}

.avgInner {
  width: 75px;
  height: 75px;
  border-radius: 50%;
  background-color: #e97838;
  border: none;
  margin: 9px;
  text-align: center;
  padding-top: 20px;
}

.avgInner .avg {
  font-size: 12px;
  font-weight: 600;
}

.avgInner .percent {
  font-weight: 700;
  margin-top: 5px;
}

.squipill_container {
  position: relative;
  max-width: 394px;
  max-height: 157px;
  width: 100%;
  height: auto;
}

.homeback {
  width: 394px;
  height: 157px;
}

.squipill_books {
  position: absolute;
  left: 5%;
  width: 93px;
  height: 137px;
  top: 7%;
}

.welcome_squipill {
  position: absolute;
  right: 12%;
  top: 26%;
}

.squillup,
.squillup span {
  font-weight: 600;
  font-size: 20px;
}

.progressBarAlign,
.progressBarAlign1 {
  margin-left: 50px;
  margin-top: -164px;
}

.dash {
  font-size: 20px;
  font-weight: 600;
}

.num_blue {
  font-size: 20px;
  font-weight: 600;
  font-size: 10px;
  color: #259de9;
}

.num_gray {
  color: #918e96;
  font-size: 20px;
  font-weight: 600;
  font-size: 10px;
}

.dash_range {
  margin-top: -20px;
}

.dropbtn {
  color: black;
  border: none;
  background: #fef9ef;
  width: 90%;
  margin-top: 20px;
  height: 50px;
  text-align: start;
  border-radius: 10px;
  cursor: pointer;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border: none;
  border-radius: 10px;
  max-height: 300px;
  overflow-y: auto;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  width: 100%;
  text-align: left;
  border-bottom: 1px solid rgb(223, 217, 217);
}

.dropdown-content a:hover {
  background-color: #f1f1f1;
}

.dropdown-content {
  display: block;
}

.show {
  display: block;
}

.loader_div {
  height: 100vh;
  width: 100vw;
  background-color: white;
  opacity: 0.5;
  z-index: 500;
}

.progress_flex {
  display: flex;
  justify-content: space-around;
}

.maths_prg {
  color: #2d6589;
  margin-top: 100px;
  margin-left: 50px;
  font-size: 20px;
}

.science_prg {
  color: #2d6589;
  margin-top: 100px;
  margin-left: -25px;
  font-size: 20px;
}

.calendar_top_div {
  border-radius: 30px;
  margin-left: 1rem;
  margin-right: 1rem;
}

/* notice with vector images */

.notice-div {
  position: relative;
  max-width: 501px;
  max-height: 184px;
  /* height: 100%; */
  width: 100%;
  border: none;
  border-radius: 20px;
  background-color: #facd51;
}

.circle_img img {
  padding-top: 10%;
  margin-left: -80px;
  overflow: hidden;
  position: absolute;
}

.book_img {
  position: absolute;
  padding-left: 50%;
  padding-top: 7%;
}

.calendr_img {
  position: absolute;
  padding-left: 85%;
  padding-top: 10%;
}

/* design change css */

.greetings {
  font-size: 20px;
  font-weight: 500;
}

.no_notice {
  font-size: 22px;
  margin-top: 3.5rem;
}

/* media query stars here */
@media only screen and (min-device-width: 1201px) and (min-device-width: 1440px) {
  .notice_squipill_div {
    display: flex;
    justify-content: space-around;
  }
}
@media only screen and (min-device-width: 1201px) {
  .notice_squipill_div {
    display: flex;
    justify-content: space-evenly;
  }

  .dash_side_div {
    width: 75%;
    margin-left: -10px;
  }

  .notice {
    height: 220px;
    border: none;
    border-radius: 20px;
    margin-right: 0.5rem !important;
    width: 90%;
  }

  .progress_score_div {
    display: flex;
    justify-content: space-evenly;
    margin-right: 0.5rem !important;
  }

  .progressBar_mainDiv {
    max-width: 494px;
    width: 100%;
  }

  .score_main_div {
    max-width: 387px;
    width: 100%;
  }

  .calendar_top_div {
    margin-top: 2.3rem;
  }

  .imgContainer {
    margin-top: 1rem;
  }

  .notice {
    display: none;
  }
}

@media only screen and (min-device-width: 1024px) and (max-device-width: 1200px) {
  .notice_squipill_div {
    display: block;
  }

  .calendar_top_div {
    margin-top: 3rem;
  }

  .imgContainer {
    margin-top: 1rem;
  }

  .dash_side_div {
    width: 60%;
  }

  .snowDiv {
    width: 38%;
    height: 100%;
    padding-bottom: 30px;
    margin-left: 10px;
  }

  .progress_score_div {
    display: block;
    margin-right: 0.5rem;
  }

  .progressBar_mainDiv {
    width: 90%;
  }

  .score_main_div {
    width: 90%;
    margin-right: 0.25rem;
    margin-top: 15px;
  }

  .notice {
    height: 220px;
    border: none;
    border-radius: 20px;
    margin-right: 0.5rem !important;
    width: 90%;
  }

  .notice p {
    width: 80%;
    text-align: start;
  }

  /* new design responsive */
  .homeback {
    max-width: 501px;
    width: 100%;
    height: 157px;
  }

  .squipill_container {
    max-width: 501px;
    max-height: 157px;
    width: 100%;
    height: auto;
  }

  .scoreWhiteDiv {
    height: auto;
    max-width: 501px;
    width: 100%;
    background-color: white;
    border-radius: 10px;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }

  .scoreDiv {
    border-radius: 10px;
    width: 85%;
    height: 180px;
    position: relative;
    padding-top: 2%;
  }

  .no_notice {
    font-size: 22px;
    margin-top: 0px;
    padding: 2rem;
  }
  .homeSideDiv {
    padding: 0px 10px 10px 20px !important;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1023px) {
  .notice {
    height: auto;
    border: none;
    border-radius: 20px;
    width: 98%;
    padding-bottom: 20px;
    margin-right: 2px !important;
    margin-left: 2px !important;
  }
  .sideDiv {
    display: block !important;
    padding: 10px 20px 10px 20px !important;
  }
  .progress_score_div {
    display: block;
    margin-right: 0.5rem;
  }

  .progressBar_mainDiv {
    width: 98%;
  }

  .score_main_div {
    margin-top: 20px;
    width: 98%;
  }

  .snowDiv {
    width: 98%;
    height: auto;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-top: 20px;
  }

  .snow_cal {
    display: flex;
  }

  .calendar_top_div {
    margin-top: 1rem !important;
  }

  .imgContainer {
    margin-top: 0px !important;
  }

  .scoreDiv {
    border-radius: 10px;
    width: 85%;
    height: auto;
  }

  .notice p {
    width: 80%;
    text-align: start;
  }

  .avgOuter {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    border: 5px solid #e97838;
    position: absolute;
    top: 53%;
    left: 70%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-color: white;
  }

  .avgInner {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: #e97838;
    border: none;
    margin: 19px;
    text-align: center;
    padding-top: 20px;
  }

  .avgInner .avg {
    font-size: 12px;
    font-weight: 600;
  }

  .avgInner .percent {
    font-weight: 700;
    font-size: 20px;
    margin-top: 15%;
  }

  .homeback {
    max-width: 700px;
    width: 100%;
    height: 157px;
  }

  .notice-div {
    max-width: 700px;
    width: 100%;
    max-height: 250px;
    height: 100%;
    padding: 10px 0px;
  }

  .squipill_container {
    max-width: 700px;
    max-height: 157px;
    width: 100%;
    height: auto;
  }

  .progressDiv {
    max-width: 700px;
    width: 100%;
    height: 376px;
  }

  .scoreWhiteDiv {
    height: auto;
    max-width: 700px;
    width: 100%;
    padding-bottom: 30px;
  }

  .no_notice {
    font-size: 22px;
    margin-top: 0px;
    padding: 1rem;
  }
}

@media only screen and (min-device-width: 200px) and (max-device-width: 767px) {
  .notice {
    height: auto;
    border: none;
    border-radius: 20px;
    width: 95%;
    padding-bottom: 20px;
    margin-right: 0px !important;
    margin-left: 0px !important;
    background-color: #d2bff4;
    margin-top: 10px;
  }

  .progress_score_div {
    display: block;
    margin-right: 0px !important;
    margin-left: 0px !important;
  }

  .progressBar_mainDiv {
    width: 100%;
  }

  .score_main_div {
    margin-top: 20px;
    width: 100%;
  }

  .snowDiv {
    width: 100%;
    height: auto;
    margin-left: 0px;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .snow_cal {
    display: block;
  }

  .calendar_top_div {
    margin-top: 1rem !important;
  }

  .imgContainer {
    margin-top: 0px !important;
    margin-bottom: 3rem;
  }

  .dash_side_div {
    width: 100%;
    margin-left: 0px;
  }

  .progressDiv {
    padding-bottom: 20px;
    padding-top: 10px;
    height: 100%;
  }

  .scoreWhiteDiv {
    height: 100%;
    width: 100%;
    padding-bottom: 20px;
  }

  .scoreDiv {
    /* height: auto; */
  }

  .notice p {
    width: 70%;
    text-align: start;
  }

  .science_prgress_align {
    margin-right: 0px;
  }

  .home_container {
    margin-left: 0px;
  }

  /* new design changes */

  .calendr_img {
    padding-left: 80%;
    padding-top: 10%;
  }

  .notice-div {
    max-width: 450px;
    max-height: 500px;
    height: auto;
    padding: 10px;
    width: 100%;
    border-radius: 10px;
  }

  .squipill_container {
    max-width: 394px;
    max-height: 157px;
    width: 100%;
    height: auto;
  }

  .homeback {
    max-width: 394px;
    width: 100%;
    height: 157px;
  }

  .circle_img img {
    padding-top: 30%;
    margin-left: -98px;
  }

  .book_img {
    position: absolute;
    padding-left: 40%;
    padding-top: 7%;
  }

  .calendr_img {
    padding-left: 70%;
    padding-top: 10%;
  }

  .notice_para {
    width: 100%;
    margin-right: 0.5rem;
    margin-left: 0.5rem;
  }

  .imgContainer .img {
    width: 250px;
    /* width: auto; */
  }
  .sideDiv {
    display: block !important;
  }
  .no_notice {
    font-size: 18px;
    margin-top: 0px;
    padding: 1rem;
  }
}

@media only screen and (min-device-width: 200px) and (max-device-width: 475px) {
  .progress_flex {
    display: block;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 374px) {
  .maths_prg {
    color: #2d6589;
    margin-top: 30%;
    margin-left: 55px;
  }

  .science_prg {
    color: #2d6589;
    margin-top: 30%;
    margin-left: -26px;
  }

  .progressBarAlign {
    margin-left: 50px;
    margin-top: -138px;
  }

  .progressBarAlign1 {
    margin-left: 50px;
    margin-top: -138px;
  }

  .welcome_squipill {
    position: absolute;
    right: 2%;
    top: 47%;
  }
}

@media only screen and (min-device-width: 375px) and (max-device-width: 424px) {
  .maths_prg {
    color: #2d6589;
    margin-top: 25%;
    margin-left: 29%;
  }

  .science_prg {
    color: #2d6589;
    margin-top: 25%;
    margin-left: -30px;
  }

  .progressBarAlign {
    margin-left: 50px;
    margin-top: -114px;
  }

  .progressBarAlign1 {
    margin-left: 50px;
    margin-top: -114px;
  }

  .welcome_squipill {
    position: absolute;
    right: 5%;
    top: 26%;
  }
}

@media only screen and (min-device-width: 425px) and (max-device-width: 767px) {
  .maths_prg {
    color: #2d6589;
    margin-top: 22%;
    padding: 10px;
    margin-left: 17%;
  }

  .science_prg {
    color: #2d6589;
    margin-top: 22%;
    margin-left: -30px;
  }

  .progressBarAlign {
    margin-left: 50px;
    margin-top: -90px;
  }

  .progressBarAlign1 {
    margin-left: 50px;
    margin-top: -90px;
  }
  .sideDiv {
    display: block !important;
  }
  .sideDiv_header {
    display: block !important;
    margin: 15px !important;
    padding: 0;
  }
}

@media only screen and (min-device-width: 200px) and (max-device-width: 767px) {
  .sideDiv,
  .sideDiv_header {
    display: block !important;
    margin: 15px !important;
    padding: 0;
  }
  .sideDiv {
    padding: 10px 10px 10px 10px !important;
  }
}
@media only screen and (min-device-width: 200px) and (max-device-width: 1023px) {
  .image-size {
    width: 50% !important;
    height: 50% !important;
  }
  .image-size-paper {
    width: 100% !important;
    height: 100% !important;
  }
}

@media only screen and (min-device-width: 1024px) and (max-device-width: 1325px) {
  .progress {
    margin-bottom: 0px !important;
  }
}
@media only screen and (min-device-width: 320px) and (max-device-width: 475px) {
  .dropbtn {
    width: 90%;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1920px) {
  .dropbtn {
    margin-top: 10px !important;
  }
}

.image-size {
  width: 25%;
  height: 25%;
}
.image-size-paper {
  width: 50%;
  height: 50%;
}
