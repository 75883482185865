.header_prg {
  background-color: white;
  border: none;
  box-shadow: 0px 0px 6px #00000029;
  margin-left: 91px;
  max-width: 4000px;
  width: auto;
  margin-bottom: 10px;
}

.progressBar_bg {
  height: 38px;
  width: 350px;
  border: 3px solid black;
  border-radius: 20px;
  background-color: #6c483c;
}

.progress_img {
  padding: 4px 0px 4px 4px;
  height: 34px;
}
.progressContainer {
}
.chptr_name {
  height: 60px;
  width: 325px;
  font-weight: 600;
  font-size: 18px;
  padding-top: 10px;
  border: 3px solid black;
  border-radius: 5px;
  margin-left: 5px;
  background-color: white;
  text-align: left;
  padding: 10px;
}
.sideDiv {
  margin-left: 87px;
  padding-bottom: 50px;
  display: flex;
  background-color: #fef9ef;
  min-height: 64vh !important;
  height: 100%;
  padding: 10px 20px 10px 10px;
}
.cont_chptr {
  position: relative;
  width: 50px;
  height: 50px;
  z-index: 2;
}

.cont_chptr .img_chptr {
  width: 100%;
  height: auto;
}

.chptr {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  height: 30px;
  width: 30px;
  color: white;
  display: flex;
  font-size: 20px;
  justify-content: center;
  font-weight: 600;
  padding-top: 5px;
}

.cont_done {
  height: 56px;
  width: 56px;
  position: relative;
  border-radius: 10px;
}
.cont_done .img_done {
  width: 100%;
  height: auto;
}

.done {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  height: 25px;
  width: 25px;
  color: white;
  display: flex;
  justify-content: center;
  font-weight: 500;
  padding-top: 5px;
}

.prg_container {
  position: relative;
  height: 38px;
  width: 340px;
}

.slider_out {
  width: 315px;
  height: auto;
  z-index: 0;
}

.sliderInside {
  position: absolute;
  top: 70%;
  left: 45%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  z-index: 1;
}

.cont_chptr .prgrs_value {
  height: 75px;
  width: 75px;
  padding: 4px;
  margin-left: 23px;
  margin-top: -20px;
  padding-top: 8px;
}

.prgs_value {
  margin-top: -48px;
  color: white;
  font-weight: 500;
  font-size: 16px;
}

.progress {
  --bs-progress-height: 1rem;
  --bs-progress-font-size: 0.75rem;
  --bs-progress-bg: #e9ecef;
  --bs-progress-border-radius: 0.375rem;
  --bs-progress-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  --bs-progress-bar-color: #fff;
  --bs-progress-bar-bg: #0d6efd;
  --bs-progress-bar-transition: width 0.6s ease;
  display: flex;
  overflow: hidden;
  font-size: var(--bs-progress-font-size);
  z-index: 1 !important;
  padding: 6px 4px 4px 4px !important;
  height: 28px !important;
  width: 277px !important;
  background-color: #68453b !important;
  border: none !important;
  border-radius: 20px !important;
  margin-top: -16px !important;
}

.chaptr_one_div {
  background-color: white;
  border-radius: 12px;
  /* padding: 20px 10px; */
}

.chaptr_one_div {
  display: flex;
  justify-content: center;
}

/* responsive code starts here */

@media only screen and (min-device-width: 1324px) and (max-device-width: 1440px) {
  .prgrs_chptr_div {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(1, 1fr);
    grid-column-gap: 2.1rem;
  }
  .progressBar {
    align-items: center;
  }
  .chaptr_one_div {
    margin-top: 0px;
    margin-right: 0px;
  }
  .progressBar {
    padding-left: 10px;
  }
  .lesson_prgs_div {
    padding-left: 15px;
  }
  .lesson_prgs_div {
    height: 330px;
    overflow-y: scroll;
  }
  .chptr_name {
    height: 56px;
    width: 310px;
    font-weight: 600;
    font-size: 18px;
    padding-top: 10px;
  }
  .prg_container {
    position: relative;
    height: 38px;
    width: 340px;
  }
}

@media only screen and (min-device-width: 1024px) and (max-device-width: 1440px) {
  .prgrs_chptr_div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(1, 1fr);
    grid-column-gap: 0.5em;
  }
  .chptr_name {
    height: 60px;
    width: 200px;
    font-weight: 600;
    font-size: 14px;
    padding: 10px;
  }
  .cont_done {
    height: 60px;
    width: 60px;
    position: relative;
    border-radius: 10px;
  }
  .slider_out {
    width: 200px;
    height: 28px;
    z-index: 0;
  }
  .prg_container {
    position: relative;
    height: 38px;
    width: 200px;
  }
  .progress {
    width: 180px !important;
    height: 20px !important;
    margin-bottom: 10px;
  }
  .chaptr_one_div {
    margin-top: 0px;
  }
  .lesson_prgs_div {
    height: 500px;
    overflow-y: auto;
  }
  .cont_chptr .prgrs_value {
    height: 70px;
    width: 70px;
    padding: 4px;
    margin-left: -4px;
    margin-top: -23px;
    padding-top: 8px;
  }
  .prgs_value {
    margin-top: -48px;
    color: white;
    font-weight: 500;
    font-size: 16px;
    margin-left: 14px !important;
  }
}
@media only screen and (min-device-width: 1024px) and (max-device-width: 1325px) {
  .prgrs_chptr_div {
    display: grid;
    grid-template-columns: repeat(3, 0.33fr);
    grid-template-rows: repeat(1, 0.3fr);
    grid-column-gap: 0.3em;
  }
  .progressBar {
    align-items: center;
  }
  .progressScroll {
    height: 380px !important;
    overflow-y: scroll !important;
  }
  .prg_container {
    position: relative;
    height: 30px;
    width: 200px;
  }
  .chptr_name {
    height: auto;
    width: 200px;
    font-weight: 600;
    font-size: 14px;
    padding: 10px;
  }
  .cont_done {
    height: 45px;
    width: 45px;
    position: relative;
    border-radius: 10px;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1023px) {
  .prgrs_chptr_div {
    display: block;
  }
  .progressBar {
    padding-left: 20px;
  }
  .lesson_prgs_div {
    padding-left: 30px;
  }
  .chaptr_one_div {
    margin-top: 40px;
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: #fef9ef;
  }
  .cont_chptr .img_chptr {
    margin-left: 40px;
  }
  .chptr {
    left: 65px;
  }
  .prg_container {
    left: 40px;
  }
  .lesson_prgs_div {
    margin-left: 30px;
  }
  .lessonProgress {
    margin-left: 30px;
  }
  .tablet_white_div {
    width: 450px;
    padding: 20px;
    border: none;
    border-radius: 12px;
    background-color: white;
  }

  .centered_div {
    display: flex;
    justify-content: center;
  }
}
@media only screen and (min-device-width: 200px) and (max-device-width: 767px) {
  .prgrs_chptr_div {
    display: block;
  }
  .chptr_name {
    height: auto;
    width: 200px;
    font-weight: 600;
    font-size: 13px;
    padding: 10px 0px 0px 10px;
  }
  .cont_done {
    height: 45px;
    width: 45px;
    position: relative;
    border-radius: 10px;
  }
  .progressBar {
    padding-left: 10px;
  }
  .cont_chptr .img_chptr {
    margin-left: 5px;
  }
  .lesson_prgs_div {
    padding-left: 20px;
  }
  /* .chptr_name {
    height: 50px;
    width: 220px;
    font-weight: 600;
    font-size: 14px;
    padding: 10px 15px;
  } */
  .slider_out {
    width: 210px;
    height: auto;
    z-index: 0;
  }
  .prg_container {
    position: relative;
    height: 32px;
    width: 220px;
    margin-top: 15px;
  }
  .prgs_value_mobile {
    margin-left: 0px !important;
  }
  .progress {
    width: 175px !important;
  }

  .sliderInside {
    top: 70%;
    left: 40%;
  }
  .cont_chptr {
    margin-top: 5px;
  }
  .chptr {
    top: 33%;
    left: 60%;
  }
  .chaptr_one_div {
    margin-bottom: 40px;
  }
  .cont_chptr .prgrs_value {
    height: 60px;
    width: 60px;
    padding: 4px;
    margin-left: -3px;
    margin-top: -23px;
    padding-top: 8px;
  }
  .prgs_value {
    margin-top: -39px;
    color: white;
    font-weight: 500;
    font-size: 14px;
    margin-left: 0px;
  }
  .centered_div {
    display: flex;
    justify-content: center;
  }
  .header_prg {
    margin-left: 0px;
  }
  /* .cont_done {
    height: 50px;
    width: 50px;
  } */
}

@media only screen and (min-device-width: 200px) and (max-device-width: 600px) {
}

/* Add Css */
@media only screen and (min-device-width: 1440px) and (max-device-width: 1920px) {
  .prgrs_chptr_div {
    justify-content: space-between;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 0.33fr);
    grid-template-rows: repeat(1, 0.3fr);
    grid-column-gap: 0.3em;
  }
  .sideDiv {
    padding: 0px 10px 0px 20px !important;
  }
  .lesson_prgs_div {
    height: 470px;
    overflow-y: scroll;
  }
}
@media only screen and (min-device-width: 320px) and (max-device-width: 400px) {
  .navLink {
    gap: 5px;
  }
}
