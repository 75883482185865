.outer {
  width: 100vw;
  min-height: 100vh;
  height: auto;
  background-color: #c6e4ce;
  padding: 3rem;
}
.tab_div {
  display: flex;
  justify-content: space-around;
  margin-bottom: -4px;
}

.inner_left {
  font-size: 30px;
  font-weight: 600;
  color: #064974;
  width: 307px;
  height: 85px;
  background-color: #fef9ef;
  border: 3px solid #e97838;
  border-radius: 30px 30px 0px 0px;
  padding-top: 15px;
}

.inner_left_active {
  font-size: 30px;
  font-weight: 600;
  color: #064974;
  width: 307px;
  height: 85px;
  background-color: #fef9ef;
  border: 3px solid #e97838;
  border-bottom: none;
  border-radius: 30px 30px 0px 0px;
  padding-top: 15px;
}

.inner {
  width: 100%;
  min-height: 70vh;
  height: auto;
  border: 3px solid #e97838;
  border-radius: 40px;
  background-color: #fef9ef;
}

/* text */

.txt {
  text-align: left;
  color: #918e96;
  border: none;
  margin: 2rem;
  padding: 4rem;
  font-weight: 600;
}

.txt_inner {
  margin-top: 50px;
  color: #918e96;
  font-size: 25px;
  text-align: left;
}

.heading_txt {
  color: #918e96;
  font-size: 30px;
  text-align: left;
  font-weight: 600;
}

.close_solution {
  margin-left: 90%;
  cursor: pointer;
}

/* Media query for screens with a maximum width of 425px */

@media only screen and (min-width: 1024px) {
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .txt {
    margin: 2rem;
    padding: 3rem;
  }
  .inner_left,
  .inner_left_active {
    width: 270px;
    padding-top: 12px;
  }
}

@media only screen and (min-width: 550px) and (max-width: 768px) {
  .txt {
    margin: 1rem;
    padding: 1rem;
  }
  .inner_left,
  .inner_left_active {
    width: 210px;
    height: 65px;
    padding-top: 12px;
  }
  .outer {
    padding: 2rem;
  }
}

@media only screen and (min-width: 284px) and (max-width: 549px) {
  .txt {
    margin: 1rem;
    padding: 1rem;
  }
  .inner_left,
  .inner_left_active {
    font-size: 20px;
    font-weight: 550;
    border-radius: 20px 20px 0px 0px;
    width: 120px;
    height: 65px;
    padding-top: 12px;
  }

  .outer {
    padding: 0.5rem;
  }
  .heading_txt {
    font-size: 20px;
    font-weight: 600;
  }
  .txt_inner {
    margin-top: 20px;
    font-size: 18px;
  }
  .close_solution {
    margin-left: 85%;
  }
  .inner {
    border: 3px solid #e97838;
    border-radius: 28px;
  }
}
