.month {
  margin-top: -35px;
  color: white;
  font-size: 26px;
  font-weight: 600;
  text-transform: uppercase;
  font-family: sans-serif, Poppins;
  letter-spacing: 0.2rem;
}

.p-datepicker table td > span {
  transition: box-shadow 0.2s;
  border-radius: 0%;
  font-size: 12px;
}
.calenderMonth{
  font-size: 17px !important;
}
.p-datepicker table td {
  width: 42px;
  height: 14px;
  padding: 0.2rem;
  color: white;
  background-color: #64c08a;
  border: 5px solid #e6ffbe;
}

/* .holiday_td {
  background-color: #ffb252;
  color: #ffffff;
  font-weight: bold;
  margin: 0;
  border-radius: 0px;
  width: 30px;
  height: 27px;
  padding: 0.2rem;
  cursor: pointer;
} */

.p-datepicker table td.p-datepicker-today {
  color: white;
  background-color: #8b60fd;
  border: 5px solid #e6ffbe;
  width: 42px;
  height: 27px;
  padding: 0.2rem;
}

.p-datepicker .p-datepicker-header {
  height: 50px;
  display: flex;
  justify-content: center;
  padding: 0.5rem;
  color: #495057;
  background: #ffffff;
  font-weight: 600;
  margin: 0;
  border-bottom: 1px solid #dee2e6;
  border-top-right-radius: 30px;
  border-top-left-radius: 30px;
  background-color: #ffb252;
}

.p-datepicker-header button {
  background-color: #ffb252;
  border: none;
}

.p-datepicker table td.p-datepicker-today > span.p-highlight {
  color: white;
  background: #8b60fd;
}

.p-datepicker-calendar {
  width: 95%;
  margin: 10px 6px;
  height: 100%;
}

.p-datepicker table th {
  padding: 0.2rem;
  background-color: #064974;
  color: white;
  border: 1px solid #064974;
  font-size: 14px;
}

.p-datepicker-year {
  display: none;
}

.p-datepicker-month {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 25px;
  opacity: 1;
  padding: 0px;
}

.hooks_container {
  margin-bottom: -60px;
  width: 300px;
}

.p-datepicker-prev {
  display: none !important;
}

.p-datepicker-next {
  display: none !important;
}

.calendar_hooks_circle {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background: #464b94 0% 0% no-repeat padding-box;
  opacity: 1;
  z-index: 0;
}

.calendar_hooks {
  width: 18px;
  height: 64px;
  z-index: 1;
  border-radius: 10px;
  background: #ffd775 0% 0% no-repeat padding-box;
  opacity: 1;
}

.p-datepicker-header {
  display: none !important;
}

.calendar_topHeader {
  min-width: 100%;
  margin-top: -20px;
  background: white;
  height: 72px;
}
.calenderContainer {
  margin-top: 15px;
  min-width: 100%;
  width: 300px !important;
  max-height: 270px;
  min-height: 265px;
  background-color: #e6ffbe;
  border-radius: 0px 0px 30px 30px;
}
.p-calendar .p-datepicker {
  min-width: 100%;
  width: 300px !important;
  max-height: 270px;
  min-height: 265px;
  background-color: #e6ffbe;
  border-radius: 30px;
}

/* media query stars here */

@media only screen and (min-device-width: 1401px) {
  .calendar_topHeader {
    min-width: 100%;
  }
  .calenderContainer {
    min-width: 100%;
    width: 300px !important;
  }
  .p-calendar .p-datepicker {
    min-width: 100%;
    width: 300px !important;
  }
}

@media only screen and (min-device-width: 1200px) and (max-device-width: 1400px) {
  .calendar_topHeader {
    width: 220px;
    min-width: 100%;
  }
  .calenderContainer {
    min-width: 100%;
    width: 220px !important;
  }
  .p-calendar .p-datepicker {
    min-width: 100%;
    width: 220px !important;
  }
}
@media only screen and (min-device-width: 1023px) and (max-device-width: 1200px) {
  .calendar_topHeader {
    width: 220px;
    min-width: 100%;
  }
  .calenderContainer {
    min-width: 100%;
    width: 220px !important;
  }
  .p-calendar .p-datepicker {
    min-width: 100%;
    width: 220px !important;
  }
}

@media only screen and (min-device-width: 1024) and (max-device-width: 1110) {
  .calendar_topHeader {
    width: 200px;
    min-width: 100%;
  }
  .calenderContainer {
    min-width: 100%;
    width: 200px !important;
  }
  .p-calendar .p-datepicker {
    min-width: 100%;
    width: 200px !important;
  }
  .p-datepicker-calendar {
    width: 95%;
    margin-top: 10px;
  }
}

@media only screen and (min-device-width: 250px) and (max-device-width: 767px) {
  .calendar_topHeader {
    width: 200px;
    min-width: 100%;
  }
}
@media only screen and (min-device-width: 375px) and (max-device-width: 400px) {
  .calendar_topHeader {
    width: 300px;
    min-width: 100%;
  }
}

@media only screen and (min-device-width: 476px) and (max-device-width: 767px) {
  .p-datepicker-calendar thead tr {
    height: 50px;
  }
  .p-calendar .p-datepicker {
    min-width: 100%;
    width: 300px !important;
    min-height: 340px;
    height: auto;
    background-color: #e6ffbe;
    border-radius: 30px;
  }
  .p-datepicker table td {
    height: 50px;
  }
}

@media only screen and (max-device-width: 375px) {
  .calendar_topHeader {
    width: 250px;
    min-width: 100%;
  }
  .calenderContainer {
    width: 250px !important;
    min-height: 265px;
    height: auto;
  }
  .p-calendar .p-datepicker {
    min-width: 100%;
    width: 250px !important;
    min-height: 240px;
    height: auto;
  }
}


@media only screen and (min-device-width: 200px) and (max-device-width: 320px) {

.calenderMonth {
  font-size: 17px !important;
  padding-left: 25px;
}
}