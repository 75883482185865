.Notification_left_container {
  min-width: 900px;
  width: 100%;
  min-height: 560px;
  background-color: white;
  margin-right: 1.5rem;
  border-radius: 12px;
}

.Notification_right_container {
  min-width: 335px;
  min-height: 560px;
  background-color: white;
  margin-right: 1rem;
}

.month_ago {
  width: 200px;
}

.container_notification {
  display: flex;
  justify-content: space-between;
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.notification_header {
  height: 46px;
  width: 100%;
  background-color: #f2f7ff80;
  box-shadow: 0px 3px 6px #00000029;
  text-align: start;
  padding: 10px 60px;
}

.notification_set_header {
  height: 46px;
  width: 100%;
  background-color: #f2f7ff80;
  box-shadow: 0px 3px 6px #00000029;
  text-align: start;
  padding: 10px 20px;
}

.notification_div {
  height: 85px;
  width: 100%;
  background-color: white;
  text-align: start;
  padding: 15px 45px;
  font-size: 14px;
}

.notification_read_div {
  height: 85px;
  width: 100%;
  background-color: #f4f5ff;
  text-align: start;
  padding: 15px 45px;
  font-size: 14px;
}

.profile_img {
  width: 46px;
  height: 46px;
}

.title_Noti {
  padding-top: 10px;
  padding-left: 20px;
}

.setting_head { 
  font-size: 14px;
  font-weight: bold;
  text-align: start;
}

.setting_cont {
  color: #333333;
  font-size: 14px;
  text-align: start;
}

.kurttC:checked + span::before {
  transform: translateX(9px) !important;
}

/* toggle css starts  */

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 2px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

@media only screen and (min-width: 1225px) {
}

@media only screen and (min-width: 1024px) and (max-width: 1224px) {
  .Notification_left_container {
    min-width: 600px;
    width: 100%;
    min-height: 560px;
    background-color: white;
    margin-right: 1.5rem;
    border-radius: 12px;
  }

  .Notification_right_container {
    min-width: 250px;
    min-height: 560px;
    background-color: white;
    margin-right: 1rem;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .Notification_left_container {
    min-width: 400px;
    width: 100%;
    min-height: 560px;
    background-color: white;
    margin-right: 1.5rem;
    border-radius: 12px;
  }

  .Notification_right_container {
    min-width: 200px;
    min-height: 560px;
    background-color: white;
    margin-right: 1rem;
  }
  .notification_div {
    height: 70px;
    width: 100%;
    background-color: white;
    text-align: start;
    padding: 8px 19px;
    font-size: 14px;
  }
  .notification_read_div {
    height: 70px;
    width: 100%;
    text-align: start;
    padding: 8px 19px;
    font-size: 14px;
    background-color: #f4f5ff;
  }
  .month_ago {
    margin-top: 11px;
    width: 66px;
    font-size: 10px;
  }
}

@media screen and (max-width: 767px) {
  .Notification_left_container {
    min-width: 250px;
    max-width: 400px;
    width: 100%;
    min-height: 500px;
    background-color: white;
    margin-right: 0px;
    border-radius: 12px;
  }
  .topnav_profile_img{
    height: auto;
    width: 35px;
  }
  .title_Noti {
    padding-top: 10px;
    padding-left: 0px !important;
  }
  .Notification_right_container {
    display: none;
  }
  .notification_div {
    height: 60px;
    width: 100%;
    background-color: white;
    text-align: start;
    padding: 5px 10px;
    font-size: 12px;
  }
  .notification_read_div {
    height: 60px;
    width: 100%;
    text-align: start;
    padding: 5px 10px;
    font-size: 12px;
    background-color: #f4f5ff;
  }
  .month_ago {
    margin-top: 11px;
    width: 66px;
    font-size: 10px;
  }
  .profile_img {
    width: 40px;
    height: 40px;
  }
}


@media only screen and (min-device-width: 1024px) and (max-device-width: 1920px){
  .topnav_profile_img {
    height: auto;
    width: 35px;
} 
}