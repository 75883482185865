.three_Row {
  display: flex;
  justify-content: space-between;
}

.selftest_head {
  background-color: #e97838;
  /* border: 2px solid white; */
  box-shadow: 1px 1px 1px 1px #e97838;
  border-radius: 49px;
  width: 483px;
  height: 45px;
  font-size: 20px;
  color: white;
  padding-top: 5px;
}

.parent {
  width: 100%;
  min-height: 800px;
  height: auto;
  padding-bottom: 10px;
  background: #f9f6ee;
}

.parent_elm {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  background-color: #f9f6ee;
  border-radius: 20px;
  margin: auto;
  width: 92%;
  height: 80vh;
  border: 2px solid #989898;
  display: flex;
  justify-content: center;
  padding: 0px 15px;
  overflow: hidden;
}

.qstn_no {
  color: #064974;
  font-size: 14px;
}

.q_no {
  width: 33px;
  height: 34px;
  background-color: #fdf980;
  border: 2px solid #fafafa;
  color: #064974;
  font-size: 24px;
  border-radius: 5px;
  box-shadow: 3px 3px 3px #00000029;
}

.question_conatier {
  position: absolute;
  width: 92%;
  border-radius: 50px;
  height: 80vh;
  overflow: hidden;
  /* padding: 3rem; */
}

.ans_input {
  width: 75px;
  height: 32px;
  background: #ffffff;
  border: 1px solid #064974;
  border-radius: 4px;
  margin-left: 20px;
}

.flower_img {
  width: 88px;
  height: 88px;
}

.marks {
  color: #918e96;
  font-size: 11px;
}

.str {
  color: #064974;
}

.ans_input::before {
  border: 1px solid #064974;
}

.flex-container {
  display: flex;
  justify-content: space-between !important;
  width: "90%" !important;
}

.timmer {
  width: 98px;
  height: 36px;
  background: white;
  border: 1px solid var(--unnamed-color-111111);
  box-shadow: 0px 3px 6px #0000000d;
  border: 1px solid #111111;
  border-radius: 6px;
}

.question_class {
  color: #064974;
  font-size: 22px;
}

.save_btn {
  background-color: #ff6700;
  border: 2px solid white;
  box-shadow: 2px 2px 2px 2px #ff6700;
  border-radius: 20px;
  width: 109px;
  height: 44px;
  font-size: 24px;
  color: white;
  font-weight: bold;
}

.practice_qstn_div {
  width: 300px;
  display: flex;
  justify-content: space-between;
}

@media screen and (min-width: 768px) {
  .mobile_name {
    display: none !important;
  }

  .desktop_name {
    display: block;
  }
}

@media screen and (max-width: 767px) {
  .mobile_name {
    display: block;
  }

  .desktop_name {
    display: none;
  }

  .save_btn {
    background-color: #ff6700;
    border: 2px solid white;
    box-shadow: 2px 2px 2px 2px #ff6700;
    border-radius: 16px;
    width: 80px;
    height: 34px;
    font-size: 14px;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1325px) {
  .parent_elm {
    width: 900px;
    height: 590px;
    border: 2px solid #989898;
  }

  .question_conatier {
    padding: 1rem;
  }

  .flex-container {
    width: 900px;
  }

  .practice_qstn_div {
    width: 200px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .parent_elm {
    width: 650px;
    height: auto;
    border: 2px solid #989898;
  }

  .question_conatier {
    padding: 1rem;
  }

  .practice_qstn_div {
    width: 200px;
  }

  .question_class {
    font-size: 18px;
  }

  .flex-container {
    width: 650px;
  }

  .flower_img {
    width: 60px;
    height: 60px;
  }

  .q_no {
    width: 20px;
    height: 20px;
    font-size: 14px;
    border-radius: 5px;
    box-shadow: 3px 3px 3px #00000029;
  }

  .selftest_head {
    width: auto;
    height: 45px;
    font-size: 20px;
    padding: 5px 20px 0px 20px;
  }

  .ans_input {
    width: 65px;
    height: 22px;
    font-size: 14px;
  }
}

/* Responsive Styles */
@media only screen and (min-width: 425px) and (max-width: 767px) {
  .parent_elm {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(1, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    background-color: #f9f6ee;
    border-radius: 20px;
    margin: auto;
    width: 380px;
    height: auto;
    border: 2px solid #989898;
  }

  .question_conatier {
    width: 380px;
    height: 207px;
    padding: 1rem;
    border: none !important;
    border-bottom: 1px solid #e4e0e0 !important;
  }

  .flex-container {
    width: 380px;
  }

  .flower_img {
    width: 60px;
    height: 60px;
  }

  .q_no {
    width: 20px;
    height: 20px;
    font-size: 14px;
    border-radius: 5px;
    box-shadow: 3px 3px 3px #00000029;
  }

  .selftest_head {
    width: auto;
    height: 45px;
    font-size: 20px;
    padding: 5px 20px 0px 20px;
    margin: 2rem 2rem;
  }

  .desktop_name {
    display: none;
  }

  .practice_qstn_div {
    width: 200px;
    display: flex;
    justify-content: space-between;
  }

  .question_class {
    font-size: 16px;
  }

  .ans_input {
    width: 50px;
    height: 22px;
    margin-left: 10px;
    font-size: 14px;
  }
}

@media only screen and (min-width: 375px) and (max-width: 424px) {
  .parent_elm {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(1, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    background-color: #f9f6ee;
    border-radius: 20px;
    margin: auto;
    width: 320px;
    height: auto;
    border: 2px solid #989898;
  }

  .question_conatier {
    width: 320px;
    height: 207px;
    padding: 1rem;
    border: none !important;
    border-bottom: 1px solid #e4e0e0 !important;
  }

  .flex-container {
    width: 320px;
  }

  .flower_img {
    width: 60px;
    height: 60px;
  }

  .q_no {
    width: 20px;
    height: 20px;
    font-size: 14px;
    border-radius: 5px;
    box-shadow: 3px 3px 3px #00000029;
  }

  .selftest_head {
    width: auto;
    height: 45px;
    font-size: 20px;
    padding: 5px 10px 0px 10px;
    margin: 1rem 1rem;
  }

  .practice_qstn_div {
    width: 200px;
    display: flex;
    justify-content: space-between;
  }

  .question_class {
    font-size: 16px;
  }

  .ans_input {
    width: 50px;
    height: 22px;
    margin-left: 10px;
    font-size: 14px;
  }
}

@media only screen and (min-width: 320px) and (max-width: 374px) {
  .parent_elm {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(1, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    background-color: #f9f6ee;
    border-radius: 20px;
    margin: auto;
    width: 280px;
    height: auto;
    border: 2px solid #989898;
  }

  .question_conatier {
    width: 280px;
    height: 207px;
    padding: 1rem;
    border: none !important;
    border-bottom: 1px solid #e4e0e0 !important;
  }

  .flex-container {
    width: 280px;
  }

  .flower_img {
    width: 60px;
    height: 60px;
  }

  .q_no {
    width: 20px;
    height: 20px;
    font-size: 14px;
    border-radius: 5px;
    box-shadow: 3px 3px 3px #00000029;
  }

  .selftest_head {
    width: auto;
    height: 45px;
    font-size: 18px;
    padding: 7px 10px 0px 10px;
    margin: 1rem 0.5rem;
  }

  .practice_qstn_div {
    width: 200px;
    display: flex;
    justify-content: space-between;
  }

  .question_class {
    font-size: 16px;
  }

  .ans_input {
    width: 50px;
    height: 22px;
    margin-left: 10px;
    font-size: 14px;
  }
}

.subQuestionText {
  background-color: #ffe5dd;
  border-radius: 25px;
  display: inline-block;
  padding: 5px 10px 10px;
}
