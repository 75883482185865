.start_test {
  color: #8b60fd;
  text-decoration: none;
  margin-left: 15px;
  cursor: pointer;
}

/* button on off toggle */
.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
}

.switch input {
  display: none;
}

.switch label {
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 20px;
  background-color: #ccc;
  border-radius: 20px;
  cursor: pointer;
}

.switch label:after {
  content: "";
  position: absolute;
  top: 2px;
  left: 2px;
  width: 16px;
  height: 16px;
  background-color: #fff;
  border-radius: 50%;
  transition: all 0.3s;
}

.switch input:checked + label {
  background-color: #4caf50;
}
.sideDiv {
  padding: 0 18px !important;
}
.switch input:checked + label:after {
  transform: translateX(18px);
}

.dropdown_section {
  display: flex;
  flex-direction: row;
  margin: 20px;
  padding: 5px;
}

.pp_table {
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  max-width: 100%;
}
.table_pp {
  /* width: 1030px; */
  width: 100%;
  margin-left: 20px;
  max-width: 100%;
}
.tr_row {
  background-color: #e0e0e0;
  color: #000000;
  height: 60px;
  font-size: 14px;
  text-align: center;
}

.table_head {
  font-size: 14px;
}

.view_btn {
  color: #fff;
  background-color: #0a91e7;
  width: 109px;
  height: 35px;
  text-align: center;
  border: none;
  border-radius: 5px;
}

.view_btn:hover {
  color: #ddd;
}

select {
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  display: flex;
}

select:focus {
  outline: none;
}

.download {
  width: 103px;
  height: 35px;
  color: #e0e0e0;
  background-color: #0a91e7;
  border: none;
  border-radius: 6px;
}
.mode {
  width: 103px;
  height: 35px;
  color: #e0e0e0;
  background-color: #8b60fd;
  border: none;
  border-radius: 6px;
}

.download:hover {
  color: #ccc;
}

.start_test {
  color: #8b60fd;
  text-decoration: none;
  margin-left: 15px;
}

/* Ayushi Css */

/* .table > :not(caption) > * > * {
    padding: 1rem !important;
  } */

.table_right {
  min-width: 970px;
}

.table_left {
  min-width: 280px;
  margin-left: 20px;
}

.Marking {
  width: 330px;
  max-width: 100%;
}

.table_data {
  background-color: white;
  font-size: 14px;
  color: #3e4954;
}

.td_row {
  border-bottom: 2px solid #eeebeb;
  background-color: white;
  height: 50px;
}

.td_row td {
  width: 200px;
}

.pp_table_div_challenge {
  /* overflow-x: scroll; */
  display: flex;
  flex-direction: column;
  /* margin-right: 1rem; */
  /* float: left !important; */
}
.pp_table_chapter {
  display: flex;
  flex-direction: column;
}

/* Ayushi css */

.pp_dropbtn {
  color: black;
  border: none;
  background: white;
  width: 100%;
  margin-top: 20px;
  height: 47px;
  text-align: start;
  border-radius: 10px;
  box-shadow: 0px 14px 40px #0000000f;
  cursor: pointer;
}

.pp_dropdown,
.pp_dropdown_year {
  position: relative;
  display: inline-block;
  width: 251px;
  height: 47px;
}

.pp_dropdown_year {
  position: relative;
  display: inline-block;
  width: 201px;
  height: 47px;
}

.pp_dropdown-content {
  display: block;
  position: absolute;
  background-color: #f9f9f9;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border: none;
  border-radius: 10px;
  height: 196px;
  overflow-y: scroll;
}

.pp_dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  width: 100%;
  text-align: left;
  border-bottom: 1px solid rgb(223, 217, 217);
  cursor: pointer;
}

.pp_dropdown-content a:hover {
  background-color: #f1f1f1;
  color: black;
}

.pp_drp_downs {
  display: flex;
  justify-content: space-between;
}

.self_dropdown {
  display: flex;
}

.table_main_div {
  overflow-x: scroll;
}

.pagination_div_pp {
  width: 970px;
  margin-top: 2rem;
}

/* .css-1oj2twp-MuiPagination-root {
    width: 750px;
  } */

.css-wjh20t-MuiPagination-ul {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0;
  margin: 0;
  list-style: none;
  justify-content: space-around;
}

.pagination_dropdown {
  width: 60px;
  background-color: white;
}

.row_dropbtn {
  background-color: white;
  border: 1px solid #beaeae;
  border-radius: 9px;
  width: 80px;
  height: 45px;
}

.row_dropdown-content {
  display: block;
  position: absolute;
  background-color: #f9f9f9;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border: none;
  border-radius: 0px;
  max-height: 200px;
  overflow-y: scroll;
  border-radius: 8px;
  /* padding: 10px 0px; */
}

.row_dropdown-content a {
  color: black;
  padding: 5px 10px;
  text-decoration: none;
  display: block;
  width: 80px;
  text-align: center;
  /* border-bottom: 1px solid rgb(223, 217, 217); */
  cursor: pointer;
  height: 45px;
}

.row_dropdown-content a:hover {
  background-color: #f1f1f1;
  color: black;
  text-decoration: none;
}

.selected_row {
  background-color: #eef2ff;
}

.selecting_row :hover {
  background-color: #f2f4fa;
}

.row_drop_open {
  height: 260px;
}

.row_dropdown_margin {
  margin-right: 20px;
}

@media only screen and (min-width: 1024px) {
  .self_dropdown {
    display: flex;
    width: 65%;
  }

  .year_div {
    width: 50%;
    display: flex;
    justify-content: end;
  }

  .pastPaper_drpdown {
    font-size: 15px;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1220px) {
  .pagination_div_pp {
    width: 800px;
  }
  .sideDiv {
    padding: 0px 10px 30px 25px !important;
  }
  .pp_table {
    border-collapse: collapse;
    margin: 0px 0px 0px 7px;
    padding: 0;
    width: 100%;
  }

  .pp_drp_downs {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0px 0px 0px 7px;
  }

  .table_marking {
    margin: 0;
  }
}

@media only screen and (min-width: 1220px) and (max-width: 1440px) {
  .pp_table {
    border-collapse: collapse;
    margin: 0px 0px 0px 0px;
    padding: 0;
    width: 100%;
  }
  .sideDiv {
    padding: 0px 10px 30px 25px !important;
  }
  .pp_drp_downs {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0px 0px 0px 7px;
  }

  .table_marking {
    margin: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .pp_dropdown,
  .pp_dropdown_year {
    position: relative;
    display: inline-block;
    width: 200px;
    height: 47px;
  }

  .pp_table {
    border-collapse: collapse;
    margin: 0px 0px 0px 7px;
    padding: 0;
    width: 100%;
  }
  .sideDiv {
    padding: 0px 10px 30px 25px !important;
  }
  .pp_drp_downs {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0px 0px 0px 7px;
  }

  .table_marking {
    margin: 0;
  }

  .yearDropdown {
    margin-right: 15px !important;
  }

  .download {
    width: 80px;
    height: 35px;
    border-radius: 6px;
  }

  .start_test {
    margin-left: 0px;
  }

  .pastPaper_drpdown {
    font-size: 15px;
  }

  .css-1oj2twp-MuiPagination-root {
    max-width: 550px;
  }

  .pagination_div_pp {
    width: 600px;
  }
}

@media screen and (min-width: 375px) and (max-width: 767px) {
  .pp_table td:last-child {
    border-bottom: 0;
  }
  .pp_table_div_challenge {
    overflow-x: scroll;
    display: flex;
    margin-right: 0rem !important;
    margin-left: 0px !important;
    float: left !important;
  }
  .pp_drp_downs {
    display: block;
  }

  .self_dropdown {
    display: block;
  }

  .css-1oj2twp-MuiPagination-root {
    max-width: 280px;
  }

  .css-1to7aaw-MuiButtonBase-root-MuiPaginationItem-root {
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    background-color: transparent;
    outline: 0;
    border: 0;
    margin: 0px 0px !important;
    border-radius: 0;
    padding: 0;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    vertical-align: middle;
    -moz-appearance: none;
    -webkit-appearance: none;
    -webkit-text-decoration: none;
    text-decoration: none;
    color: inherit;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    border-radius: 16px;
    text-align: center;
    box-sizing: border-box;
    min-width: 25px !important;
    height: 25px !important;
    padding: 0 4px;

    color: rgba(0, 0, 0, 0.87);
    -webkit-transition: color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }

  .pp_dropdown_year {
    position: relative;
    display: inline-block;
    width: 251px;
    height: 47px;
  }

  .pagination_div_pp {
    width: 350px;
  }
}

@media screen and (max-width: 374px) {
  .pp_dropdown_year {
    position: relative;
    display: inline-block;
    width: 251px;
    height: 47px;
    right: 10px;
  }

  .pp_table td:last-child {
    border-bottom: 0;
  }

  .pp_drp_downs {
    display: block;
  }
  .pp_table_div_challenge {
    /* overflow-x: scroll; */
    display: flex;
    margin-right: 0rem !important;
    float: left !important;
    margin-left: 0px !important;
  }
  .self_dropdown {
    display: block;
  }

  .css-1oj2twp-MuiPagination-root {
    max-width: 240px;
  }

  .css-1to7aaw-MuiButtonBase-root-MuiPaginationItem-root {
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    background-color: transparent;
    outline: 0;
    border: 0;
    margin: 0px 0px !important;
    border-radius: 0;
    padding: 0;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    vertical-align: middle;
    -moz-appearance: none;
    -webkit-appearance: none;
    -webkit-text-decoration: none;
    text-decoration: none;
    color: inherit;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    border-radius: 16px;
    text-align: center;
    box-sizing: border-box;
    min-width: 25px !important;
    height: 25px !important;
    padding: 0 4px;

    color: rgba(0, 0, 0, 0.87);
    -webkit-transition: color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }

  .pagination_div_pp {
    width: 300px;
  }

  .row_dropbtn {
    width: 55px;
  }
}

/* <---------------------------> */
@media only screen and (min-device-width: 280px) and (max-device-width: 1920px) {
  .slectExam {
    padding: 10px;
  }
  .selectYear {
    padding: 10px;
  }
  .chooseYear {
    padding-left: 10px;
  }
}
@media only screen and (min-device-width: 280px) and (max-device-width: 364px) {
}
