.dropdown-menu.show {
  margin-top: 30px !important;
  margin-left: -30px !important;
  border: 1px solid #b9ddf4;
  box-shadow: 1px 1px 33px 1px #e7e7e7;
  width: 90%;
}

.profile_card {
  z-index: 1;
  background-color: white;
  width: 306px;
  margin-top: 8px;
  z-index: 2;
  background: white;
  background-color: white;
  height: 250px;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #0b92e866;
  opacity: 1;
  margin-left: -40px;
}

.topNav {
  background-color: #8b60fd;
}

.student {
  color: white;
  text-align: start;
}

.container,
.container_open {
  position: relative;
}

.image-card {
  display: flex;
  flex: 1;
  align-items: center;
  /* border: 1px solid #ccc; */
  border-radius: 8px;
  /* padding: 16px; */
  margin: 16px;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
}

.image-container {
  /* flex: 1; */
  /* margin-right: 16px; */
}

.image-container img {
  max-width: 50%;
  height: auto;
  border-radius: 8px;
}

.text-container {
  flex: 2;
}


.container .redDot {
  position: absolute;
  left: 70%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  width: 20px;
  height: 20px;
  font-size: 8px;
  border: none;
  cursor: pointer;
  border-radius: 10px;
  text-align: center;
  background-color: red;
  color: white;
}

/* .container_open .redDot_open {
  position: absolute;
  top: 38%;
  left: 72%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  width: auto;
  font-size: 8px;
  padding: 3px 5px;
  border: none;
  cursor: pointer;
  border-radius: 50%;
  text-align: center;
  background-color: red;
  color: white;
} */

.notification_link {
  width: 72px;
}

.close_btn_notification {
  margin-left: 80%;
  position: absolute;
  top: 0%;
  right: 0%;
  z-index: 999;
}

.close_btn {
  margin-left: 80%;

}

.profile {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  border: 3px solid white;
}

.nav-link {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: white !important;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}

.profileDiv {
  margin-left: 300px;
}

.profileDropDown {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  border: 3px solid #0b92e8;

  margin-top: -33px;
}

.drp_down {
  max-width: 250px;
  width: 100%;
  text-align: center;
}

.drp_profile_text {
  width: 250px;
  text-align: center;
}

.user_Dialog {
  width: 288px;
}

.forWardArrow {
  border-radius: 50%;
  border: none;
  background-color: #b2b1ff;
  color: #6563ff;
  height: 30px;
  width: 30px;
  margin-left: 75%;
  margin-top: -10%;
}

.signOut_btn {
  border: none;
  background-color: #ff6700;
  color: white;
  padding-left: 2px;
  padding-right: 2px;
  border-radius: 15px;
  max-width: 100px;
  width: 100%;
  height: 30px;
  font-size: 12px;
  font-weight: 600;
  margin-left: 10px;
  border: 1px solid white;
  box-shadow: 2px 2px 2px 2px #ff6700;
}

.nav_div {
  max-height: 60px;
}

.logOutHeader {
  color: #ff6700;
  font-weight: 500;
  font-size: 35px;
  text-align: center;
}

/* .logout_Modal {
  height: 300px;
  width: 600px;
  border-radius: 50px;
  padding: 20px;
  border: 5px solid black;
} */

/* .modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: 50px !important;
  outline: 0;
  border-radius: 80px !important;
} */

.downArrow {
  color: white;
  margin-top: 10px;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.profile_link {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

/* .modal {
  --bs-modal-zindex: 1055;
  --bs-modal-width: 709px !important;
  --bs-modal-padding: 1rem;
  --bs-modal-margin: 0.5rem;
  --bs-modal-color: ;
  --bs-modal-bg: #fff;
  --bs-modal-border-color: var(--bs-border-color-translucent);
  --bs-modal-border-width: 1px;
  --bs-modal-border-radius: 0.5rem;
  --bs-modal-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-modal-inner-border-radius: calc(0.5rem - 1px);
  --bs-modal-header-padding-x: 1rem;
  --bs-modal-header-padding-y: 1rem;
  --bs-modal-header-padding: 1rem 1rem;
  --bs-modal-header-border-color: var(--bs-border-color);
  --bs-modal-header-border-width: 1px;
  --bs-modal-title-line-height: 1.5;
  --bs-modal-footer-gap: 0.5rem;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: var(--bs-border-color);
  --bs-modal-footer-border-width: 1px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-modal-zindex);
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
} */

.reward_div {
  margin-left: 1rem;
  margin-right: 1rem;
}

.container_div {
  background: #fef9ef;
  display: flex;
}

/* card body css starts here  */
.card-body {
  flex: 1 1 auto;
  padding: 0px !important;
  color: var(--bs-card-color);
}

.profilecard_top_div {
  padding: 15px 20px 0px 20px;
}

.profilecard_bottom_div {
  padding: 0px 20px 10px 20px;
}

/* notification dropdown starts here */

.icon_dorp_down {
  width: 294px;
  height: 236px;
  background: #fef9ef;
  border: 1px solid #0b92e866;
  border-radius: 6px;
  margin-top: 11px;
  position: relative;
  z-index: 100;
  margin-left: -120px;
}

.icon_dorp_down_mobile {
  width: 294px;
  height: 236px;
  background: #fef9ef;
  border: 1px solid #0b92e866;
  border-radius: 6px;
  margin-top: 11px;
  position: relative;
  z-index: 100;
}

.notification_drop_div {
  /* min-height: 93px; */
  /* width: 100%; */
  text-align: start;
  padding: 10px;
  font-size: 14px;
  color: black;
}

.notification_drop_read_div {
  /* min-height: 93px; */
  /* width: 100%; */
  background-color: #0b92e833;
  text-align: start;
  padding: 10px;
  font-size: 14px;
  color: black;
}

.view_all_noti {
  font-size: 14px;
  color: #8b60fd;
  margin-right: 15px;
  margin-top: 20px;
  position: absolute;
  bottom: 15px;
}

.dropDown_content {
  /* margin-top: -44px; */
  height: 186px;
overflow-y: scroll;
}

.topnav_profile_img {
  width: 50px;
  /* Adjust the width as needed */
  height: auto;
  /* Maintain aspect ratio */
  margin-right: 10px;
  /* Add some spacing between image and text */
}

.no_note {
  font-size: 18px;
  color: black;
}

@media only screen and (min-device-width: 1024px) {
  .card_show {
    display: none;
  }

  .card_desktop {
    display: block;
  }
}

@media only screen and (max-device-width: 1023px) {
  .card_show {
    display: block;
  }

  .card_desktop {
    display: none;
  }

  .profile_card2 {
    z-index: 1;
    background-color: white;
    width: 306px;
    z-index: 2;
    background: white;
    background-color: white;
    height: 250px;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #0b92e866;
    opacity: 1;
    margin-left: -40px;
    margin-bottom: -252px;
    margin-left: 60%;
  }
}

@media only screen and (min-device-width: 768px) {

  .menuIcon,
  .topNav_toggle {
    display: none;
  }

  .profile_card {
    margin-bottom: -200px;
    margin-left: -40px;
    width: 306px;
    z-index: 200;
    background-color: white;
    height: 250px;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #0b92e866;
    opacity: 1;
    margin-left: -40px;
  }
}

@media only screen and (min-device-width: 1024px) {
  .icon_dorp_down_mobile {
    display: none;
  }

  .icon_dorp_down {
    display: block;
  }

  .profile_nav_link {
    width: 270px;
  }
}

@media only screen and (max-device-width: 1023px) and (min-device-width: 768px) {
  .icon_dorp_down_mobile {
    display: block;
    margin-left: 29%;
    margin-bottom: -236px;
    position: relative;
    margin-top: 0px;
  }

  .icon_dorp_down {
    display: none;
  }
}

@media only screen and (max-device-width: 767px) and (min-device-width: 425px) {
  .icon_dorp_down_mobile {
    display: block;
    margin-left: 17%;
    margin-bottom: -236px;
    position: relative;
    margin-top: 0px;
  }

  .icon_dorp_down {
    display: none;
  }
}

@media only screen and (max-device-width: 424px) and (min-device-width: 375px) {
  .icon_dorp_down_mobile {
    display: block;
    margin-left: 17%;
    margin-bottom: -236px;
    margin-top: 0px;
    position: relative;
  }

  .icon_dorp_down {
    display: none;
  }
}

@media only screen and (max-device-width: 374px) and (min-device-width: 200px) {
  .icon_dorp_down_mobile {
    display: block;
    margin-left: 5%;
    margin-bottom: -236px;
    margin-top: 0px;
    position: relative;
  }

  .icon_dorp_down {
    display: none;
  }
}

@media only screen and (min-device-width: 200px) and (max-device-width: 767px) {
  .dropDown_main {
    display: none;
  }

  .drp_down {
    display: none;
  }

  .user_Dialog {
    width: 120px;
  }

  .nav_div {
    max-height: 40px;
  }

  .profile_card {
    margin-bottom: -200px;
    margin-left: -40px;
    width: 306px;
    z-index: 200;
    background-color: white;
    height: 250px;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #0b92e866;
    opacity: 1;
    margin-left: -40px;
  }

  .container_div {
    display: block;
  }

  .menuIcon {
    display: block;
    margin-bottom: -60px;
  }

  .topNav_toggle {
    display: block;
  }

  .downArrow {
    color: white;
    margin-top: 10px;
    margin-left: 0.2rem;
    margin-right: 0.2rem;
  }

  /* .notification_link {
    width: 57px;
  } */
}

@media only screen and (min-device-width: 601px) and (max-device-width: 767px) {
  .profile_card2 {
    z-index: 1;
    background-color: white;
    width: 306px;
    margin-top: 1px;
    z-index: 2;
    background: white;
    background-color: white;
    height: 250px;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #0b92e866;
    opacity: 1;
    margin-bottom: -248px;
    margin-left: 50%;
  }
}

@media only screen and (min-device-width: 200px) and (max-device-width: 400px) {
  .reward_div {
    margin-left: 0.2rem;
    margin-right: 0.2rem;
  }

  .profile_link {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }

  .user_Dialog {
    width: 90px !important;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 374px) {
  .profile_card2 {
    z-index: 20;
    background-color: white;
    width: 306px;
    margin-top: 1px;
    z-index: 2;
    background: white;
    background-color: white;
    height: 250px;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #0b92e866;
    opacity: 1;
    margin-bottom: -253px;
    margin-left: 4%;
  }
}

@media only screen and (min-device-width: 375px) and (max-device-width: 424px) {
  .profile_card2 {
    z-index: 20;
    background-color: white;
    width: 306px;
    margin-top: 1px;
    z-index: 2;
    background: white;
    background-color: white;
    height: 250px;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #0b92e866;
    opacity: 1;
    margin-bottom: -253px;
    margin-left: 15%;
  }
}

@media only screen and (min-device-width: 424px) and (max-device-width: 600px) {
  .profile_card2 {
    z-index: 20;
    background-color: white;
    width: 306px;
    margin-top: 1px;
    z-index: 2;
    background: white;
    background-color: white;
    height: 250px;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #0b92e866;
    opacity: 1;
    margin-bottom: -253px;
    margin-left: 25%;
  }
}

/* 
will put top nav and side nav together, place an icon at top nav, display it only in smaller screens, and when user click on it, we will show the sidebar */