.bg-mock {
  background-color: #fff !important;
}

.mock_box {
  top: 213px;
  left: 132px;
  width: 220px;
  background: #fef9ef 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 11px;
  display: flex;
  flex-direction: column;
}

.start-mock-test {
  background: #ff782c 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 0px 0px 11px 11px;
  opacity: 1;
  padding: 10px 0;
  cursor: pointer;
}
.mock-container .mock_grid {
  grid-template-columns: repeat(4, 1fr);
  display: grid;
  /* grid-template-columns: repeat(3, 1fr); */
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 54px;
  grid-row-gap: 30px;
}

/* @media screen and (max-width: 768px) {
  .mock-container .mock_grid {
    grid-template-columns: repeat(2, 1fr);
  }
  .mock_box {
    width: 170px;
  }
} */
@media only screen and (min-width: 1440px) {
  .mock-container .mock_grid {
    grid-template-columns: repeat(5, 1fr);
  }
}

@media only screen and (min-width: 901px) and (max-width: 1200px) {
  .mock-container .mock_grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media only screen and (min-width: 769px) and (max-width: 900px) {
  .mock-container .mock_grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .chapter_contaier_div {
    display: flex;
    justify-content: center;
  }
}

@media only screen and (min-width: 650px) and (max-width: 768px) {
  .mock-container .mock_grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (min-width: 280px) and (max-width: 649px) {
  .mock-container .mock_grid {
    grid-template-columns: repeat(1, 1fr);
  }
}
