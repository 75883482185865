.sub_name {
  margin-left: 20px;
  font-size: 20px;
  color: #918e96;
  cursor: pointer;
}
.lesson_name {
  font-size: 20px;
  color: #8b60fd;
}

.lesson_row {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 6rem;
  grid-row-gap: 5rem;
}
.heart_img{
  cursor: pointer;
}
.lession_li {
  margin-left: 20px;
  display: flex;
  width: 60%;
  justify-content: space-between;
}

.tab {
  display: flex;
  cursor: pointer;
  margin-left: 1.5rem !important;
}

.lesson_tab {
  font-size: 18px;
  color: #918e96;
  font-weight: 500;
}
.lesson_tab_active {
  font-size: 18px;
  color: #8b60fd;
  font-weight: 500;
}

.lesson_icon {
  width: 21px;
  height: 21px;
  margin-top: 4px;
}

.purple_line {
  border-bottom: 2px solid #8b60fd;
  display: flex;
  margin-left: 1.5rem !important;
}

.play_img {
  position: absolute;
  margin-left: 20px;
  margin-top: 220px;
}

.lesson_box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  padding: 12px 18px;
  border: none;
  cursor: pointer;
  text-align: center;
  width: 100px;
  height: 80px;
  background-color: #fff;
  font-size: 20px;
  border-radius: 10px;
}

.lesson_img {
  width: 60px;
  height: 60px;
}

.controls {
  margin-top: 49%;
}

.lesson_contaier_div {
  display: flex;
  justify-content: center;
}

/* design change css */

.lesson_container {
  padding: 14px;
  width: 250px;
  height: 201px;
  background-color: #b2e9ff;
  border-radius: 10px;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
  position: relative;
}

.lesson_num {
  text-align: left;
  font: normal normal normal 20px/28px Comic Sans MS;
  letter-spacing: 0px;
  color: #000000;
  font-size: 16px;
  padding: 5px;
  opacity: 1;
  font-weight: 550;
}

@media only screen and (min-width: 768px) {
  .desktop_lesson_name {
    display: block;
  }
  .mobile_lesson_name {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .desktop_lesson_name {
    display: none;
  }

  .mobile_lesson_name {
    display: block;
  }
  .sub_name {
    margin-left: 10px;
    font-size: 14px;
  }
  .lesson_name {
    font-size: 14px;
  }
  .lesson_arrow {
    font-size: 14px;
  }
}

@media only screen and (min-width: 1220px) {
  .lesson_row {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 4rem;
    grid-row-gap: 3rem;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1219px) {
  .lesson_row {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(6, 1fr);
    grid-column-gap: 3rem;
    grid-row-gap: 1rem;
  }
  .lesson_contaier_div {
    justify-content: center;
    height: 650px;
}
  .lesson_container {
    margin-left: 0rem;
  }
  .lession_li {
    margin-left: 20px;
    display: flex;
    width: 70%;
    justify-content: space-between;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .lesson_row {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-column-gap: 2rem;
    grid-row-gap: 3rem;
  }
  .lesson_contaier_div {
    justify-content: center;
  }
  .lesson_container {
    margin-left: 0rem;
  }
  .lession_li {
    margin-left: 20px;
    display: flex;
    width: 95%;
    justify-content: space-between;
  }
}

@media only screen and (min-width: 326px) and (max-width: 550px) {
  .lesson_row {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(6, 1fr);
  }
  .lession_li {
    margin-left: 20px;
    display: block;
    width: 100%;
  }
  .purple_line {
    border: none;
    display: flex;
    margin-left: 1.5rem !important;
  }
}

@media only screen and (min-width: 550px) and (max-width: 767px) {
  .lesson_row {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(6, 1fr);
    grid-column-gap: 0.75rem;
    grid-row-gap: 1rem;
  }
  .lesson_container {
    height: 180px;
    width: 200px;
    border-radius: 30px;
  }
  .lesson_box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    padding: 5px 0px;
    border: none;
    cursor: pointer;
    text-align: center;
    width: 70px;
    height: 60px;
    background-color: #fff;
    font-size: 20px;
    border-radius: 10px;
    margin-top: 0px;
  }
  .lesson_num {
    font-size: 18px;
    text-align: start;
  }
  .purple_line {
    border: none;
    display: flex;
    margin-left: 1.5rem !important;
  }
}

@media only screen and (max-width: 325px) {
  .lesson_row {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(6, 1fr);
  }
  .lesson_container {
    height: 200px;
    width: 250px;
    border-radius: 10px;
  }
  .lession_li {
    margin-left: 20px;
    display: block;
  }
  .lesson_box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    padding: 5px 15px;
    border: none;
    cursor: pointer;
    text-align: center;
    width: 80px;
    height: 70px;
    background-color: #fff;
    font-size: 20px;
    border-radius: 10px;
    margin-top: 0px;
  }
  .lesson_num {
    font-size: 18px;
    text-align: start;
  }
  .purple_line {
    border: none;
    display: flex;
    margin-left: 1.5rem !important;
  }
}



@media only screen and (min-width: 768px) and (max-width: 1920px) {


.lesson_icon {
  width: 18px;
  height: 18px;
}}

@media only screen and (min-width: 320px) and (max-width: 760px) {
  .lesson_row {
  
    grid-row-gap: 2rem;
  }

.lession_li {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  margin-left: 0;
  padding-left: 0;
}
.tab{
  margin-left: 0 !important;
}
.purple_line {
  border: none;
  display: flex;
  margin-left:0 !important;
}
}