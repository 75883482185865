.react-pdf__Page__canvas {
  margin: 0 auto;
  width: 100% !important;
  height: 60% !important;
}
@media only screen and (min-device-width: 1024px) and (max-device-width: 1200px) {
  .react-pdf__Page__canvas {
    margin: 0 auto;
    width: 100% !important;
    height: 60% !important;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1023px) {
  .react-pdf__Page__canvas {
    margin: 0 auto;
    width: 98% !important;
    height: 60% !important;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 374px) {
  .react-pdf__Page__canvas {
    margin: 0 auto;
    width: 100% !important;
    height: 60% !important;
  }
}
@media only screen and (min-device-width: 375px) and (max-device-width: 424px) {
  .react-pdf__Page__canvas {
    margin: 0 auto;
    width: 100% !important;
    height: 60% !important;
  }
}
@media only screen and (min-device-width: 425px) and (max-device-width: 767px) {
  .react-pdf__Page__canvas {
    margin: 0 auto;
    width: 98% !important;
    height: 60% !important;
  }
}
@media only screen and (min-device-width: 320px) and (max-device-width: 767px) {
  .react-pdf__Page__canvas {
    margin: 0 auto;
    width: 98% !important;
    height: 60% !important;
  }
}
