.video-react .video-react-big-play-button {
  font-size: 10em;
  line-height: 1.5em;
  height: 135px;
  width: 135px;
  display: block;
  position: absolute;
  top: 38%;
  left: 43%;
  padding: 0;
  cursor: pointer;
  opacity: 1;
  border: none;
  background-color: #c6e4ce;
  background-color: #c6e4ce;
  border-radius: 50%;
  transition: all 0.4s;
}

/* Media query for screens with a maximum width of 425px */

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .video-react .video-react-big-play-button {
    font-size: 7em;
    line-height: 1.5em;
    height: 100px;
    width: 100px;
    display: block;
    position: absolute;
    top: 38%;
    left: 43%;
    padding: 0;
    cursor: pointer;
    opacity: 1;
    border: none;
    background-color: #c6e4ce;
    background-color: #c6e4ce;
    border-radius: 50%;
    transition: all 0.4s;
  }
}

@media only screen and (min-width: 284px) and (max-width: 767px) {
  .video-react .video-react-big-play-button {
    font-size: 4em;
    line-height: 1.5em;
    height: 60px;
    width: 60px;
    display: block;
    position: absolute;
    top: 38%;
    left: 43%;
    padding: 0;
    cursor: pointer;
    opacity: 1;
    border: none;
    background-color: #c6e4ce;
    background-color: #c6e4ce;
    border-radius: 50%;
    transition: all 0.4s;
  }
}
