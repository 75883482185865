/* App.css or your custom CSS file */

/* Center the modal vertically and horizontally */
.modal {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

/* Set a max-width for the modal dialog */
.pdf-modal .modal-dialog {
  max-width: 100% !important;
}
.Pre_btn {
  background-color: #8b60fd;
  color: white;
  border-radius: 8px;
  width: 100px;
  cursor: pointer;
  border: none;
}
.close_btn_notification {
  position: absolute;
  top: 2%;
  right: 5%;
  z-index: 999;
}

.pdfModal {
  min-height: 50vh !important;
  height: 100% !important;
  background-color: #fef9ef;
  color: #666666;
  font-size: 20px;
  text-align: justify;
  text-justify: inter-word;
  border-radius: 0px !important;
}

/* Ensure the PDF content inside the modal is responsive */
.pdf-modal .modal-body {
  max-height: 70vh;
  overflow-y: auto;
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1023px) {
  .pdfModal {
    min-height: 50vh !important;
    height: 100% !important;
    background-color: #fef9ef;
    color: #666666;
    font-size: 20px;
    text-align: justify;
    text-justify: inter-word;
    border-radius: 0px !important;
  }
}

@media only screen and (min-device-width: 200px) and (max-device-width: 767px) {
  .pdfModal {
    min-height: 45vh !important;
    height: 100% !important;
    background-color: #fef9ef;
    color: #666666;
    font-size: 20px;
    text-align: justify;
    text-justify: inter-word;
    border-radius: 0px !important;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1920px) {
  .pdf-modal {
    width: 600px;
  }
}
