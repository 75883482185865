.top_math {
  margin-bottom: 20px;
  font-size: 14px;
  text-align: start;
  margin-top: -27px;
}

.sa_speechMenu {
  /* margin-left: 3rem; */
  color: #e97838;
}

.sa_marks {
  color: #989898;
  font-size: 17px;
  margin-right: 3rem;
  margin-top: 10px;
}

.sa_question_div {
  width: 70%;
  margin-left: 4rem;
}

@media only screen and (min-width: 768px) and (max-width: 1220px) {
  .active_qtn_num {
    margin-left: 0.25rem;
  }

  .qtn_num {
    margin-left: 0.25rem;
  }
  .btn_div_selftest {
    width: 70%;
  }
  .speechMenu {
    margin-left: 0rem;
  }
}
@media only screen and (min-width: 768px) {
  .mobileButton {
    width: 50%;
  }
  .next_btn_self {
    margin-right: 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .sa_question_div {
    width: 70%;
    margin-left: 2rem;
  }
  .sa_marks {
    color: #989898;
    font-size: 14px;
    margin-right: 0.5rem;
    margin-top: 5px;
  }
  .btn_div_selftest {
    width: 70%;
    align-items: center !important;
  }
  .self_Test {
    padding-top: 50px !important;
    padding-left: 10px !important;
  }
}

@media only screen and (min-width: 284px) and (max-width: 549px) {
  .speechMenu {
    margin-left: 0.5rem;
  }
  .top_math {
    margin-bottom: 20px;
    font-size: 14px;
    text-align: start;
    margin-top: -5px;
  }
  .sa_question_div {
    width: 68%;
    margin-left: 1rem;
  }
  .sa_marks {
    color: #989898;
    font-size: 10px;
    margin-right: 5px;
    margin-top: 5px;
  }
  .fBPRrU {
    background-color: rgb(255, 103, 0);
    border: 2px solid white;
    box-shadow: rgb(255, 103, 0) 2px 2px 2px 2px;
    border-radius: 20px;
    max-width: 77px !important;
    width: 85px;
    font-size: 20px;
    color: white;
    height: 35px;
    font-weight: 550;
  }
  .egfEjE {
    background-color: rgb(145, 142, 150);
    border: 2px solid white;
    box-shadow: rgb(145, 142, 150) 2px 2px 2px 2px;
    border-radius: 20px;
    max-width: 77px !important;
    width: 85px;
    font-size: 20px;
    color: white;
    height: 35px;
    font-weight: 550;
  }
  .jHHnTu {
    background-color: rgb(255, 103, 0);
    border: 2px solid white;
    box-shadow: rgb(255, 103, 0) 2px 2px 2px 2px;
    border-radius: 20px;
    max-width: 85px !important;
    width: 85px;
    color: white;
    height: 38px;
  }
  .correct_mob {
    position: absolute;
    left: 110px;
    bottom: 0px;
  }
}

/* @media only screen and (min-width) {
  .qstn_bg{
    min-height: 90vh;
    margin-top: 1rem;
    padding: 1rem;
    position: relative;
  }
} */
