.topDiv {
  display: flex;
  min-height: 100vh;
  height: 100%;
  z-index: 1;
  top: 0px;
  left: 0px;
}
.css-1wvake5 {
  position: relative;
  border-right-width: 1px;
  border-right-style: solid;
  border-color: #efefef;
  -webkit-transition: width, left, right, 300ms;
  transition: width, left, right, 300ms;
  width: 80px !important;
  min-width: 80px !important;
  top: 0px;
}

.course_hover :hover {
  content: "Course";
}

.nav_icons {
  width: 31px;
  height: 24px;
}

.css-ewdv3l {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 92vh;
}

.css-16jkw2k > .ps-menu-button {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 57px !important;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  box-sizing: border-box;
  cursor: pointer;
  padding-right: 0px !important;
  padding-left: 0px !important;
}
.css-1t8x7v1 > .ps-menu-button {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 57px !important;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  box-sizing: border-box;
  cursor: pointer;
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.tab_name {
  font-size: 14px;
}

.css-dip3t8 {
  position: relative;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 3;
  background-color: white !important;
}

@media only screen and (min-device-width: 1023px) {
  .open_logo {
    display: none;
  }
}

@media only screen and(min-device-width: 768px) and (max-device-width: 1023px) {
  .open_logo {
    display: block;
  }
}
