.selfTest_bg {
  min-height: 100vh;
  height: auto;
  width: 100%;
  background-color: #ffe5dd;
  border: none;
  padding: 2rem;
}
.active_qtn_num {
  color: white;
  border: none;
  border-radius: 50%;
  background-color: #8b60fd;
  font-size: 20px;
  font-weight: 500;
  width: 41px;
  height: 41px;
  padding-top: 4px;
  cursor: pointer;
  margin-left: 1.5rem;
}

.qtn_num {
  color: #8b60fd;
  border: 3px solid #8b60fd;
  border-radius: 50%;
  font-size: 20px;
  font-weight: 500;
  width: 41px;
  height: 41px;
  padding-top: 4px;
  cursor: pointer;
  margin-left: 1.5rem;
}

.qstn_bg {
  width: 100%;
  height: 90%;
  background-color: white;
  margin-top: 2rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.question_1 {
  width: 10%;
  font-size: 25px;
}

.question {
  text-align: left;
  font-size: 25px;
}

.question_div {
  width: 80%;
  margin-left: 4rem;
}
.sound_qstn {
  cursor: pointer;
}

.selected_optn {
  background-color: #daccff !important;
}

.highlight_question {
  background-color: #ffe5dd;
  border: none;
  border-radius: 30px;
  height: auto;
  padding: 10px;
}

.optn {
  background-color: white;
  border: 1px solid #b4ad9f;
  border-radius: 28px;
  padding: 5px 20px;
  width: 337px;
  min-height: 55px;
  text-align: start;
  font-size: 25px;
  box-shadow: 0px 12px 19px #225a6f1a;
  margin: 0rem 1rem;
  cursor: pointer;
}

.next_btn {
  margin-top: 0.5rem;
}
.two_option {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}

.correct {
  font-size: 30px;
  color: #72bf4a;
  font-weight: 700;
  display: flex;
}

.right {
  font-size: 30px;
  color: #72bf4a;
  font-weight: 700;
  display: flex;
}

.logOutHeader_self {
  color: #ff6700;
  font-weight: 500;
  font-size: 35px;
  text-align: center;
}

.options {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 1rem;
  grid-row-gap: 2rem;
}

.chptr_name_selftest {
  font-size: 12px;
  color: #111111;
}
.speechMenu {
  margin-left: 3rem;
}

.correct_text {
  margin-top: 3rem;
}
.right_mob {
  display: none;
}

.correct_mob {
  display: none;
}

.correct_top_main_div {
  margin-top: 3rem;
}

/* Media query for screens with a maximum width of 425px */

@media only screen and (min-width: 768px) and (max-width: 1220px) {
  .active_qtn_num {
    margin-left: 0.25rem;
  }

  .qtn_num {
    margin-left: 0.25rem;
  }
  .speechMenu {
    margin-left: 0rem;
  }
}

@media only screen and (min-width: 1024px) {
  .qstn_bg {
    min-height: 600px;
    margin-top: 1rem;
    padding: 1rem;
    position: relative;
  }
  .btn_div_selftest {
    /* position: absolute; */
    bottom: 0;
  }
  .question_div_mobile {
    margin-left: 60px;
  }
  .num_pagination {
    display: flex;
  }
  .qstn_sound {
    display: flex;
  }
  .yes_no_div {
    margin-top: 5rem;
  }
  .carton_img {
    position: relative;
    top: -170px;
    left: 40%;
  }

  .next_btn_self {
    margin-bottom: 60px;
    margin-right: 100px;
  }
  .bck_btn_self {
    margin-bottom: 60px;
    margin-right: 20px;
  }

  .correct {
    margin-left: 12rem;
  }
  .right {
    margin-left: 19rem;
  }
}
@media only screen and (min-width: 1224px) {
  .qstn_bg {
    min-height: 78vh;
    margin-top: 1rem;
    padding: 1rem;
    position: relative;
  }
  .btn_div_selftest {
    /* position: absolute; */
    bottom: 0;
  }
  .question_div_mobile {
    margin-left: 10px !important;
  }
  .num_pagination {
    display: flex;
  }
  .qstn_sound {
    display: flex;
  }
  .yes_no_div {
    margin-top: 5rem;
  }
  .carton_img {
    position: relative;
    top: -150px;
    left: 40%;
  }

  .next_btn_self {
    margin-bottom: 60px;
    margin-right: 100px;
  }
  .bck_btn_self {
    margin-bottom: 60px;
    margin-right: 20px;
  }

  .correct {
    margin-left: 12rem;
  }
  .right {
    margin-left: 19rem;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .qstn_bg {
    min-height: 600px;
    margin-top: 1rem;
    padding: 1rem;
    position: relative;
  }
  .question_div_mobile {
    margin-left: 10px !important;
  }
  .optn {
    border-radius: 25px;
    padding: 5px 15px;
    width: 280px;
    min-height: 55px;
    margin: 0rem;
  }
  .num_pagination {
    display: flex;
  }
  #NumPage {
    overflow: hidden !important;
  }
  .qstn_sound {
    display: block;
  }
  .yes_no_div {
    margin-top: 5rem;
  }
  /* .carton_img {
    position: relative;
    top: -119px;
    left: 35%;
  } */
  .btn_div_selftest {
    /* position: absolute; */
    bottom: 0;
    /* margin-left: -55px; */
  }
  .solution_mob {
    margin-left: 70px;
  }
  .correct img {
    height: 80px;
    width: 80px;
  }
  .correct {
    font-size: 20px;
    color: #72bf4a;
    font-weight: 700;
    display: flex;
    padding-right: 50px;
  }
  .question_1 {
    width: 15%;
    font-size: 25px;
  }

  .question {
    text-align: left;
    font-size: 25px;
  }

  .question_div {
    width: 70%;
    margin-left: 2rem;
  }
  .question_div_mobile {
    width: 80%;
    margin-left: 2rem;
    margin-left: 60px;
  }
  .options {
    grid-column-gap: 0.5rem;
    grid-row-gap: 1rem;
  }
  .bck_btn_self {
    margin-bottom: 60px;
    margin-right: 20px;
  }
  .next_btn_self {
    margin-bottom: 60px;
    margin-right: 0px;
  }
  .self_Button {
    padding-top: 50px !important;
    padding-left: 10px !important;
  }
}

@media only screen and (min-width: 550px) and (max-width: 767px) {
  .qstn_bg {
    margin-top: 2rem;
    padding: 1rem;
    min-height: 600px;
  }
  .optn {
    border-radius: 25px;
    padding: 5px 15px;
    width: 220px;
    min-height: 55px;
    margin: 0rem;
  }
  .selfTest_bg {
    height: auto;
    width: 100vw;
    padding: 3rem;
  }
  .num_pagination {
    display: flex;
    overflow-x: scroll;
  }
  .qstn_sound {
    display: block;
  }
  .yes_no_div {
    margin-top: 5rem;
  }
  .carton_img {
    position: relative;
    top: -150px;
    left: 40%;
  }
}

@media only screen and (min-width: 284px) and (max-width: 549px) {
  .two_option {
    display: block;
    justify-content: center;
    margin-top: 0.5rem;
  }
  .optn {
    border-radius: 20px;
    padding: 10px 20px;
    width: 250px;
    min-height: 50px;
    margin: 0rem;
    margin-top: 10px;
    font-size: 18px;
  }
  .selfTest_bg {
    height: auto;
    width: 100vw;
    padding: 1rem;
  }
  .num_pagination {
    display: flex;
    overflow-x: scroll;
  }
  .qstn_sound {
    display: block;
  }
  .qstn_bg {
    min-height: 500px;
    margin-top: 1rem;
    padding: 0.5rem 1rem;
    position: relative;
  }

  .correct_text {
    display: block;
    margin-top: 0.5rem;
    color: #72bf4a;
    font-weight: 700;
    font-size: 18px;
  }
  .correct_mob img {
    height: 42px;
    width: 42px;
  }

  .right_mob img {
    height: 60px;
    width: 75px;
  }
  .yes_no_div {
    margin-top: 40px;
  }
  /* .carton_img {
    position: relative;
    top: -130px;
    left: 14%;
  } */
  .options {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 0rem;
    grid-row-gap: 0rem;
  }
  .correct {
    display: none;
  }
  .question_1 {
    width: 15%;
    font-size: 18px;
  }

  .question {
    text-align: left;
    font-size: 18px;
  }

  .question_div {
    width: 70%;
    margin-left: 1rem;
  }

  .speechMenu {
    margin-left: 0.5rem;
  }
  .btn_div_selftest {
    /* position: absolute; */
    bottom: -30px;
    margin-left: -23px;
  }
  .next_btn_self {
    margin-bottom: 60px;
    margin-right: 3px;
  }
  .bck_btn_self {
    margin-bottom: 60px;
    margin-right: 3px;
  }
  .solution_mob {
    margin-left: 12px;
  }
  .logOutHeader_self {
    font-weight: 500;
    /* font-size: 35px; */
    width: 100%;
  }

  .right_mob {
    display: block;
    margin-top: 2rem;
  }
  .correct_mob {
    display: block;
    margin-top: 2rem;
  }
  .right {
    display: none;
  }
}

/* modal css */

@media only screen and (min-device-width: 1024px) and (max-device-width: 1920px) {
  .btn_div_selftest {
    bottom: 20px !important;
    align-items: center;
  }
  .self_Test {
    padding-top: 50px !important;
    padding-left: 10px !important;
  }
  .inputAnsPast {
    margin-left: 30px !important;
  }
  .self_Button {
    padding-top: 50px !important;
    padding-left: 10px !important;
  }
  /*    
    .self_correct{
      padding-right: 100px;
    } */
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1023px) {
  .inputAnsPast {
    margin-left: 80px !important;
    max-width: 400px;
  }
}
@media only screen and (min-device-width: 1440px) and (max-device-width: 1920px) {
  .inputAnsPast {
    margin-left: 25px !important;
  }
  .self_incorrect {
    padding-right: 280px;
  }
}
@media only screen and (min-device-width: 1024px) and (max-device-width: 1439px) {
  .inputAnsPast {
    margin-left: 50px !important;
  }
  .self_incorrect {
    padding-right: 100px;
  }
}
.inputAnsPast {
  max-width: 600px !important;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1023px) {
  .right {
    padding-right: 100px;
  }
}
@media only screen and (min-device-width: 1024px) and (max-device-width: 1439px) {
  .right {
    padding-right: 150px;
  }
}
@media only screen and (min-device-width: 1440px) and (max-device-width: 1920px) {
  .right {
    padding-right: 28rem;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 767px) {
  .successModal {
    height: 310px !important;
    width: 300px;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }
  .logOutHeader_self {
    font-size: 25px;
  }
  .modal-body {
    /* width: 310px; */
    padding: 20px 20px !important;
  }
  .carton_img {
    position: relative;
    top: -130px;
    left: 50px;
    /* right:35px; */
  }
  .close_btn_self {
    justify-content: end;
    padding-right: 15px;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1023px) {
  /* .modal-body{
  
  padding: 20px 20px!important;
        } */
  .close_btn_self {
    justify-content: end;
    position: relative;
    left: 75px;
  }
  .carton_img {
    position: relative;
    top: -145px;
    left: 25%;
  }
  .successModal {
    height: auto !important;
    width: auto;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }
}

@media only screen and (min-device-width: 1024px) and (max-device-width: 1920px) {
  /* .modal-body{

padding: 20px 20px!important;
      } */
  .close_btn_self {
    justify-content: end;
  }
  .carton_img {
    position: relative;
    top: -160px;
    left: 40%;
  }
  .successModal {
    height: auto !important;
    width: auto;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }
}
@media only screen and (min-device-width: 1536px) and (max-device-width: 1920px) {
  .close_btn_self {
    justify-content: end;
    position: relative;
    left: 60px;
  }
}


.custom-checkbox {
  width: 20px;
  height: 20px;
}