.activitySettingModal .modal-dialog {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 14px;
  width: 800px;
  max-width: 800px;
}
.activitySettingModal .modal-header {
  background: #8b60fd 0% 0% no-repeat padding-box;
  border-radius: 14px 14px 0px 0px;
  display: flex;
  justify-content: space-between;
  color: #ffffff;
  padding: 21px 37px;
}
.activitySettingModal .modal-content {
  border: unset;
}
.activitySettingModal .modal-body {
  padding: unset !important;
}
.setting-option {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  font: normal normal normal 14px / 19px Comic Sans MS;
  color: #064974;
  padding: 21px 70px;
}

.setting-option span {
  background: #ebebeb;
  color: black;
  width: 100px;
  padding: 10px;
  display: flex;
  justify-content: center;
  border: 1px solid transparent;
}

.setting-option .selected {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #8b60fd;
}

.activitySettingModal .upcoming_head_activity {
  font: normal normal bold 14px / 19px Comic Sans MS;
  color: #ffffff;
}

.activity-apply-btn {
  display: flex;
  justify-content: flex-end;
  padding: 21px 70px;
}
.activity-apply-btn button {
  background: #8b60fd 0% 0% no-repeat padding-box;
  border-radius: 22px;
  width: 100px;
  border: 1px solid #8b60fd;
}

@media only screen and (max-device-width: 768px) {
  .activitySettingModal .modal-header {
    padding: 15px 20px;
  }
  .activitySettingModal .modal-dialog {
    width: 600px;
  }

  .setting-option {
    padding: 10px 35px;
  }
  .activity-apply-btn {
    padding: 10px 35px;
  }
}
@media only screen and (max-device-width: 500px) {
  .activity-apply-btn {
    justify-content: center;
  }
  .setting-option {
    flex-direction: column;
  }
}
