.active_tab {
  color: #fff;
  background-color: #0a91e7;
  width: 90px;
  height: 30px;
  text-align: center;
  border: none;
  border-radius: 5px;
  padding: 0px;
  font-size: 11px;
}

.nonactive_tab {
  color: black;
  background-color: #eeeeee;
  width: 90px;
  height: 30px;
  text-align: center;
  border: none;
  border-radius: 5px;
  padding: 0px;
  font-size: 11px;
}

.active_tab:hover {
  color: white;
}

.revion_topicName_div {
  width: 255px;
  height: 600px !important;
  background: #ffffff;
  overflow-y: auto;
  /* overflow-x: hidden; */
  /* margin-left: 20px; */
}

.active_topic_name {
  /* width: 251px; */
  /* height: 55px; */
  background-color: #8b60fd;
  color: white;
  font-size: 18px;
  text-align: start;
  padding: 8px 25px;
  border: none;
  border-radius: 4px 4px 4px 4px;
  cursor: pointer;
}

.topic_name {
  width: 251px;
  /* height: 55px; */
  font-size: 18px;
  cursor: pointer;
  color: #064974;
  text-align: start;
  padding: 8px 25px;
}

.revision_table_container {
  width: 606px;
  height: auto;
  border-radius: 8px 8px 0px 0px;
}

.lesson_score_div {
  width: 100%;
  /* width: 606px; */
  /* height: 533px; */
  margin-left: 10px;
  border-radius: 8px 8px 0px 0px;
  background-color: white;
}

.revision_table_head {
  height: 59px;
  background: #e0e0e0;
  border-radius: 8px 8px 0px 0px;
  text-align: start;
  font-weight: bold;
}

.rev_head_text {
  padding-left: 10px;
  font-weight: 500;
}

.scheme_content {
  min-height: 30vh;
  height: 70%;
  /* padding: 4rem 8rem; */
  background-color: #fef9ef;
  color: #666666;
  font-size: 20px;
  border-radius: 50px !important;
  text-align: justify;
  text-justify: inter-word;
  border-radius: 50px;
}

.row_dropbtn {
  background-color: white;
  border: 1px solid #beaeae;
  border-radius: 9px;
  width: 80px;
  height: 45px;
  /* margin-bottom: 20px; */
}

.react-pdf__Page__canvas {
  margin: 0 auto;
  width: 100% !important;
  height: 100% !important;
}

.year_scheme {
  margin-left: -40px;
}

.revesion_table_content_rivision {
  background-color: #ffffff;
  color: #918e96;
  height: 50px;
  border: 1px solid #08134a1a;
  opacity: 1;
}

.rev_content_text {
  /* height: 40px; */
  padding: 0px 10px;
}

.revision_table_container .rev_content_text + .rev_content_text {
  border-left: 0px solid #ebebeb !important;
}

.revision_table_com_container {
  min-width: 101px;
  width: 100%;
  height: auto;
  border-radius: 8px 8px 0px 0px;
}

.rev_start {
  font-size: 17px;
  color: #8b60fd;
  /* height: 40px; */
  padding: 0px 10px;
  cursor: pointer;
}

.rev_score {
  font-size: 17px;
  color: #000000;
  /* height: 40px; */
  padding: 0px 10px;
}

.com_top_div {
  /* width: 101px; */
  /* height: 533px; */
  margin-left: 10px;
  border-radius: 8px 8px 0px 0px;
  background-color: white;
}

.com_div {
  /* height: 55px; */
  font-size: 16px;
  padding: 10px;
  text-align: center;
  background-color: #e0e0e0;
  font-weight: bold;
  border-radius: 6px 6px 0px 0px;
  font-weight: 500;
}

.right_rev {
  height: 40px;
  padding-top: 10px;
  width: 100px;
}

.table_content_top_margin {
  margin-top: 4rem;
}

/* pastpaper table class starts here */

.rev_pastpaper_table_container {
  width: 948px;
  /* height: 532px; */
  border-radius: 4px 4px 0px 0px;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.rev_pastpaper_table_head {
  height: 76px;
  background: #e0e0e0;
  /* border-radius: 4px 4px 0px 0px; */
  text-align: start;
  font-weight: bold;
}

.close_btn_notification {
  position: absolute;
  top: 5%;
  right: 5%;
  z-index: 999;
}

.close_btn {
  margin-left: 80%;
}

.rev_pastpaper_head_text {
  font-size: 25px;
  color: #3e4954;
  font-weight: 400 !important;
}

.rev_pastpaper_content_text {
  /* height: 40px; */
  padding-left: 82px;
  text-align: left;
  font-size: 22px;
  border: 1px solid #08134a1a;
}

.revesion_pastpaper_table_content {
  background-color: #ffffff;
  color: #918e96;
  height: 76px;
}

.pastpaperSpacing {
  margin-right: 0.5rem;
  margin-left: 1rem;
}

/* modal css starts here */

.revision_download {
  color: #8b60fd;
}

.rev_close {
  height: 24px;
  width: 24px;
  cursor: pointer;
  margin-left: 6rem;
}

.notes_slide_img {
  width: 921px;
  height: 473px;
}

.slide_head {
  font-size: 18px;
  color: #8b60fd;
}

.cir_table_heading {
  height: 49px !important;
  background: #e0e0e0;
  border-radius: 8px 8px 0px 0px;
  text-align: start;
  font-weight: bold;
}

.revesion_top_table {
  padding-left: 20px !important;
  padding-right: 15px !important;
  /* padding-left: -4px; */
}

.cir_head_text_rivision {
  /* padding-left: 0px !important; */
  font-size: 20px;
  text-align: center;
  padding-top: 0px !important;
}

.cir_head_text_no {
  padding-left: 0px !important;
  font-size: 20px;
}

.cir_table_container {
  width: 100%;
  height: 600px;
  /* margin-left: 10px; */
  border-radius: 8px 8px 0px 0px;
}

.cir_content_text_rivision {
  padding-left: 0px !important;
  text-align: center !important;
  font-size: 22px !important;
  border-bottom: 0px solid #fef9ef !important;
  cursor: pointer;
}

.cir_table_container .cir_content_text_rivision + .cir_content_text_rivision {
  border-left: 0px solid #ebebeb !important;
  color: black;
}

.lesson_1_div {
  display: flex;
  background-color: #e0e0e0;
  font-size: 16px;
  /* height: 55px; */
  border-radius: 8px 8px 0px 0px;
  font-weight: bold;
  padding-bottom: 10px;
}

.lesson_table_head1 {
  width: 80px;
  text-align: start;
  font-weight: 500;
  padding-left: 10px;
  padding-top: 10px;
}

.lesson_table_head2 {
  width: 200px;
  text-align: start;
  font-weight: 500;
  padding-top: 15px;
  padding-left: 40px;
}

.lesson_table_head3 {
  width: 300px;
  text-align: start;
  font-weight: 500;
  padding-top: 15px;
  padding-left: 40px;
}

.lesson_no_div {
  color: #918e96;
  font-size: 16px;
  padding: 20px;
  /* width: 80px; */
  /* min-height: 476px; */
  border-right: 1px solid #ebebeb;
}

.lesson_name_div {
  color: #918e96;
  font-size: 16px;
  /* width: 200px; */
  min-height: 476px;
  border-right: 1px solid #ebebeb;
}

.lesson_descrp_div {
  color: #918e96;
  font-size: 16px;
  /* width: 400px; */

  min-height: 476px;
}

.lesson_1_1 {
  height: 40px;
  /* padding: 10px 20pxs; */
}

@media screen and (min-width: 1024px) and (max-width: 1441px) {
  .com_top_div {
    min-width: 101px;
    width: 100%;
    height: 533px;
    margin-left: 10px;
    border-radius: 8px 8px 0px 0px;
    background-color: white;
  }
  .comp_container {
    /* min-height: 50vh; */
  }
  .right_rev {
    height: 40px;
    padding-top: 10px;
    width: 100%;
  }

  .row_drop_open {
    height: 290px;
  }
  .cir_head_text_rivision {
    padding-left: 10px;
  }
  .revion_topicName_div {
    overflow-x: hidden;
    height: 585px !important;
  }
}

@media screen and (max-width: 1024px) {
  .revesion_top_table {
    margin-right: 25px;
    overflow-x: scroll;
    /* height: 465px !important; */
  }
}

@media screen and (min-width: 1024px) and (max-width: 1220px) {
  .rev_pastpaper_table_container {
    width: 700px;
    min-height: 532px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .cir_table_container {
    max-width: 737px !important;
    width: auto;
    height: auto;
    margin-left: 10px;
    margin-right: 10px;
    border-radius: 8px 8px 0px 0px;
  }

  .rev_pastpaper_head_text {
    padding-left: 30px;
  }

  .rev_pastpaper_content_text {
    /* height: 40px; */
    padding-left: 30px;
    text-align: left;
    font-size: 22px;
    border: 0px solid #08134a1a !important;
  }

  .cir_content_text_rivision {
    padding-left: 35px;
  }

  .notes_slide_img {
    width: 700px;
    height: 350px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .rev_pastpaper_table_container {
    width: 500px;
    min-height: 532px;
    margin-left: 20px;
    margin-right: 10px;
  }

  .no_data1_img {
    position: relative;
    left: 100px;
    top: 50px;
  }

  .cir_table_container {
    min-width: 538px !important;
    width: auto;
    height: auto;
    margin-left: 10px;
    margin-right: 10px;
    border-radius: 8px 8px 0px 0px;
  }

  .rev_pastpaper_head_text {
    /* padding-left: 20px; */
  }

  .cir_head_text_rivision,
  .cir_head_text_no {
    padding-left: 20px;
  }

  .cir_content_text_rivision {
    padding-left: 25px;
  }

  .rev_pastpaper_content_text {
    /* height: 40px; */
    padding-left: 20px;
    text-align: left;
    font-size: 22px;
    border: 1px solid #08134a1a;
  }

  .notes_slide_img {
    width: 468px;
    height: 373px;
  }
  .comp_container {
    min-height: 50vh;
  }
  .revion_topicName_div {
    overflow-x: hidden;
  }
}

@media screen and (max-width: 767px) {
  .rev_pastpaper_table_container {
    width: 400px;
    min-height: 532px;
    margin-left: 10px;
    /* margin-right: 10px; */
  }

  .cir_head_text_rivision,
  .cir_head_text_no {
    padding-left: 20px;
  }

  .cir_table_container {
    min-width: 700px;
    width: auto;
    height: auto;
    margin-left: 10px;
    margin-right: 10px;
    border-radius: 8px 8px 0px 0px;
  }

  .rev_pastpaper_head_text {
    padding-right: 10px;
  }

  .cir_content_text_rivision {
    padding-left: 25px;
  }

  .rev_pastpaper_content_text {
    /* height: 40px; */
    padding-left: 10px;
    text-align: left;
    font-size: 22px;
    border: 1px solid #08134a1a;
  }

  .notes_slide_img {
    min-width: 250px;
    width: 100%;
    height: 200px;
  }

  .revion_topicName_div {
    width: auto;
    height: 600px !important;
    overflow-y: initial;
  }

  .rev_close {
    margin-left: 1.5rem;
  }

  .slide_head {
    font-size: 14px;
  }
}

@media screen and (max-width: 767px) {
  .revion_topicName_div {
    width: auto;
    height: 580px !important;
    overflow-y: initial;
  }
}

@media screen and (min-width: 1220px) and (max-width: 1440px) {
  .sideDiv {
    /* padding: 0px 10px 0px 25px !important; */
  }
}

.cir_content_text_rivisionMy.collections {
  font-size: 17px;
  color: #8b60fd;
  /* height: 40px; */
  padding: 0px 10px;
  cursor: pointer;
}

.under_scr {
  color: black;
  font-weight: bold;
}
.under_scr1 {
  font-size: 50px;
  color: black;
  padding-bottom: 5px;
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .no_data_img {
    width: 300px;
  }
  .no_data1_img {
    position: relative;
    left: 100px;
    top: 50px;
  }
  .revesion_top_table {
    padding-left: 0px !important;
  }
  .cir_content_text_rivision {
    font-size: 16px !important;
  }
  .rev_pastpaper_head_text {
    font-size: 20px !important;
    padding-left: 18px !important;
  }
}

/* @media screen and (min-width: 320px) and (max-width: 450px) {

.paginationRes{
  background-color: rgb(254, 249, 239);
    position: absolute;
    bottom: 260px;
    left: 80px;
    height: 100px;
}
.resRevison{
position: relative;
}

  }

  @media screen and (min-width:768px) and (max-width: 1023px) {

    .paginationRes{
      background-color: rgb(254, 249, 239);
        position: absolute;
        bottom: 260px;
        left: 350px;
        height: 100px;
    }
    .resRevison{
    position: relative;
    }
    
      }


      @media screen and (min-width:1024px) and (max-width: 1439px) {

        .paginationRes{
          background-color: rgb(254, 249, 239);
            position: absolute;
            bottom: 260px;
            left:500px;
            height: 100px;
        }
        .resRevison{
        position: relative;
        }
        
          }

          @media screen and (min-width:1440px) and (max-width: 15px) {

            .paginationRes{
              background-color: rgb(254, 249, 239);
                position: absolute;
                bottom: 260px;
                left:500px;
                height: 100px;
            }
            .resRevison{
            position: relative;
            }
            
              } */

@media only screen and (min-device-width: 1536px) and (max-device-width: 1900px) {
  /* .comp_container{
                  min-height: 0px !important;
                } */

  .revion_topicName_div {
    height: 586px !important;
  }
}

@media only screen and (min-device-width: 1024px) and (max-device-width: 1439px) {
  .no_data1_img {
    position: relative;
    left: 100px;
    top: 50px;
  }
}

@media only screen and (min-device-width: 1440px) and (max-device-width: 1920px) {
  .no_data1_img {
    position: relative;
    left: 250px;
    top: 50px;
  }
}

.paginationRes {
  margin-top: 10px;
}
