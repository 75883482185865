.container_error {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 768px) and (min-width: 320px) {
  .container_error {
    width: 70%;
  }
}
