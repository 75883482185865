@import url(https://fonts.googleapis.com/css?family=PT+Sans);

.wrapper {
  margin: 5% auto;
}

.thermometer {
  position: relative;
  height: 72px;
  rotate: 270deg;
}

.thermometer-body {
  left: 75px;
  top: 50px;
  background-color: white;
  border-radius: 0 22px 22px 0;
  border: 5px solid black;
  border-left: none;
  height: 38px;
  width: 240px;
  margin-top: 100px;
}

.primary .thermometer-body2 {
  border-color: black;
}

/* .thermometer-body:before {
  content: "1%";
  color: white;
  rotate: 90deg;
  padding-top: 30px;
  font-size: 25px;
  font-weight: 600;
  width: 100px;
  height: 100px;
  position: absolute;
  top: -21px;
  left: -64px;
  z-index: 1;
  background: #e97838;
  border-radius: 50%;
  border: 5px solid black;
  box-shadow: inset 0 0 0 4px white;
  margin: -9px;
} */

.thermometer_circle {
  color: white;
  rotate: 90deg;
  padding-top: 30px;
  font-size: 25px;
  font-weight: 600;
  width: 100px;
  height: 100px;
  position: absolute;
  top: -21px;
  left: -64px;
  z-index: 1;
  background: #e97838;
  border-radius: 50%;
  border: 5px solid black;
  box-shadow: inset 0 0 0 4px white;
  margin: -9px;
}

.primary .thermometer-body2:before {
  background: #6deea5;
  border-color: black;
}

progress[value] {
  appearance: none;
  border: none;
  margin: 2.5px;
  width: 200px;
  height: 15px;
  position: relative;
  top: 5px;
  left: -1px;
  background-color: #fff;
  color: #fff;
  border-radius: 0 16px 16px 0;
  z-index: 1;
}

progress[value]::-webkit-progress-bar {
  background-color: #fff;
  border-radius: 0 16px 16px 0;
}

progress[value]::-webkit-progress-value {
  background-color: #e97838;
  border-radius: 0 32px 8px 0;
}

.primary progress[value]::-webkit-progress-value {
  background-color: #6deea5;
}

progress[value]::-moz-progress-bar {
  background-color: #e97838;
  border-radius: 0 32px 8px 0;
}

.primary progress[value]::-moz-progress-bar {
  background-color: #6deea5;
}

progress[value="100"]::-webkit-progress-value {
  border-radius: 0 16px 16px 0;
}

progress[value="100"]::-moz-progress-bar {
  border-radius: 0 16px 16px 0;
}

progress[value="0"]::-webkit-progress-bar:before {
  content: "0%";
  position: absolute;
  top: -2px;
  left: -40px;
}

progress[value="25"]::-webkit-progress-bar:before {
  content: "25%";
  position: absolute;
  top: -2px;
  left: -40px;
}

progress[value="50"]::-webkit-progress-bar:before {
  content: "50%";
  position: absolute;
  top: -2px;
  left: -40px;
}

progress[value="75"]::-webkit-progress-bar:before {
  content: "75%";
  position: absolute;
  top: -2px;
  left: -40px;
}

progress[value="100"]::-webkit-progress-bar:before {
  content: "100%";
  position: absolute;
  top: -2px;
  left: -40px;
}

.animated {
  margin-top: 30px;
}

/* button {
  margin-left: 120px;
  padding: 6px 16px;
  background-color: #e74c3c;
  border: 5px solid #c0392b;
  border-radius: 32px;
  color: #fff;
} */
.primary button {
  background-color: #6deea5;
  border-color: #6deea5;
}

.primary button:hover {
  color: #6deea5;
}

button:disabled,
.primary button:disabled {
  background-color: #bdc3c7;
  color: #7f8c8d;
}

.thermometer-body2 {
  left: 75px;
  top: 50px;
  background-color: white;
  border-radius: 0 22px 22px 0;
  border: 5px solid black;
  border-left: none;
  height: 38px;
  width: 240px;
  margin-top: 100px;
  z-index: 1;
}

/* .thermometer-body2:before {
  content: "33%";
  color: white;
  rotate: 90deg;
  padding-top: 30px;
  font-size: 25px;
  font-weight: 600;
  width: 100px;
  height: 100px;
  position: absolute;
  top: -21px;
  left: -64px;
  z-index: 1;
  background: #6deea5;
  border-radius: 50%;
  border: 5px solid black;
  box-shadow: inset 0 0 0 4px white;
  margin: -9px;
} */

.thermometer_circle2 {
  color: white;
  rotate: 90deg;
  padding-top: 30px;
  font-size: 25px;
  font-weight: 600;
  width: 100px;
  height: 100px;
  position: absolute;
  top: -21px;
  left: -64px;
  z-index: 1;
  background: #6deea5;
  border-radius: 50%;
  border: 5px solid black;
  box-shadow: inset 0 0 0 4px white;
  margin: -9px;
}

@media only screen and (min-device-width: 425px) and (max-device-width: 767px) {
  .thermometer-body2 {
    left: 75px;
    top: 50px;
    height: 38px;
    width: 240px;
    margin-top: 10px;
    z-index: 1;
  }

  .thermometer-body {
    left: 75px;
    top: 50px;
    height: 38px;
    width: 240px;
    margin-top: 10px;
  }
}

@media only screen and (min-device-width: 375px) and (max-device-width: 424px) {
  .thermometer-body2 {
    left: 75px;
    top: 50px;
    height: 38px;
    width: 240px;
    margin-top: 50px;
    z-index: 1;
  }

  .thermometer-body {
    left: 75px;
    top: 50px;
    height: 38px;
    width: 240px;
    margin-top: 50px;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 374px) {
  .thermometer-body2 {
    left: 75px;
    top: 50px;
    height: 38px;
    width: 240px;
    margin-top: 100px;
    z-index: 1;
  }

  .thermometer-body {
    left: 75px;
    top: 50px;
    height: 38px;
    width: 240px;
    margin-top: 100px;
  }
}

@media only screen and (min-device-width: 1240px) and (max-device-width: 1440px) {
  .prgrs_chptr_div {
    display: grid !important;
    grid-template-columns: repeat(3, 3.3fr) !important;
    grid-template-rows: repeat(1, 0.3fr) !important;
    grid-column-gap: 1rem !important;
  }
}