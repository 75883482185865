.faq_display_none {
  display: none;
}

.faq_container {
  min-height: 87vh;
  background-color: #fef9ef;
}

.wel_faq {
  font-size: 25px;
  color: #111111;
}

.faq_img {
  width: 234px;
  height: 183px;
}

.accordian_qstn {
  display: flex;
  justify-content: space-between;
  background-color: white;
  border: none;
  min-height: 50px;
  min-width: 1000px;
  font-size: 16px;
  border-radius: 6px;
  padding: 20px;
  cursor: pointer;
}

.accordian_qna_div {
  border: 1px solid #fff;
}
.accordian_qna_div:hover {
  border: 1px solid #daccff;
  border-radius: 6px;
}

.acc_qstn {
  text-align: start;
}

.accordian_ans {
  background-color: white;
  border: none;
  min-height: 50px;
  min-width: 1000px;
  font-size: 16px;
  border-radius: 6px;
  padding: 20px;
}
.acc_ans {
  text-align: start;
}

.faq_find_here {
  font-size: 18px;
}

.contact_squipill {
  color: #8b60fd;
  font-size: 18px;
  margin-left: 5px;
}

.faq_textarea {
  background-color: white;
  border: 1px solid #8b60fd;
  height: 137px;
  width: 1000px;
  font-size: 16px;
  border-radius: 3px;
  padding: 20px;
  color: #999999;
  resize: none;
  overflow: auto;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.send_query_btn {
  width: 187px;
  height: 36px;
  border: 1px solid #fafafa;
  border-radius: 49px;
  color: white;
  font-weight: 500;
  font-size: 12px;
  background-color: #ff6700;
  box-shadow: 0px 3px 6px #ff6700;
  margin-top: 1rem;
}

.acc_icon {
  margin-left: 10px;
}

/* responsive styling starts here */

@media only screen and (min-width: 1225px) {
  .accordian_qstn {
    min-height: 50px;
    min-width: 1000px;
  }
  .accordian_ans {
    min-height: 50px;
    min-width: 1000px;
  }
  .accordian_qna_div {
    width: 1002px;
  }
  .faq_textarea {
    height: 137px;
    width: 1000px;
    font-size: 16px;
    border-radius: 3px;
    padding: 20px;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1224px) {
  .accordian_qstn {
    min-height: 50px;
    min-width: 700px;
  }
  .accordian_ans {
    min-height: 50px;
    min-width: 700px;
  }
  .accordian_qna_div {
    width: 700px;
  }
  .faq_textarea {
    height: 137px;
    width: 700px;
    font-size: 16px;
    border-radius: 3px;
    padding: 20px;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1023px) {
  .accordian_qstn {
    min-height: 50px;
    min-width: 500px;
  }
  .accordian_ans {
    min-height: 50px;
    min-width: 500px;
  }
  .accordian_qna_div {
    width: 500px;
  }
  .faq_textarea {
    height: 137px;
    width: 500px;
    font-size: 16px;
    border-radius: 3px;
    padding: 20px;
  }
}

@media only screen and (min-device-width: 425px) and (max-device-width: 767px) {
  .accordian_qstn {
    min-height: 50px;
    min-width: 350px;
  }
  .accordian_ans {
    min-height: 50px;
    min-width: 350px;
  }
  .accordian_qna_div {
    width: 350px;
  }
  .faq_textarea {
    height: 137px;
    width: 350px;
    font-size: 16px;
    border-radius: 3px;
    padding: 20px;
  }
}
@media only screen and (min-device-width: 375px) and (max-device-width: 424px) {
  .accordian_qstn {
    min-height: 50px;
    min-width: 300px;
  }
  .accordian_ans {
    min-height: 50px;
    min-width: 300px;
  }
  .accordian_qna_div {
    width: 300px;
  }
  .faq_textarea {
    height: 137px;
    width: 300px;
    font-size: 16px;
    border-radius: 3px;
    padding: 20px;
  }
}

@media only screen and (min-device-width: 200px) and (max-device-width: 374px) {
  .accordian_qstn {
    min-height: 50px;
    min-width: 280px;
  }
  .accordian_ans {
    min-height: 50px;
    min-width: 280px;
  }
  .accordian_qna_div {
    width: 280px;
  }
  .faq_textarea {
    height: 137px;
    width: 280px;
    font-size: 16px;
    border-radius: 3px;
    padding: 20px;
  }
}

@media only screen and (min-device-width: 1024px) and (max-device-width: 1920px) {
  .send_query_btn {
    margin-bottom: 12px;
  }
}
