.row_one {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 6rem;
  grid-row-gap: 5rem;
}

/* Ayushi css */

.chaptr_div {
  position: relative;
  max-height: 250px;
  height: 100%;
  max-width: 304px;
  width: 100%;
}

.chapter_contaier_div {
  display: flex;
  justify-content: center;
  /* height: 500px; */
  /* overflow-y: scroll; */
}

/* design change starts here */

.chaptr_img {
  height: 220px;
  width: 275px;
  border-radius: 30px;
}

.one_anotherBox {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  padding: 12px 24px;
  border: none;
  cursor: pointer;
  text-align: center;
  width: 195px;
  height: 153px;
  background-color: #fff;
  font-size: 20px;
  border-radius: 10px;
}
.chpt_num {
  font-size: 20px;
  text-align: start;
}

.chpt_name {
  font-weight: bold;
  font-size: 20px;
  text-align: left;
  margin-top: 2rem;
}

/* Media query for screens with a maximum width of 425px */
@media only screen and (min-width: 1440px) {
  .row_one {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 2rem;
    grid-row-gap: 3rem;
  }
}

@media only screen and (min-width: 1100px) and (max-width: 1200px) {
  /* .chaptr_div {
    margin-left: 1rem;
  } */
  .row_one {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 2rem;
    grid-row-gap: 3rem;
  }
}

@media only screen and (min-width: 769px) and (max-width: 900px) {
  .row_one {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-column-gap: 2rem;
    grid-row-gap: 3rem;
  }

  .chapter_contaier_div {
    display: flex;
    justify-content: center;
  }
}

@media only screen and (min-width: 650px) and (max-width: 768px) {
  .row_one {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-column-gap: 2rem;
    grid-row-gap: 3rem;
  }
  .chapter_contaier_div {
    display: flex;
    justify-content: center;
  }
}

@media only screen and (min-width: 326px) and (max-width: 450px) {
  .row_one {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(6, 1fr);
    grid-row-gap: 2rem;
  }
  .chapter_contaier_div {
    display: flex;
    justify-content: center;
  }
}

@media only screen and (min-width: 450px) and (max-width: 650px) {
  .row_one {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(6, 1fr);
    grid-column-gap: 0.75rem;
    grid-row-gap: 1rem;
  }
  .chapter_contaier_div {
    display: flex;
    justify-content: center;
  }
  .chaptr_img {
    height: 180px;
    width: 200px;
    border-radius: 30px;
  }
  .one_anotherBox {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    padding: 5px 15px;
    border: none;
    cursor: pointer;
    text-align: center;
    width: 160px;
    height: 130px;
    background-color: #fff;
    font-size: 20px;
    border-radius: 10px;
    margin-top: 0px;
  }

  .chpt_name {
    font-weight: bold;
    font-size: 25px;
  }
  .chpt_num {
    font-size: 18px;
    text-align: start;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1100px) {
  .row_one {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(6, 1fr);
    grid-column-gap: 3rem;
    grid-row-gap: 1rem;
  }
  .chapter_contaier_div {
    display: flex;
    justify-content: center;
  }
  .chaptr_img {
    height: 185px;
    width: 210px;
    border-radius: 30px;
  }
  .one_anotherBox {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    padding: 5px 15px;
    border: none;
    cursor: pointer;
    text-align: center;
    width: 150px;
    height: 122px;
    background-color: #fff;
    font-size: 20px;
    border-radius: 10px;
    margin-top: 0px;
  }

  .chpt_name {
    font-weight: bold;
    font-size: 16px;
  }
  .chpt_num {
    font-size: 18px;
    text-align: start;
  }
}

@media only screen and (max-width: 325px) {
  .row_one {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(6, 1fr);
    grid-row-gap: 2rem;
  }
  .chapter_contaier_div {
    display: flex;
    justify-content: center;
  }
  .chaptr_img {
    height: 192px;
    width: 220px;
    border-radius: 30px;
  }
  .one_anotherBox {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    padding: 5px 15px;
    border: none;
    cursor: pointer;
    text-align: center;
    width: 160px;
    height: 130px;
    background-color: #fff;
    font-size: 20px;
    border-radius: 10px;
    margin-top: 0px;
  }

  .chpt_name {
    font-weight: bold;
    font-size: 18px;
  }
  .chpt_num {
    font-size: 18px;
    text-align: start;
  }
}
