.myCollection_top_div {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 2rem;
  grid-row-gap: 3rem;
}

.my_col_head {
  font-size: 21px;
  color: white;
  font-weight: bold;
  display: flex;
  margin-bottom: 3rem;
}

.col_btn_desktop {
  margin-right: 5rem;
  margin-top: 3rem;
}

@media only screen and (min-width: 1225px) {
  .col_btn_mobile {
    display: none;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1224px) {
  .myCollection_top_div {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 1rem;
    grid-row-gap: 3rem;
  }
  .col_btn_mobile {
    display: none;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1023px) {
  .myCollection_top_div {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 2rem;
    grid-row-gap: 2rem;
  }
  .col_btn_mobile {
    display: none;
  }
}

@media only screen and (min-device-width: 200px) and (max-device-width: 767px) {
  .col_btn_mobile {
    display: block;
  }
  .col_btn_desktop {
    display: none;
  }
  .col_eadig {
    margin-top: 2rem;
  }
}

@media only screen and (min-device-width: 425px) and (max-device-width: 767px) {
  .myCollection_top_div {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
  }
}
@media only screen and (min-device-width: 375px) and (max-device-width: 424px) {
  .myCollection_top_div {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 3rem;
    grid-row-gap: 1rem;
  }
}

@media only screen and (min-device-width: 200px) and (max-device-width: 374px) {
  .myCollection_top_div {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 1.5rem;
    grid-row-gap: 1rem;
  }
}
