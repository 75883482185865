.mainDiv {
  margin-left: 100px;
  width: 90%;
}
.ButtonAct {
  background-color: #8b60fd;
  color: white;
  border: none;
  border-radius: 8px;
  height: 30px;
  padding: 4px 10px;
  border: 2px solid white;
  box-shadow: 1px 1px 1px 1px #dcd6d6;
}

.orangeBtn {
  border: none;
  background-color: white;
  color: black;
  padding: 4px 20px 4px 20px;
  height: 37px;
  font-weight: 700;
}

.orangeBtn_Active {
  border: none;
  background-color: #facd51;
  color: black;
  padding: 4px 20px 4px 20px;
  font-weight: 700;
  border-radius: 3px;
  height: 37px;
}

.weekdata {
  background: linear-gradient(
    180.55deg,
    #f9ce54,
    #f8cf57,
    #f1d771,
    #e9e18f,
    #e4e69e
  );
  min-height: 546px;
  height: auto;
}

.Card {
  background-color: white;
  margin: 30px;
  height: 80%;
  box-shadow: 10px 10px #facd51;
  max-height: 441px;
  height: 100%;
  min-width: 1100px;
}

.weekBtn {
  border: none;
  background-color: #facd51;
  color: black;
  padding-left: 4px;
  padding-right: 4px;
  border-radius: 30px;
  width: 400px;
  height: 50px;
  font-size: 20px;
  font-weight: 800;

  padding-top: 12px;
  border: 1px solid white;
  box-shadow: 2px 2px 2px 2px #facd51;
}

.cardContent {
  margin-bottom: 100px;
}

.start {
  background-color: #fae751;
  color: black;
  border: none;
  border-radius: 20px;
  padding: 5px 10px;
  width: 107px;
  height: 39px;
}
.redo {
  background-color: #989898;
  color: black;
  border: none;
  border-radius: 20px;
  padding: 5px 10px;
  width: 107px;
  height: 39px;
}

.week {
  font-weight: 600;
  opacity: 0.5;
  padding: 0px 5px;
  cursor: pointer;
}

.week1 {
  font-weight: 600;
  padding: 0px 5px;
  cursor: pointer;
}

.week_div {
  padding-top: 20px;
}

.month01 {
  font-size: 20px;
}

.grayDot {
  border-top: 1px dashed #c7c7c7;
  margin-top: 15px;
  width: 200px;
}

.checkBox {
  height: 18px;
  width: 18px;
  border-radius: 2px;
  margin-top: 6px;
}

.mathDropDown {
  border: #ff782c;
  color: #ff782c;
  width: 150px;
}

.sunFlower {
  margin-top: 300px;
}
.page-link {
  position: relative;
  display: block;
  padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
  font-size: var(--bs-pagination-font-size);
  color: black !important;
  text-decoration: none;
  background-color: var(--bs-pagination-bg);
  border: var(--bs-pagination-border-width) solid
    var(--bs-pagination-border-color);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.june {
  border: 1px solid #8b60fd;
  border-radius: 4px;
  width: 90px;
  height: 29px;
  margin: 0px 10px;
}

.margin_5 {
  margin-right: 3rem;
  margin-left: 3rem;
}

.season_btn {
  display: flex;
  width: 50%;
  margin-left: 0.3rem;
}

.activity_sideDiv {
  margin-left: 90px;
  display: flex;
  background-color: white;
}

@media only screen and (min-width: 1225px) {
  .start {
    margin-right: 3rem;
    margin-left: 3rem;
  }
  .weekBtn {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
  .week_div {
    padding-top: 20px;
    margin-right: 3rem;
    margin-left: 1rem;
  }
  .season_btn_inside {
    display: flex;
    justify-content: space-between;
  }
  .june_div {
    margin-right: 3rem;
  }
  .no_data_found_div {
    width: 800px;
    font-size: 40px;
  }
  .season_data_div {
    width: 800px !important;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1224px) {
  .grayDot {
    display: none;
    border-top: 1px dashed #c7c7c7;
    margin-top: 20px;
    width: 100px;
  }
  .start {
    margin-right: 2rem;
    margin-left: 2rem;
  }
  .margin_5 {
    margin-right: 2rem;
    margin-left: 2rem;
  }
  .weekBtn {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
  .week_div {
    padding-top: 20px;
    margin-right: 3rem;
    margin-left: 3rem;
  }
  .season_btn_inside {
    display: flex;
    justify-content: space-between;
  }
  .no_data_found_div {
    width: 480px;
    font-size: 30px;
  }
  .season_data_div {
    width: 480px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .activity_sideDiv {
    display: block;
  }
  .grayDot {
    display: none;
    border-top: 1px dashed #c7c7c7;
    margin-top: 20px;
    width: 70px;
  }
  .sun_div,
  .flower_div {
    display: none;
  }
  .start {
    border-radius: 20px;
    padding: 5px 10px;
    width: 90px;
    height: 39px;
    margin-right: 1rem;
    margin-left: 1rem;
  }
  .redo {
    border-radius: 20px;
    padding: 5px 10px;
    font-weight: 550;
    width: 90px;
    height: 39px;
  }
  .margin_5 {
    margin-right: 1.5rem;
    margin-left: 1.5rem;
  }
  .Card {
    margin: 20px;
    height: 80%;
    width: 100%;
    min-width: auto;
    box-shadow: 10px 10px #facd51;
  }
  .weekBtn {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
  .week_div {
    padding-top: 20px;
    margin-right: 3rem;
    margin-left: 3rem;
  }
  .season_btn_inside {
    display: flex;
    justify-content: space-between;
  }
  .weekdata {
    padding-bottom: 20px;
  }
  .no_data_found_div {
    width: 400px;
    font-size: 25px;
  }
  .season_data_div {
    width: 400px !important;
  }
}

@media screen and (max-width: 767px) {
  .activity_sideDiv {
    display: block;
    margin-left: 0px;
    margin: 15px;
    padding: 0px;
  }
  /* .grayDot {
    display: none;
    border-top: 1px dashed #c7c7c7;
    margin-top: 20px;
    width: 50px;
  } */
  .sun_div,
  .flower_div {
    display: none;
  }
  .start {
    margin-right: 1rem;
    margin-left: 1rem;
  }
  .start {
    border-radius: 18px;
    padding: 0px 0px;
    width: 70px;
    height: 30px;
  }
  .redo {
    border-radius: 18px;
    padding: 0px 0px;
    width: 70px;
    height: 30px;
  }
  .margin_5 {
    margin-right: 0.25rem;
    margin-left: 0.25rem;
  }
  .weekBtn {
    padding-left: 4px;
    padding-right: 4px;
    border-radius: 30px;
    width: 180px;
    height: 50px;
    font-size: 20px;
    font-weight: 800;

    padding-top: 12px;
    border: 1px solid white;
    box-shadow: 2px 2px 2px 2px #facd51;
  }
  .season_btn {
    display: block;
    width: 100%;
  }
  .Card {
    margin: 30px;
    height: 80%;
    width: 83%;
    min-width: auto;
    box-shadow: 1px 10px #facd51;
  }
  .month01 {
    font-weight: 600;
    font-size: 16px;
  }
  .weekBtn {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .week_div {
    padding-top: 20px;
    margin-right: 0.25rem;
    margin-left: 0.25rem;
  }
  .season_btn_inside {
    display: block;
  }
  .weekdata {
    padding-bottom: 20px;
    min-height: 446px;
  }
  .no_data_found_div {
    width: 100%;
    font-size: 18px;
  }
  .season_data_div {
    width: 100% !important;
  }
  .season_btn div {
    margin-right: 1rem;
  }

  .orangeBtn_Active,
  .orangeBtn {
    margin-right: 1rem;
    width: 100px;
  }
}

@media only screen and (min-width: 320px) and (max-width: 425px) {
  .season_btn div {
    display: flex;
    justify-content: space-between;
  }
  .grayDot {
    border-top: 1px dashed #c7c7c7;
    margin-top: 11px;
    margin-left: 15px !important;
    width: 50px !important;
  }
  .week1 {
    font-weight: 600;
    padding: 0px 43px;
    cursor: pointer;
}
.weekdata{
  overflow-x: scroll;
}
.mobileView{
  margin-left: 80px;
}
.sunRes{
  margin-right: 20px;
  margin-left: 10px;
}
/* .Card {
  margin: 0px;
  height: 80%;
  width: 83%;
  box-shadow: 1px 10px #facd51;
} */
}
@media only screen and (min-width: 376px) and (max-width: 425px) {


.mobileView{
margin-left: 40px;
}
}
@media only screen and (min-width: 1024px) and (max-width: 1535px) {


  .mobileView{
  margin-left: 40px;
  }
  .Card {
    margin: 20px;
    /* height: 80%; */
    width: 100%;
    min-width: auto;
    box-shadow: 10px 10px #facd51;
  }
  }
  @media only screen and (min-width: 1536px) and (max-width: 1920px) {


    .mobileView{
    margin-left: 40px;
    }
    }