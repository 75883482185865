.scheme_nav {
  height: 104px;
  width: 100%;
  background-color: #8b60fd;
  color: white;
  display: flex;
  justify-content: space-around;
  padding: 2rem 0rem;
}

.nav_data {
  font-size: 30px;
}

.close_btn_mark,
.download_sign {
  cursor: pointer;
}

.scheme_content {
  min-height: 100vh !important;
  height: 100% !important;
  padding: 1rem 13rem !important;
  background-color: #fef9ef;
  color: #666666;
  font-size: 20px;
  text-align: justify;
  text-justify: inter-word;
  border-radius: 0px !important;
}

.react-pdf__Page__annotations,
.annotationLayer {
  display: none;
}

.react-pdf__Document {
  background: white;
  justify-content: center;
  display: flex;
}

.pagec {
  display: flex;
  justify-content: center;
  width: 100%;
}

.Pre_btn {
  background-color: #8b60fd;
  color: white;
  border-radius: 8px;
  width: 100px;
  cursor: pointer;
  border: none;
}

.markedContent {
  display: none;
}
.iframe-container {
  overflow: hidden;
  padding-top: 56.25%; /* 16:9*/
  position: relative;
}

.iframe-container iframe {
   border: 0;
   height: 100%;
   left: 0;
   position: absolute;
   top: 0;
   width: 100%;
}
@media only screen and (min-width: 1220px) {
  .year_scheme {
    margin-left: -200px;
  }
  .scheme_nav {
    justify-content: space-between;
    padding: 2rem 3rem 2rem 6rem;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1200px) {
  .year_scheme {
    margin-left: -100px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .scheme_content {
    min-height: 86vh;
    height: 100%;
    /* padding: 3rem 6rem; */
  }
  .scheme_content {
    min-height: 100vh !important;
    height: 100% !important;
    padding: 1rem 0.8rem !important;
    background-color: #fef9ef;
    color: #666666;
    font-size: 20px;
    text-align: justify;
    text-justify: inter-word;
    border-radius: 0px !important;
  }
  .year_scheme {
    margin-left: -40px;
  }
}

@media only screen and (min-width: 284px) and (max-width: 767px) {
  .scheme_content {
    min-height: 86vh;
    height: 100%;
    /* padding: 2rem 3rem; */
  }
  .pdfIframe{
    height: 500px !important;
    width: 800px !important ;
  }
  .scheme_content {
    min-height: 100vh !important;
    height: 100% !important;
    padding: 1rem 0.8rem !important;
    background-color: #fef9ef;
    color: #666666;
    font-size: 20px;
    text-align: justify;
    text-justify: inter-word;
    border-radius: 0px !important;
  }
  .nav_data {
    font-size: 20px;
  }
  .year_scheme {
    display: none;
  }
  .math_scheme {
    display: none;
  }
}

@media only screen and (min-width: 425px) and (max-width: 767px) {
  .react-pdf__Page__textContent,
  .textLayer,
  .react-pdf__Page__canvas {
    max-width: 400px;
    max-height: 500px;
    height: auto;
  }
}
@media only screen and (min-width: 375px) and (max-width: 424px) {
  .react-pdf__Page__textContent,
  .textLayer,
  .react-pdf__Page__canvas {
    max-width: 340px;
    max-height: 400px;
    height: auto;
  }
}
@media only screen and (min-width: 280px) and (max-width: 374px) {
  .react-pdf__Page__textContent,
  .textLayer,
  .react-pdf__Page__canvas {
    max-width: 280px;
    max-height: 325px;
    height: auto;
  }
}



.new-next-btn{
  width: 50% !important ;
}