.cir_table_container_carriculum {
  width: 100%;
  height: auto;
  /* margin-left: 10px; */
  border-radius: 8px 8px 0px 0px;
}

.cir_table_head {
  height: 55px !important;
  background: #e0e0e0;
  border-radius: 8px 8px 0px 0px;
  text-align: start;
  font-weight: bold;
}

.revesion_table_content {
  background-color: #ffffff;
  color: #918e96;
  height: 50px;
  border: 1px solid #eee3e3 !important;
}

.cir_head_text {
  padding-left: 80px !important;
  font-size: 20px !important;
  /* padding-top: 15px !important; */
}

.cir_head_text_no {
  padding-left: 40px !important;
  font-size: 20px;
}

.carriculum_top_table_div {
  padding-left: 4px !important;
  padding-right: 10px !important;
  padding-left: -4px;
}

.cir_content_text {
  padding-left: 57px !important;
  text-align: left !important;
  font-size: 22px !important;
  border-bottom: 5px solid #fef9ef !important;
  cursor: pointer;
}

.revesion_top_table_div {
  margin-left: 20px;
  margin-right: 20px;
}

.chaptr_no_width {
  width: 250px;
  /* padding-top: 15px; */
}

.sideDiv {
  margin-left: 87px;
  padding-bottom: 50px;
  display: flex;
  background-color: #fef9ef;
  min-height: 100vh;
  height: 100%;
  padding: 10px 20px 10px 10px;
}

.cir_table_container_carriculum .cir_content_text + .cir_content_text {
  border-left: 1px solid #ebebeb !important;
}

.forward_arr {
  margin-right: 13rem;
}

.download_btn {
  width: 156px;
  height: 36px;
  background: #c6e4ce;
  border: 1px solid #eeeeee;
  border-radius: 4px;
  opacity: 0.65;
  color: black;
  font-weight: bold;
  text-align: start;
  padding: 5px 10px 0px 10px;
  margin-left: 3rem;
  /* margin-top: 0.75rem; */
  font-size: 13px;
  cursor: pointer;
}

.download_aqa_div {
  margin-right: 11rem;
  display: flex;
  align-items: center;
}

/* modal css */

.cir_close {
  margin-left: 97%;
  cursor: pointer;
}

.download_div {
  /* margin-top: 20px; */
  color: rgb(167, 159, 159);
}

@media screen and (min-width: 768px) {
  .download_aqa_div {
    display: flex;
  }

  .download_aqa_div_mobile {
    display: none;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1440px) {
  /* .cir_table_container_carriculum {
    min-width: 600px;
    width: auto;
    height: auto;
    margin-left: 10px;
    margin-right: 15px;
    border-radius: 8px 8px 0px 0px;
  } */
  .sideDiv {
    padding: 0px 10px 0px 10px !important;
  }
}

@media screen and (min-width: 1220px) and (max-width: 1440px) {
  .sideDiv {
    padding: 0px 10px 0px 20px !important;
  }
  .carriculamSideDiv {
    padding: 0px 10px 0px 10px !important;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1220px) {
  .download_btn {
    margin-left: 2rem;
    margin-top: 0.75rem;
  }

  .revesion_top_table_div {
    overflow-x: scroll;
    margin-left: 0px;
    margin-right: 0px;
  }

  .sideDiv {
    padding: 0px 10px 0px 20px !important;
  }
  .carriculamSideDiv {
    padding: 0px 10px 0px 10px !important;
  }
  .download_aqa_div {
    margin-right: 4rem;
  }

  .cir_table_container_carriculum {
    min-width: 895px;
    width: auto;
    height: auto;
    margin-right: 15px;
    margin-left: 10px;
    border-radius: 8px 8px 0px 0px;
  }

  .chaptr_no_width {
    width: 200px;
  }

  .forward_arr {
    margin-right: 2rem;
  }

  .cir_content_text {
    padding-left: 35px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .sideDiv {
    margin-left: 100px;
    padding-bottom: 50px;
    display: flex;
    background-color: #fef9ef;
    min-height: 100vh;
    height: 100%;
    padding: 0px 0px 10px 0px !important;
  }
  .carriculamSideDiv {
    padding: 0px 10px 0px 0px !important;
  }
  .download_btn {
    margin-left: 2rem;
    margin-top: 0.75rem;
  }
  .sideDiv {
    padding: 0px 20px 10px 10px !important;
  }
  .revesion_top_table_div {
    overflow-x: scroll;
  }

  .download_aqa_div {
    margin-right: 3rem;
  }

  .cir_table_container_carriculum {
    min-width: 600px;
    width: auto;
    height: auto;
    /* margin-left: 10px; */
    /* margin-right: 15px; */
    border-radius: 8px 8px 0px 0px;
  }

  .chaptr_no_width {
    width: 150px;
  }

  .cir_head_text,
  .cir_head_text_no {
    padding-left: 20px !important;
  }

  .forward_arr {
    margin-right: 2rem;
  }

  .cir_content_text {
    padding-left: 25px;
  }
}

@media screen and (max-width: 767px) {
  .download_aqa_div {
    display: none;
  }

  .sideDiv {
    margin-left: 0px;
    padding-bottom: 30px;
  }

  .revesion_top_table_div {
    overflow-x: scroll;
    margin-left: 0px;
    margin-right: 0px;
  }

  .download_aqa_div_mobile {
    display: flex;
    justify-content: space-between;
    margin-left: 19px;
  }

  .cir_table_container_carriculum {
    min-width: 700px;
    width: auto;
    height: auto;
    /* margin-left: 15px; */
    margin-right: 15px;
    border-radius: 8px 8px 0px 0px;
  }

  .chaptr_no_width {
    width: 150px;
  }

  .cir_table_container_carriculum .cir_content_text + .cir_content_text {
    border-left: 1px solid #ebebeb !important;
  }

  .cir_head_text,
  .cir_head_text_no {
    padding-left: 20px !important;
  }

  .forward_arr {
    margin-right: 2rem;
  }

  .cir_content_text {
    padding-left: 25px;
  }

  .download_div {
    display: none;
    margin-top: 20px;
    color: rgb(167, 159, 159);
  }
}

@media only screen and (min-device-width: 200px) and (max-device-width: 767px) {
  .pdf-modal {
    display: block;
  }

  .sideDiv,
  .sideDiv_header {
    display: block !important;
    /* margin: 15px !important; */
    padding: 0;
  }
}

/* <------------------------------> */
@media only screen and (min-device-width: 1024px) and (max-device-width: 1920px) {
  .sideDiv {
    padding: 0 18px !important;
  }
  .pp_table_div {
    margin-right: 0px !important;
  }
}

@media screen and (min-width: 320px) and (max-width: 374px) {
  .download_btn {
    font-size: 10px;
  }
}
