.post_model {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  color: #989898;
  font-size: 25px;
}

.score_green {
  color: #6deea5;
  margin-bottom: 100px;
  font-size: 24px;
}
.modal-content {
  border-radius: 80px;
}
.rations {
  color: #989898;
  text-align: center;
  margin: 5px;
  font-size: 24px;
}

.close_btn_self {
  margin-top: -150px;
}

.close_btn_self img {
  cursor: pointer;
}

.check_ans_body {
  text-align: center;
  font: normal normal normal 18px/25px Comic Sans MS;
}
.check_ans_btn {
  width: 196px;
  height: 50px;
  background: #8b60fd 0% 0% no-repeat padding-box;
  border-radius: 25px;
  opacity: 1;
  color: #fff;
  margin-bottom: 20px;
}
@media screen and (min-width: 375px) and (max-width: 767px) {
  /* .post_model {
      display: flex;
      justify-content: center;
      margin-bottom: 20px;
      color: #989898;
      font-size: 20px !important;
  } */
  .pp_model {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    color: #ff6700;
    font-size: 25px !important;
    text-align: center;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 768px) {
  .add_comnt_inp {
    width: 65%;
  }
  /* .close_btn_self   {
  margin-top: -170px;
    padding-left: 280px;
} */
}

/* @media only screen and (min-device-width: 768px) and (max-device-width: 1920px){
    
      .modal-body {
        height: 389;
        border-radius: 80px;
        background-color: white;
        border: 8px solid rgb(139, 96, 253);
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 50px!important; 
    
      }
      } */
