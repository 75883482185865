.rewards_sideDiv {
  margin-left: 90px;
  display: flex;
  background-color: #fef9ef;
  padding: 10px 30px 40px 2rem;
  min-height: 92vh;
}

.active_reward_head {
  font-size: 17px;
  color: #ff6700;
  font-weight: bold;
  cursor: pointer;
  /* border-bottom: 5px solid #ff6700; */
}

.head_reward {
  width: 15%;
}

.reward_head {
  font-size: 17px;
  color: #b4ad9f;
  font-weight: bold;
  cursor: pointer;
  /* border-bottom: 5px solid #40404d; */
}

.upcoming_head {
  font: normal normal bold 18px / 25px Comic Sans MS;
  color: #091243;
}

.rewards_viewall {
  font-size: 15px;
  color: #000;
  cursor: pointer;
}

.reward_box {
  width: 131px;
  height: 144px;
  padding: 5px;
  cursor: pointer;
  border: 1px solid #b498ff;
  border-radius: 11px;
}
.reward_box_background {
  position: relative;
  background: #e9dddd;
  /* opacity: 0.25; */
}

.badge_box {
  width: 131px;
  height: 144px;
  background: #e273f7;
  border-radius: 11px;
  opacity: 0.5;
  padding: 5px;
  cursor: pointer;
}

.badge_box_size {
  width: 131px;
  height: 144px;
}

.lock_icon {
  display: flex;
  justify-content: end;
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
}

.rewards_img {
  display: flex;
  justify-content: center;
}

.rewards_img img {
  height: 60px;
  width: 52px;
  margin-top: 10px;
}

.reward_seperate {
  width: 10px;
  min-height: 600px;
  background-color: white;
  border-radius: 10px;
  opacity: 0.3;
  margin-left: 35px;
  margin-top: -20px;
}

.tv_time {
  font-size: 19px;
  color: white;
  font-weight: bold;
  text-transform: uppercase;
}

.tv_div_container {
  position: relative;
}

.tv_div {
  width: 277px;
  height: 163px;
  background: #ff9d00;
  box-shadow: 0px 26px 40px #ffb10065;
  opacity: 0.42;
  filter: blur(50px);
}

.tv_div_img {
  position: absolute;
}

/* badge css starts here */

.star_reward {
  font-size: 12px;
  color: black;
  font-weight: SemiBold;
  font-family: Poppins;
}

/* rewards slider */
.reward_orange_slider {
  width: 125px;
  height: 5px;
  border-radius: 3px;
  background-color: #ff6700;
}

.reward_gray_slider {
  width: 125px;
  height: 5px;
  border-radius: 3px;
  background-color: #40404d;
}

.badges_rewards_div {
  width: 100%;
}

.green_no_data {
  width: 40%;
  font-size: 25px;
  margin-top: 3rem;
}

.show_badge_details {
  width: 40%;
}

.rewards_cards {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: repeat(1, 1fr);
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
}

.hr_rewards {
  background-color: #daccff;
  opacity: 0.6;
  height: 3px;
  width: 456px;
}

.rewards_container {
  /* display: flex; */
}

.no_reward_ava {
  font-size: 20px;
  color: white;
  margin: 2rem 0rem;
}

@media only screen and (min-width: 1024px) and (max-width: 1224px) {
  .rewards_cards {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(1, 1fr);
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
  }
  .hr_rewards {
    opacity: 0.6;
    width: 300px;
  }
  .reward_seperate {
    width: 10px;
    min-height: 600px;
    border-radius: 10px;
    margin-left: 20px;
    margin-top: -20px;
  }
  .rewards_sideDiv {
    padding: 10px 20px 10px;
  }
  .show_badge_details {
    width: 40%;
    padding: 0px 20px;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1023px) {
  .rewards_cards {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(1, 1fr);
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
  }
  .hr_rewards {
    opacity: 0.6;
    width: 100%;
  }
  .reward_seperate {
    display: none;
  }
  .rewards_sideDiv {
    padding: 10px 20px 10px;
  }
  .show_badge_details {
    width: 100%;
    padding: 0px 20px;
  }
  .rewards_container {
    display: block;
  }
  .badges_rewards_div {
    width: 100%;
  }
}

@media only screen and (min-device-width: 200px) and (max-device-width: 767px) {
  .rewards_img img {
    margin-top: 0px;
  }
  .rewards_sideDiv {
    margin-left: 0px;
    display: flex;
    padding: 10px 20px 10px;
    min-height: 92vh;
  }
  .hr_rewards {
    opacity: 0.6;
    width: 100%;
  }
  .reward_seperate {
    display: none;
  }
  .rewards_sideDiv {
    padding: 10px 20px 10px;
  }
  .show_badge_details {
    width: 100%;
    padding: 0px 20px;
  }
  .rewards_container {
    display: block;
  }
  .badges_rewards_div {
    width: 100%;
  }

  .badge_box_size {
    width: 110px;
    height: 125px;
  }

  .reward_box {
    width: 110px;
    height: 125px;
    /* border: 3px solid #ff9d00; */
    /* background: #707070; */
    /* border-radius: 11px; */
    /* opacity: 0.5; */
    /* padding: 5px; */
  }
  .badge_box {
    width: 110px;
    height: 125px;
    background: #e273f7;
    border-radius: 11px;
    opacity: 0.5;
    padding: 5px;
  }
}

@media only screen and (min-device-width: 501px) and (max-device-width: 767px) {
  .rewards_cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(1, 1fr);
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
  }
}
@media only screen and (min-device-width: 375px) and (max-device-width: 500px) {
  .rewards_cards {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(1, 1fr);
    grid-column-gap: 0.5rem;
    grid-row-gap: 1rem;
  }

  .custom-modal .modal-content {
    padding: 20px !important;
  }
}

@media only screen and (min-device-width: 200px) and (max-device-width: 374px) {
  .rewards_cards {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(1, 1fr);
    grid-column-gap: 3.5rem;
    grid-row-gap: 1rem;
  }

  .rewards_cards .rewards {
    display: flex;
    justify-content: center;
  }
  .custom-modal .modal-content {
    padding: 20px !important;
  }
}

.rewards_cards .rewards {
  display: flex;
  justify-content: center;
}

.textStyle {
  letter-spacing: var(--unnamed-character-spacing-0);
  text-align: center;
  font: normal normal normal 16px/23px Comic Sans MS;
  letter-spacing: 0px;
  color: #373737;
  opacity: 1;
}

/* CustomModal.css */
.custom-modal .modal-dialog {
  max-width: 100%;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh; /* Full viewport height */
  /* min-width: 80%; */
}
.custom-width .modal-dialog {
  min-width: 84%;
}
.custom-small-width .modal-dialog {
  min-width: 25%;
}

.custom-modal .modal-content {
  border-radius: 20px !important;
  padding: 40px;
  overflow-y: auto;
  max-height: 90vh;
}

.custom-modal .close_btn_self img {
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.drp_labour_color {
  color: #918e96;
}

.reward-desc {
  font: normal normal normal 20px / 28px Comic Sans MS;
  letter-spacing: 0px;
  color: #373737;
}

.title-name {
  font: normal normal normal 16px / 23px Comic Sans MS;
  color: #000000;
}

.pp_dropdown-content button {
  border: unset;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  width: 100%;
  text-align: left;
  border-bottom: 1px solid rgb(223, 217, 217);
  cursor: pointer;
  background-color: #fff;
}

.addCollBtn {
  background: #8b60fd 0% 0% no-repeat padding-box;
  color: #fff;
  border-radius: 5px;
  padding: 5px;
  border: 1px solid #8b60fd;
}

.noBadgeImg {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.unlocked-img {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.collection-img {
  position: absolute;
  top: 40%;
  right: 50%;
  transform: translate(50%, -50%);
}

.space-dropdown {
  margin: 0 10px;
}

@media only screen and (max-width: 562px) {
  .space-dropdown {
    margin: 10px 0px;
  }
}

.summary-btn {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
