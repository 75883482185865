.contactus_sideDiv {
  margin-left: 90px;
  padding-bottom: 25px;
  display: flex;
  background-color: white;
}

.send_msg {
  font-size: 21px;
  color: #3e4954;
  font-weight: bold;
}

.contact_us-card {
  width: 800px;
  height: 635px;
  box-shadow: 0px 14px 40px #fef9ef;
}

.contact_us_img {
  width: 183px;
  height: 181px;
  margin-top: 3rem;
}

.contact_us_inpt {
  min-width: 706px;
  height: 253px;
  background: #fef9ef;
  border-radius: 8px;
  border: none;
  color: #a9a9a9;
  padding: 15px;
  text-align: start;
  border: none;
  overflow: auto;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  resize: none;
}

.send_msg_btn {
  width: 187px;
  height: 36px;
  border: 1px solid #fafafa;
  border-radius: 49px;
  color: white;
  font-weight: 500;
  font-size: 12px;
  background-color: #ff6700;
  box-shadow: 0px 3px 6px #ff6700;
  margin-top: 1rem;
}

.send_btn_div {
  min-width: 706px;
  text-align: start;
}

/* modal css starts here */

.thanksForReach {
  font-size: 22px;
  color: #424b54;
}

.someoneFrom {
  font-size: 18px;
  color: #666666;
  width: 55%;
  text-align: center;
}

.contact_us_contentdiv {
  margin-top: -22px;
}

.contact_us_close {
  margin-top: -15px;
}

.contact_us_close {
  cursor: pointer;
}

.circle_icon {
  width: 120px;
  height: 120px;
}

.faq_right_icon {
  width: 120px;
  height: 120px;
}

/* responsive styling starts here */

@media only screen and (min-width: 1225px) {
  .contact_us_container {
    background-color: white;
    /* min-width: 1220px; */
    width: 100%;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1224px) {
  .contact_us-card {
    width: 600px;
    height: 635px;
    box-shadow: 0px 14px 40px #fef9ef;
  }
  .contact_us_inpt {
    min-width: 500px;
    height: 253px;
    padding: 15px;
  }
  .send_btn_div {
    min-width: 500px;
    text-align: start;
  }
  .contact_us_container {
    /* min-width: 900px; */
    width: 100%;
  }
  .faq_right_icon {
    width: 90px;
    height: 90px;
  }
  .someoneFrom {
    font-size: 18px;
    color: #666666;
    width: 84%;
    text-align: center;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1023px) {
  .contactus_sideDiv {
    display: block;
  }
  .contact_us-card {
    width: 600px;
    height: 615px;
    box-shadow: 0px 14px 40px #fef9ef;
  }
  .contact_us_inpt {
    min-width: 500px;
    height: 240px;
    padding: 15px;
  }
  .send_btn_div {
    min-width: 500px;
    text-align: start;
  }
  .contact_us_container {
    width: 100%;
  }
  .faq_right_icon {
    width: 90px;
    height: 90px;
  }
  .someoneFrom {
    font-size: 18px;
    color: #666666;
    width: 84%;
    text-align: center;
  }
}

@media only screen and (min-device-width: 200px) and (max-device-width: 767px) {
  .contactus_sideDiv {
    display: block;
    margin-left: 0px;
    margin: 15px 15px 0px 15px;
    padding: 0px;
    height: 85vh;
  }
  .contact_us-card {
    width: 100%;
    height: 600px;
    box-shadow: 0px 14px 40px #fef9ef;
    margin-bottom: 0.5rem;
  }
  .contact_us_img {
    width: 200px;
    height: 200px;
    margin-top: 0.5rem;
  }
  .send_msg_btn {
    width: 150px;
    height: 36px;
    font-size: 12px;
    box-shadow: 0px 3px 6px #ff6700;
    margin-top: 0.5rem;
  }
  .someoneFrom {
    font-size: 18px;
    color: #666666;
    width: 90%;
    text-align: center;
  }
  .contact_us_close {
    margin-top: 0px;
  }
  .circle_icon {
    width: 60px;
    height: 60px;
  }
  .faq_right_icon {
    width: 70px;
    height: 70px;
  }
}

@media only screen and (min-device-width: 425px) and (max-device-width: 767px) {
  .contact_us_inpt {
    min-width: 350px;
    height: 253px;
    padding: 15px;
  }
  .send_btn_div {
    min-width: 280px;
    text-align: center;
  }
  .contact_us_container {
    width: 100%;
  }
}
@media only screen and (min-device-width: 375px) and (max-device-width: 424px) {
  .contact_us_inpt {
    min-width: 300px;
    height: 253px;
    padding: 15px;
  }
  .send_btn_div {
    min-width: 300px;
    text-align: center;
  }
  .contact_us_container {
    width: 100%;
  }
}

@media only screen and (min-device-width: 200px) and (max-device-width: 374px) {
  .contact_us_inpt {
    min-width: 250px;
    height: 253px;
    padding: 15px;
  }
  .send_btn_div {
    min-width: 250px;
    text-align: center;
  }
  .contact_us_container {
    width: 100%;
  }
}
