.pp_test_container {
  width: 100vw;
  min-height: 100vh;
  background-color: #fef9ef;
  padding: 3rem 3rem 1rem 3rem;
  overflow: hidden;
}

.pp_timer {
  width: 111px;
  height: 47px;
  border-radius: 21px;
  background-color: #8b60fd;
  color: white;
  padding: 10px;
  font-weight: bold;
  font-size: 20px;
}

.pp_close {
  width: 155px;
  height: 61px;
  background: #8b60fd;
  color: white;
  padding: 10px;
  font-weight: bold;
  font-size: 22px;
  cursor: pointer;
  border-radius: 30px;
}
.pp_close.new-btn {
  height: auto !important;
}

.pp_index {
  width: 41px;
  height: 41px;
  border: 3px solid #4b4b4b;
  opacity: 0.12;
  color: #4b4b4b;
  border-radius: 50%;
  font-size: 20px;
  padding: 2px;
  font-weight: bold;
}

.pp_sub_index {
  width: 41px;
  height: 41px;
  background: #ffffff;
  border: 1px solid #daccff;
  border-radius: 50%;
  font-size: 22px;
  padding: 2px;
  font-weight: bold;
  color: #daccff;
}

.pp_index_active {
  width: 41px;
  height: 41px;
  border: 3px solid #daccff;
  border-radius: 50%;
  font-size: 20px;
  padding: 2px;
  font-weight: bold;
  color: #daccff;
}

.pp_test_white_div {
  width: 1220px;
  min-height: 520px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 20px;
  border-radius: 20px;
}
.postDiv {
  /* padding-top: 18px; */
}
.pp_test_white_div img {
  width: 100%;
  height: 100%;
  height: 100%;
  object-fit: contain;
}

.pp_year {
  font-size: 20px;
  text-align: start;
  margin-left: 7rem;
  margin-top: 1rem;
}

.pp_back_btn {
  width: 130px;
  height: 51px;
  background: #8b60fd;
  border-radius: 6px;
  font-size: 24px;
  color: white;
  font-weight: bold;
  cursor: pointer;
  padding: 7px;
}

.pp_auto_check_btn {
  width: 321px;
  height: 51px;
  background: #eeeeee;
  border-radius: 6px;
  color: #918e96;
  font-size: 24px;
  font-weight: bold;
  cursor: pointer;
  padding: 7px;
}

.pp_test_btn {
  /* margin-top: -50px; */
}

.pp_model {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  color: #ff6700;
  font-size: 35px;
  text-align: center;
}
.pp_model_title {
  font-size: 20px !important;
}

.pp_close_icon {
  height: 35px;
  width: 35px;
}

/* Media query for screens with a maximum width of 425px */

@media only screen and (min-width: 1024px) and (max-width: 1220px) {
  .pp_test_white_div {
    width: 900px;
    min-height: 520px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 20px;
    border-radius: 20px;
  }

  .pp_test_btn {
    /* margin-left: 50px; */
  }
  .pp_year {
    margin-left: 10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .pp_test_white_div {
    width: 600px;
    min-height: 500px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 20px;
    border-radius: 20px;
  }
  .num_pagination {
    overflow-x: scroll !important;
  }
  .pp_auto_check_btn {
    width: 250px;
  }
  .pp_back_btn {
    width: 90px;
  }
  .pp_year {
    margin-left: 35px;
  }
  .pp_test_btn {
    /* margin-left: 130px; */
  }
}

@media only screen and (min-width: 424px) and (max-width: 767px) {
  .pp_test_white_div {
    width: 350px;
    min-height: 400px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 20px;
    border-radius: 20px;
  }

  .pp_test_btn {
    /* margin-left: 120px; */
  }

  .pp_test_container {
    /* padding: 1rem; */
    min-height: 100vh;
    height: 100%;
  }

  .pp_close {
    width: 85px;
    height: 42px;
    background: #8b60fd;
    color: white;
    padding: 7px;
    font-weight: bold;
    font-size: 16px;
    cursor: pointer;
    border-radius: 30px;
  }

  .pp_timer {
    width: 88px;
    height: 40px;
    border-radius: 21px;
    background-color: #8b60fd;
    color: white;
    padding: 8px;
    font-weight: bold;
    font-size: 16px;
  }

  .pp_back_btn {
    width: 80px;
    height: 35px;
    background: #8b60fd;
    border-radius: 6px;
    font-size: 14px;
    color: white;
    font-weight: bold;
    cursor: pointer;
    padding: 7px;
  }

  .pp_auto_check_btn {
    width: 120px;
    height: 36px;
    background: #eeeeee;
    border-radius: 6px;
    color: #918e96;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    padding: 7px;
    white-space: nowrap;
  }

  .pp_year {
    font-size: 15px;
    text-align: start;
    margin-top: 0.5rem;
    margin-left: 0px;
  }

  .pp_pagination {
    display: none;
  }
}

@media only screen and (min-width: 320px) and (max-width: 424px) {
  .pp_test_white_div {
    width: 250px;
    min-height: 300px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 20px;
    border-radius: 20px;
  }

  .pp_test_btn {
    /* margin-left: 100px; */
  }

  .pp_test_container {
    padding: 1rem;
  }

  .pp_test_container {
    padding: 1rem;
  }

  .pp_close {
    width: 85px;
    height: 42px;
    background: #8b60fd;
    color: white;
    padding: 7px;
    font-weight: bold;
    font-size: 16px;
    cursor: pointer;
    border-radius: 30px;
  }

  .pp_timer {
    width: 88px;
    height: 40px;
    border-radius: 21px;
    background-color: #8b60fd;
    color: white;
    padding: 8px;
    font-weight: bold;
    font-size: 16px;
  }

  .pp_back_btn {
    width: 80px;
    height: 35px;
    background: #8b60fd;
    border-radius: 6px;
    font-size: 14px;
    color: white;
    font-weight: bold;
    cursor: pointer;
    padding: 7px;
  }

  .pp_auto_check_btn {
    width: 120px;
    height: 36px;
    background: #eeeeee;
    border-radius: 6px;
    color: #918e96;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    padding: 7px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: clip;
  }

  .pp_year {
    font-size: 15px;
    text-align: start;
    margin-top: 0.5rem;
    margin-left: 30px;
  }

  .pp_pagination {
    display: none;
  }
}

@media only screen and (min-width: 1440px) and (max-width: 1532px) {
  .pp_year {
    margin-left: 4rem;
  }
}
/* @media only screen and (min-width: 1536px) and (max-width: 1920px) {
  .pp_year{
    margin-left: 4rem;
  }
} */
.custom-checkbox {
  width: 20px; /* Adjust the width */
  height: 20px; /* Adjust the height */
  transform: scale(1.5); /* Adjust the scale */
  cursor: pointer;
  accent-color: #95c136;
}
