.practice_head {
  width: 100%;
  color: #8b60fd;
  font-size: 20px;
}

.container_practicetest {
  display: flex;
  flex-wrap: wrap;
  background-color: #fef9ef;
}

.practice_table {
  width: 100%;
  text-align: start;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 20px;
}
.practice_column {
  height: 80px;
  border-bottom: 1px solid #ede6e6;
}
.Start_btn {
  /* background-color: #8b60fdbe; */
  /* color: #000000; */
  border: none;
  border-radius: 14px;
  width: 128px;
  height: 41px;
  font-size: 18px;
}

.practice_row_td {
  font-size: 18px;
  font-weight: 500;
  text-align: center;
}

.white_space {
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  box-shadow: 0px 0px 6px #00000029;
}

.assigned {
  cursor: pointer;
  gap: 10px;
  flex-wrap: wrap;
}

.tgl_btn {
  margin: 10px;
  width: 201px;
  height: 48px;
  margin-right: 50px;
}

.drp_width {
  width: 350px;
  /* margin-right: 2rem; */
}

.purple_line_practice_test {
  margin-left: 0.5rem !important;
  background: #facd51 0% 0% no-repeat padding-box;
  border-radius: 4px;
  padding: 5px 32px;
  color: #000000;
}
.inactive_practice_test {
  margin-left: 0.5rem !important;
  /* background: #facd51 0% 0% no-repeat padding-box; */
  border-radius: 4px;
  padding: 5px 32px;
  color: #000000;
}

.les_activity {
  min-width: 450px;
  /* padding-left: 12px; */
}

.chptr_prac {
  min-width: 280px;
  text-align: center;
}

.mobile_name {
  display: flex;
  justify-content: center;
  display: none;
}

@media only screen and (max-width: 1220px) {
  .practice_table {
    width: 900px;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 20px;
    overflow-x: scroll;
    text-align: start;
  }
  .container_practicetest {
    display: block;
    overflow-x: scroll;
  }
  .drp_width {
    width: 500px;
  }
}
@media only screen and (max-width: 1023px) {
  .drp_width {
    width: 657px;
  }
}

@media only screen and (max-width: 767px) {
  .practice_table {
    width: 767px;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 20px;
    overflow-x: scroll;
    text-align: start;
  }
  .container_practicetest {
    display: block;
    overflow-x: scroll;
  }
  .Start_btn {
    display: flex;
    justify-content: center;
    width: 80%;
  }
  .white_space {
    flex-wrap: wrap;
    justify-content: center;
  }
  .drp_width {
    width: 50%;
    margin-right: 0.5rem;
  }
  .mobile_name {
    display: block;
  }
  .desktop_name {
    display: none;
  }
}

@media only screen and (max-width: 500px) {
  .drp_width {
    width: 90%;
  }
}
.imgMange {
  position: relative;
  left: 400px;
}

.activity_header {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.setting_btn {
  display: flex;
  text-align: center;
  margin: 0 30px;
  font: normal normal normal 16px / 23px Comic Sans MS;
  color: #8b60fd;
  align-items: center;
  cursor: pointer;
}

.btn-dropdown {
  border: unset;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  width: 100%;
  text-align: left;
  border-bottom: 1px solid rgb(223, 217, 217);
  cursor: pointer;
  background-color: #fff;
}
