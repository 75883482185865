.cui-toolbar-button-right {
  margin-left: 4px !important;
  margin-right: 0px !important;
  vertical-align: middle !important;
  display: none !important;
}
.cui-toolbar-buttondock.alignright {
  right: 0px !important;
  position: absolute !important;
  display: none !important;
}

.cui-statusbar .cui-ctl {
  display: none !important;
}

.cui-statusbar {
  height: 40px;
  background-color: white !important;
  border-top: 1px solid #555555;
}

.custom-iframe {
  /* Add your custom styles here */
}

/* Target header and footer elements within the iframe content */
.custom-iframe .header {
  display: none;
}

.custom-iframe .footer #ChromelessStatusBar.RightDock {
  display: none !important;
}
