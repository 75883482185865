.heading {
  font-size: 22px;
}

.answer {
  font-size: 16px;
  color: #918e96;
  text-align: left;
}

.question {
  font-size: 21px;
  color: black;
  text-align: left;
}

.back_dot {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background-color: #000;

  color: black;
}

.text_student {
  color: #ff6700;
  font-size: 15px;
  text-align: left;
}
.text_student > p {
  color: #424b54;
  margin-right: 14px;
  font-size: 15px;
}

.comment_icon {
  color: #918e96;
  text-align: center;
  font-size: 16px;
  cursor: pointer;
  margin-right: 3rem;
  display: flex;
}

.c_count {
  font-size: 17px;
  font-weight: bold;
  color: #918e96;
}
.up_arr {
  margin-right: 2rem;
  cursor: pointer;
}

/* Ayushi css */

.error_qna {
  font-size: 14px;
  color: red;
}
.info_qna {
  margin-top: 1px;
  margin-right: 2px;
}

.back_btn_width {
  margin-left: 1.5rem;
}

.parent_QA {
  width: 98%;
  height: auto;
  background-color: #fafafa;
  margin-left: 1rem;
  margin-right: 1rem;
  padding: 20px;
}

.all_question {
  text-align: left;
  font-weight: 600;
  font-size: 16px;
}

.person_icon {
  border: 1px solid black;
  background-color: #8b60fd;
  color: white;
  border-radius: 50%;
  height: 32px;
  width: 32px;
}

.profile_student {
  width: 10%;
}

.question_div {
  width: 65%;
}

.questions_details {
  border-top: 1px solid #ebebeb;
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
}

.QA_heading {
  display: flex;
  justify-content: space-between;
  padding: 1rem !important;
}
.QA_btn {
  display: flex;
}

.comment_icon_2 {
  color: #918e96;
  text-align: center;
  font-size: 16px;
  cursor: pointer;
  display: flex;
}

.QnA_bg {
  min-height: 100vh;
  height: 100%;
  background-color: #fef9ef;
  padding-bottom: 10px;
}

/* comment stylying */

.comment_div {
  background-color: #fef9ef;
  border: none;
  max-width: 1138px;
  width: 100%;
  height: auto;
  padding: 20px;
}

.purple_comnt {
  width: 536px;
  height: auto;
  background: #8b60fd5a;
  border-radius: 10px;
  padding: 10px;
}
.comnt_data {
  font-size: 16px;
  text-align: left;
  width: 90%;
}

.yellow_comment {
  width: 570px;
  height: auto;
  background: #f7c7004d;
  border-radius: 10px;
  text-align: right;
  padding: 20px;
}

.yelloW_person_icon {
  border: 1px solid black;
  background-color: #f7c7004d;
  color: white;
  border-radius: 50%;
  height: 29px;
  width: 29px;
}

.add_comnt_inp {
  width: 75%;
  height: 55px;
  background: white;
  border: 1px solid #8b60fd;
  border-radius: 10px;
  padding: 5px;
}
.share {
  width: 10%;
}

.share img {
  cursor: pointer;
}

/* post css starts here */

.post_head {
  text-align: center;
  font-weight: 600;
  font-size: 18px;
  margin-top: -4%;
}

.textarea_post {
  width: 100%;
  height: 400px;
  padding: 12px 20px;
  box-sizing: border-box;
  border: 2px solid #8b60fd;
  border-radius: 6px;
  font-size: 16px;
  resize: none;
}

.textarea_post:enabled:focus {
  outline: 0 none;
  outline-offset: 0;
  border-color: #8b60fd;
}

.add_comnt_inp:enabled:focus {
  outline: 0 none;
  outline-offset: 0;
  border-color: #8b60fd;
}

.mobile_post {
  display: none;
}

/* media query starts here */

@media only screen and (min-width: 1201px) {
  .comment_icon_2 {
    display: none !important;
  }
  .text_student {
    color: #ff6700;
    font-size: 15px;
    text-align: left;
    margin-left: 5rem;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1200px) {
  .question_div {
    width: 70%;
  }
  .comment_icon_2 {
    display: none !important;
  }
  .text_student {
    color: #ff6700;
    font-size: 15px;
    text-align: left;
    margin-left: 5rem;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .question_div {
    width: 75%;
  }
  .comment_icon_2 {
    display: none !important;
  }
  .text_student {
    color: #ff6700;
    font-size: 15px;
    text-align: left;
    margin-left: 3rem;
  }
  .postModal  {
    width: 600px!important;
    margin-left: -20px!important;
  }

  .parent_QA{
    width: 95%;
  }
}

@media only screen and (min-width: 375px) and (max-width: 767px) {
  .questions_details {
    display: block;
  }
  .comment_icon {
    display: none !important;
  }
  .comment_icon_2 {
    display: flex;
  }
  .question_div {
    width: 80%;
    margin-left: 10px;
  }
  .QA_heading {
    display: block;
    padding-right: 10px!important;
  }
  .parent_QA{
    width: 91%;
  }
  .QA_btn {
    display: flex;  
    justify-content: space-between;
    margin-top: 20px;
  }
  .post_head {
    margin-top: -6%;
  }
  .textarea_post {
    height: 300px;
  }
  .text_student {
    color: #ff6700;
    font-size: 15px;
    text-align: left;
    margin-left: 10px;
  }
  .up_arr {
    display: none;
  }
  .add_cmnt {
    justify-content: space-between;
  }

}

@media only screen and (min-width: 284px) and (max-width: 374px) {
  .questions_details {
    display: block;
  }
  .comment_icon {
    display: none !important;
  }
  .comment_icon_2 {
    display: flex;
  }
  .question_div {
    width: 80%;
    margin-left: 10px;
  }
  .QA_heading {
    display: block;
    padding-right: 10px!important;
  }
  .parent_QA{
    width: 91%;
  }

  .QA_btn {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  .post_head {
    margin-top: -6%;
  }
  .textarea_post {
    height: 300px;
  }
  .mobile_post {
    display: block;
  }
  .desktop_post {
    display: none;
  }
  .text_student {
    color: #ff6700;
    font-size: 15px;
    text-align: left;
    margin-left: 0px;
  }
  .up_arr {
    display: none;
  }
  .add_cmnt {
    justify-content: space-between;
  }
}

/* <--------------------------------> */
@media only screen and (min-device-width: 1024px) and (max-device-width: 1920px){
.postModal  {
  width: 700px!important;
}
.parent_QA{
  width: 97%;

}
.QA_heading{
  padding-right:18px!important;
}
}

@media only screen and (min-device-width: 320px) and (max-device-width: 374px){
  .add_comnt_inp{
    width: 65%;
  } 
  }