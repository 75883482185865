.comic_font {
  font-family: Comic Sans MS !important;
}

.profile_container {
  margin-bottom: 3px;
  min-height: 91vh!important;
}

.parent_boxDiv {
  background: #ffffff;
  border: 2px solid #8b60fd;
  width: 1000px;
  min-height: 443px;
  padding: 0.5rem 3rem;
  margin-left: 1rem;
}

.container_profile {
  display: flex;
  justify-content: space-between;
  /* height: 88vh; */
  margin-top: 4rem;
  width: 100%;
}

.KK {
  height: 107px;
  width: 107px;
  border-radius: 50%;
  position: relative;
  border: 3px solid #8b60fd;
  margin: 0rem 2rem;
}

.box {
  width: 429px;
  border: none;
  /* border: 3px solid #8B60FD; */
}

h4 {
  margin-right: 300px;
}
.profile_data_div {
  padding: 10px 20px;
  border: 1px solid #8b60fd53;
  border-radius: 5px;
  margin: 5px;
  height: 52px;
}

.gifts {
  height: 150px;
  display: flex;
  margin-top: -22px;
  margin-left: 3.9rem;
}

.gift-price {
  position: absolute;
  right: 120px;
  top: 220px;
  width: 179px;
  height: 168px;
}
/* this score card section you can after this  */
.boxs {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 600px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 1px solid rgb(0, 0, 0);
}
.score_sections {
  display: flex;
  justify-content: center;
  background-color: rgb(236, 236, 238);
  height: 40px;
  padding: 10px;
}

.profile_key {
  display: flex;
  justify-content: flex-start;
  width: 50%;
  font-size: 17px;
  color: #111111;
}

.profile_value {
  display: flex;
  justify-content: flex-start;
  width: 80%;
  font-size: 17px;
  color: #111111;
  padding: 0px 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.profile_box_div {
  display: flex;
  justify-content: center;
}


@media only screen and (min-device-width: 1024px) and (max-device-width: 1200px) {
  .parent_boxDiv {
    width: 700px;
    min-height: 443px;
    padding: 0.5rem 2rem;
    margin-left: 1rem;
  }
  .KK {
    height: 107px;
    width: 107px;
    position: relative;
    border: 3px solid #8b60fd;
    margin: 0rem;
  }
  .gifts {
    height: 150px;
    display: flex;
    margin-top: -22px;
    margin-left: 0px;
  }
  .gift-price {
    position: absolute;
    right: 50px;
    top: 220px;
  }
  .profile_value {
    width: 60%;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1023px) {
  .parent_boxDiv {
    width: 100%;
    min-height: 443px;
    height: 100%;
    padding: 0.5rem 2rem;
    margin-left: 0rem;
    /* display: flex;
    justify-content: center; */
    margin-top: 10px;
  }
  .KK {
    height: 107px;
    width: 107px;
    position: relative;
    border: 3px solid #8b60fd;
    margin: 0rem;
  }
  .gifts {
    height: 150px;
    display: flex;
    margin-top: -22px;
    margin-left: 0px;
  }
  .gift-price {
    position: absolute;
    right: 50px;
    top: 220px;
  }
  .profile_box_div {
    display: block;
  }
  .container_profile {
    display: block;
    margin-top: 2rem;
    width: 100%;
  }
  .gift-price {
    display: none;
  }
  .box {
    width: 550px;
    border: none;
  }
  .profile_key {
    width: 40%;
  }
  .profile_value {
    width: 60%;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 374px) {
  .parent_boxDiv {
    width: 98%;
    max-width: 300px;
    min-height: 443px;
    height: 100%;
    padding: 0.5rem;
    margin-left: 0rem;
    /* display: flex;
    justify-content: center; */
    margin-top: 10px;
  }
  .box {
    width: 100%;
    border: none;
    max-width: 280px;
  }
}
@media only screen and (min-device-width: 375px) and (max-device-width: 424px) {
  .parent_boxDiv {
    width: 98%;
    max-width: 350px;
    min-height: 443px;
    height: 100%;
    padding: 0.5rem;
    margin-left: 0rem;
    /* display: flex;
    justify-content: center; */
    margin-top: 10px;
  }
  .box {
    width: 100%;
    border: none;
    max-width: 350px;
  }
}
@media only screen and (min-device-width: 425px) and (max-device-width: 767px) {
  .parent_boxDiv {
    width: 98%;
    max-width: 408px;
    min-height: 443px;
    height: 100%;
    padding: 0.5rem 2rem;
    margin-left: 0rem;
    /* display: flex;
    justify-content: center; */
    margin-top: 10px;
  }
  .box {
    width: 100%;
    border: none;
    max-width: 380px;
  }
}
@media only screen and (min-device-width: 320px) and (max-device-width: 767px) {
  .KK {
    height: 107px;
    width: 107px;
    position: relative;
    border: 3px solid #8b60fd;
    margin: 0rem;
  }
  .gifts {
    height: 150px;
    display: flex;
    margin-top: -22px;
    margin-left: 0px;
  }
  .gift-price {
    position: absolute;
    right: 50px;
    top: 220px;
  }
  .profile_box_div {
    display: block;
  }
  .container_profile {
    display: block;
    margin-top: 2rem;
    width: 100%;
    margin-right: -1.5rem;
  }
  .gift-price {
    display: none;
  }

  .profile_key {
    width: 40%;
    font-size: 14px;
  }
  .profile_value {
    width: 60%;
    font-size: 14px;
  }
}


/* @media only screen and (min-device-width: 1024px) and (max-device-width: 1920px) {
  .sideDiv{
    height: 100vh!important;
    overflow-y:hidden!important ;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 767px) {
.sideDiv{
  max-height: 100vh;
  min-height: 100vh;
  height: 1500px;
}
} */