.score_head {
  color: #9c9c9c;
  font-size: 18px;
  margin: 20px;
  text-align: start;
}

.applyBtn {
  margin-top: 100px;
  margin-right: 20px;
  display: flex;
  justify-content: end;
}
.lessionName{
  text-align: left ;
}
.score_div {
  min-height: 565px;
  height: 100%;
  border: none;
  width: 70%;
  border-radius: 20px;
  background-color: white;
  margin-right: 1rem;
  margin-left: 1rem;
  display: flex;
  justify-content: center;
}

.apply_div {
  min-height: 80vh;
  height: auto;
  border: none;
  width: 70%;
  border-radius: 20px;
  background-color: white;
  margin-right: 1rem;
  margin-left: 1rem;
}

.select_div {
  min-height: 565px;
  height: 100%;
  width: 25%;
  border: none;
  border-radius: 20px;
  background-color: white;
}
.select_apply_div {
  min-height: 565px;
  height: auto;
  width: 25%;
  border: none;
  border-radius: 20px;
  background-color: white;
}

.score_check {
  font-size: 20px;
  font-weight: 600;
}

.score_lorem {
  font-size: 12px;
  color: #a2a2a2;
}

.view_inp {
  width: 80%;
  text-align: left;
  border: 2px solid #ff7213;
  border-radius: 10px;
  min-height: 40px;
  background-color: #fef9ef;
  padding-left: 10px;
}

.accordion-button:not(.collapsed) {
  color: #ff7213;
  background-color: #fef9ef;
  box-shadow: 0px 0px 0px 0px;
  height: 30px;
  padding: px 0px 0px 20px;
  font-weight: 500;
  font-size: 16px;
}

.drp_optn {
  border: none;
  border-radius: 20px;
  height: 100px;
  text-align: left;
}

accordion-button:not(.collapsed)::after {
  background-image: var(--bs-accordion-btn-active-icon);

  transform: none !important;
}

.accordion-button {
  background-color: #fef9ef;
  font-weight: 600;
  font-size: 20px;
}

.dropdown_avatar {
  position: relative;
  display: inline-block;
}

.dropdown_avatar-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  width: 80%;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border: none;
  border-radius: 10px;
  max-height: 200px;
  overflow-y: scroll;
  cursor: pointer;
}

.dropdown_avatar a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  width: 100%;
  text-align: left;
  border-bottom: 1px solid rgb(223, 217, 217);
}

.dropdown_avatar a:hover {
  background-color: #f1f1f1;
}

.not-allowed{
  cursor: not-allowed !important;
}

.dropdown_avatar-content {
  display: block;
}

.show {
  display: block;
}
.score_Top_div {
  margin-left: 100px;
  padding-bottom: 30px;
}

.lesson_header {
  text-align: start;
  padding: 20px 30px;
  width: 100%;
  background-color: #eef1f5;
  font-size: 24px;
  border: none;
  box-shadow: 0px 3px 6px #00000029;
}
.chap_name {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  font-size: 22px;
  color: #918e96;
  padding: 20px 40px;
}
.chapter_box {
  margin-top: 60px;
}

.chap_total {
  display: flex;
  justify-content: space-between;
  font-size: 22px;
  color: #8b60fd;
  padding: 20px 40px;
}

.dropbtn_avatar {
  width: 90%;
  text-align: left;
  border: 2px solid #ff7213;
  border-radius: 10px;
  min-height: 60px;
  background-color: #fef9ef;
  padding-left: 10px;
  color: #08134a;
  font-size: 18px;
  font-weight: 600;
}

/* responsive code starts here */

@media only screen and (min-device-width: 1024px) and (max-device-width: 1220px) {
  .score_div {
    min-height: 565px;
    height: 100%;
    border: none;
    width: 65%;
    border-radius: 20px;
    background-color: white;
    margin-right: 1rem;
    margin-left: 1rem;
    display: flex;
    justify-content: center;
  }
  .select_div {
    min-height: 565px;
    height: 100%;
    width: 30%;
    border: none;
    border-radius: 20px;
    background-color: white;
  }
  .apply_div {
    width: 65%;
    margin-right: 1rem;
    margin-left: 1rem;
  }
  .select_apply_div {
    min-height: 565px;
    height: auto;
    width: 30%;
    border: none;
    border-radius: 20px;
    background-color: white;
  }
  .lesson_header {
    padding: 10px 20px;
    font-size: 20px;
  }
  .chap_name {
    font-size: 18px;
    padding: 18px 30px;
  }
  .chap_total {
    font-size: 18px;
    padding: 18px 30px;
  }
  .dropbtn_avatar{
    font-size: 16px;
  }
}

@media only screen and (min-device-width: 1023px) {
  .score_content {
    display: flex;
  }
  .score_show {
    margin-top: 10%;
    width: 499px;
  }

  .applyBtn {
    margin-top: 120px;
    margin-right: 20px;
    display: flex;
    justify-content: end;
  }
  .chapter_box {
    margin-top: 60px;
  }
  .dropdown_avatar-content {
    width: 90%;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1023px) {
  .score_content {
    display: flex;
    flex-direction: column-reverse;
    gap: 40px;  }
  .score_div {
    min-height: 300px;
    height: 100%;
    border: none;
    width: 95%;
    border-radius: 20px;
    background-color: white;
  }
  .apply_div {
    height: auto;
    border: none;
    width: 95%;
    border-radius: 20px;
    background-color: white;
  }
  .score_show {
    margin-top: 1%;
  }
  .scoreImg {
    margin-top: 30px;
  }
  .select_div,
  .select_apply_div {
    margin-top: 20px;
    height: auto;
    width: 95%;
    padding: 40px;
    border: none;
    border-radius: 20px;
    background-color: white;
    margin-left: 20px;
  }
  .dropbtn_avatar {
    margin-left: 0px;
    width: 90%;
    height: 60px;
    min-height: 60px;
  }
  .applyBtn {
    margin-top: 50px;
    /* margin-right: 20px; */
    display: flex;
    justify-content: center;
  }
  .chapter_box {
    margin-top: 40px;
  }

  .dropdown_avatar-content {
    width: 90%;
  }
}
@media only screen and (min-device-width: 200px) and (max-device-width: 767px) {
  .score_content {
    display: flex;
    flex-direction: column-reverse;
    gap: 40px;  
  }
  .lessionName{
    text-align: left ;
    /* max-width: 180px; */
  }
  .dropbtn_avatar {
    font-size: 14px;
    }
  .score_div {
    min-height: 300px;
    height: auto;
    padding-bottom: 10px;
    border: none;
    width: 95%;
    border-radius: 20px;
    background-color: white;
    margin-right: 0rem;
    margin-left: 10px;
  }
  .apply_div {
    min-height: 300px;
    height: 100%;
    border: none;
    width: 95%;
    border-radius: 20px;
    background-color: white;
    margin-right: 0rem;
    margin-left: 10px;
  }
  .score_show {
    margin-top: 1%;
  }
  .scoreImg {
    margin-top: 30px;
  }
  .select_div,
  .select_apply_div {
    margin-top: 20px;
    min-height: 400px;
    height: 100%;
    width: 95%;
    padding: 10px;
    border: none;
    border-radius: 20px;
    background-color: white;
    margin-left: 10px;
    padding-bottom: 20px;
  }
  .dropbtn_avatar {
    margin-left: 0px;
    width: 50%;
    min-height: 40px;
  }
  .applyBtn {
    margin-top: 50px;
    /* margin-right: 20px; */
    display: flex;
    justify-content: center;
  }
  .score_Top_div {
    margin-left: 0px;
  }
  .chapter_box {
    margin-top: 20px;
  }
  .chap_name {
    font-size: 15px;
    padding: 18px 30px;
  }
  .chap_total {
    font-size: 14px;
    padding: 18px 30px;
  }
  .dropdown_avatar-content {
    width: 50%;
  }
  .score_check {
    font-size: 18px;
    font-weight: 600;
    width: 270px;
  }
  .check_score {
    display: flex;
    justify-content: center;
  }
  .lesson_header {
    padding: 10px 20px;
    font-size: 18px;
  }
}

@media only screen and (min-device-width: 200px) and (max-device-width: 600px) {
  .dropbtn_avatar {
    margin-left: 0px;
    width: 80%;
    min-height: 40px;
  }
  .lessionName{
    text-align: left ;
    max-width: 197px;
  }
  .dropdown_avatar-content {
    width: 80%;
  }
}

@media only screen and (min-device-width: 1221px) and (max-device-width: 1920px) {

.dropbtn_avatar {
font-size: 16px;
}
}