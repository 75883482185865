.past_paper {
  width: 100%;
  min-height: 100vh;
  height: 100%;
  background-color: #fef9ef;
}
/* .start_tests {
  background-color: #fef9ef;
} */

.start-items {
  flex: 1;
  border: 1px solid black;
  padding: 10px;
}



.sample_test {
  max-width: 95vw;
  min-width: 83vw;
  width: 100%;
  height: 194px;
  color: #ffffff;
  text-align: left;
  background-color: #8b60fd;
  border-radius: 20px 0px 0px 20px;
  padding: 60px 0px 0px 25px;
  margin-left: 20px;
}

.sample_test div {
  font-size: 42px;
  font-weight: 550;
}

.che {
  background-color: #8b60fd;
  color: #f9f9f9;
  width: 200px;
  height: 139px;
  border-radius: 0px 30px 0px 0px;
  margin-bottom: 10px;
  padding: 30px 0px 0px 0px;
}

.che h3 {
  font-size: 60px;
  font-weight: 600;
}

.key_stage {
  background-color: #8b60fd;
  color: #f9f9f9;
  width: 200px;
  height: 136px;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 10px;
  padding: 10px 0px 0px 0px;
}

.key_stage .key_name {
  font-size: 29px;
  font-weight: 550;
}

.key_stage .count {
  font-size: 49px;
  font-weight: bold;
}

.key_nxt {
  background-color: #8b60fd;
  color: #f9f9f9;
  width: 200px;
  height: 408px;
  border-radius: 0px 20px 20px 0px;
  margin-bottom: 10px;
  padding: 20px 0px 0px 20px;
}

.key_2 {
  font-size: 129px;
  transform: matrix(0, -1, 1, 0, 0, 0);
  margin-top: 9.5rem;
}
.demTable {
  border: 2px solid black;
  border-collapse: collapse;
  padding: 5px;
  width: 800px;
  min-height: 400px;
  height: auto;
}

.demTable td {
  border: 2px solid black;
  text-align: center;
  padding: 10px;
  background: #ffffff;
  color: #000000;
}

.test_A {
  font-size: 60px;
  font-weight: bold;
  text-align: start;
}

.table_container {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}
.closese {
  background-color: #8b60fd;
  width: 155px;
  height: auto !important;
  color: #ebeaea;
  border: none;
  border-radius: 30px !important;
  font-size: 22px;
  margin: 2rem ;
  font-weight: bold;
  padding: 10px;
  cursor: pointer;
  text-align: center;
  align-self: center;

}
.btn_in {
  width: 110px;
  height: auto !important;
  background-color: #8b60fd;
  color: #ebeaea;
  border: none;
  font-size: 20px;
  border-radius: 10px !important;
  margin-right: 2rem;
  justify-content: center;
  display: flex;
  font-weight: bold;
  font-size: 24px;
  margin-top: 2rem;
  padding: 5px;
  cursor: pointer;
}

.table_div {
  background-color: white;
  height: auto;
  width: 100%;
  padding: 30px;
}

.cover_image_test {
  max-height: 495px;
  max-width: 80vw;
}

@media only screen and (min-width: 1220px) {
  .cover_image_test {
    max-height: 495px;
    max-width: 80vw;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1219px) {
  .sample_test {
    max-width: 85vw;
    min-width: 75vw;
    width: 100%;
    height: 150px;
    border-radius: 20px 0px 0px 20px;
    padding: 40px 0px 0px 25px;
    margin-left: 20px;
  }
  .cover_image_test {
    max-height: 450px;
    max-width: 70vw;
  }
  .demTable {
    padding: 5px;
    width: 600px;
    min-height: 300px;
    height: auto;
  }
  .test_A {
    font-size: 40px;
  }
  .btn_in {
    width: 80px;
    height: 40px;
    font-size: 18px;
    border-radius: 10px;
    margin-right: 1rem;
    margin-top: 1rem;
    position: absolute;
    right: 10px;
    bottom: 10px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .sample_test {
    max-width: 85vw;
    min-width: 75vw;
    width: 100%;
    height: 150px;
    border-radius: 20px 0px 0px 20px;
    padding: 40px 0px 0px 25px;
    margin-left: 20px;
  }
  .sample_test div {
    font-size: 30px;
    font-weight: 550;
  }
  .demTable {
    padding: 5px;
    width: 500px;
    min-height: 300px;
    height: auto;
  }
  .test_A {
    font-size: 40px;
  }
  .closese {
    width: 120px;
    height: 50px;
    border-radius: 30px;
    font-size: 22px;
    margin: 1rem 2rem 1rem 0rem;
    padding-top: 8px;
  }
  .che {
    width: 120px;
    height: 120px;
    border-radius: 0px 30px 0px 0px;
    margin-bottom: 10px;
    padding: 30px 0px 0px 0px;
  }

  .che h3 {
    font-size: 40px;
    font-weight: 600;
  }
  .key_stage {
    width: 120px;
    height: 120px;
    border-radius: 0px 0px 0px 0px;
    margin-bottom: 10px;
    padding: 10px 0px 0px 0px;
  }

  .key_stage .key_name {
    font-size: 19px;
    font-weight: 550;
  }

  .key_stage .count {
    font-size: 32px;
    font-weight: bold;
  }
  .key_nxt {
    width: 120px;
    height: 220px;
    /* height: 408px; */
    border-radius: 0px 20px 20px 0px;
    margin-bottom: 10px;
    padding: 20px 0px 0px 20px;
  }
  .key_2 {
    font-size: 80px;
    transform: matrix(0, -1, 1, 0, 0, 0);
    margin-top: 5rem;
  }
  .btn_in {
    width: 80px;
    height: 40px;
    font-size: 18px;
    border-radius: 10px;
    margin-right: 1rem;
    margin-top: 1rem;
    position: absolute;
    right: 10px;
    bottom: 10px;
  }
}
@media screen and (max-width: 767px) {
  .sample_test {
    min-width: 75vw;
    width: 100%;
    height: 150px;
    border-radius: 20px 0px 0px 20px;
    padding: 30px 0px 0px 25px;
    margin-left: 10px;
  }
  .sample_test div {
    font-size: 30px;
    font-weight: 550;
  }
  .demTable {
    padding: 5px;
    width: auto;
    min-height: 300px;
    height: auto;
  }
  .test_A {
    font-size: 40px;
  }
  .closese {
    width: 80px;
    height: 40px;
    border-radius: 30px;
    font-size: 18px;
    margin: 1rem 1rem 1rem 0rem;
    padding-top: 5px;
  }
  .che {
    width: 80px;
    height: 90px;
    border-radius: 0px 30px 0px 0px;
    margin-bottom: 10px;
    padding: 30px 0px 0px 0px;
  }

  .che h3 {
    font-size: 20px;
    font-weight: 600;
  }
  .key_stage {
    width: 80px;
    height: 90px;
    border-radius: 0px 0px 0px 0px;
    margin-bottom: 10px;
    padding: 10px 0px 0px 0px;
  }

  .key_stage .key_name {
    font-size: 12px;
    font-weight: 550;
  }

  .key_stage .count {
    font-size: 32px;
    font-weight: bold;
  }
  .key_nxt {
    width: 80px;
    height: 220px;
    border-radius: 0px 20px 20px 0px;
    margin-bottom: 10px;
    padding: 20px 0px 0px 0px;
  }
  .key_2 {
    font-size: 40px;
    transform: matrix(0, -1, 1, 0, 0, 0);
    margin-top: 5rem;
  }
  .side_che {
    display: none;
  }
  .table_div {
    background-color: white;
    height: auto;
    width: 100%;
    padding: 0px;
  }
  .table_container {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
    margin: 10px;
  }
  .btn_in {
    width: 80px;
    height: 40px;
    font-size: 18px;
    border-radius: 10px;
    margin-right: 1rem;
    margin-top: 1rem;
    position: absolute;
    right: 0;
    bottom: 10px;
  }
}
