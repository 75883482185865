.summary-modal .modal-header .close {
  margin: -1rem -1rem -1rem auto;
  padding: 1rem;
}

.summary-modal .summary-content {
  display: flex;
  flex-direction: column;
}

.summary-modal .score-board .boxed {
  border-radius: 10px;
  position: relative;
  text-align: center;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 2px solid #b498ff;
}

.summary-modal .boxed img {
  width: 100px; /* Adjust size as needed */
  height: 100px; /* Adjust size as needed */
}

.summary-modal .boxed .number {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font: normal normal normal 14px/19px Comic Sans MS;
  letter-spacing: -0.28px;
  color: #000000;
}

.summary-modal .total {
  text-align: left;
  font: normal normal normal 18px / 25px Comic Sans MS;
  letter-spacing: 0px;
  color: #000000;
}

.summary-modal .modal-body {
  background: #fdf980 0% 0% no-repeat padding-box;
  border-radius: 10px;
}
.summary-modal .mainBox {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 2px solid #fdf980;
  border-radius: 14px;
  padding: 15px;
  width: 100%;
}
.summary-modal .test-name {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  padding: 0 30px;
  text-align: left;
  font: normal normal normal 18px / 25px Comic Sans MS;
  letter-spacing: 0px;
  color: #064974;
}
.summary-modal .score-board {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 50%;
}

.summary-modal .modal-dialog {
  min-width: 30%;
}
